import { useState, useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext";

import { dataFetch } from "../../../utils/data-fetch";
import { useNavigate } from "react-router-dom";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  CircularProgress,
  Stack,
} from "@mui/material";

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "address", label: "Address", alignRight: false },
  { id: "uin", label: "UIN", alignRight: false },
];

export default function EmployeePayrollList({ onEmpSelected }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [status, setStatus] = useState("active");
  const [category, setCategory] = useState("all");

  const [selectedPayroll, setSelectedPayroll] = useState("all");

  const { getAccessToken, activeFranchise } = useAuth();

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  const handlePayrollChange = (event) => {
    setSelectedPayroll(event.target.value);
  };

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/employees/mgract/v2/query?sort=join_date&dir=a`;

    if (status && status !== "all") url += `&status=${status}`;
    if (category && category !== "all") url += `&role=${category}`;

    if (selectedPayroll && selectedPayroll !== "all")
      url += `&payroll_type=${selectedPayroll}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
      console.log(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [status, activeFranchise, category, selectedPayroll]); // eslint-disable-line react-hooks/exhaustive-deps

  const navigate = useNavigate();

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          mt={2}
          spacing={2}
        >
          <Typography variant="h5" gutterBottom>
            Employee List
          </Typography>

          {loadingData === true && <CircularProgress color="secondary" />}

          <Stack direction="row" spacing={2}>
            <Select
              id="payroll-select"
              value={selectedPayroll}
              onChange={handlePayrollChange}
            >
              <MenuItem value={"all"}>Type - All</MenuItem>
              <MenuItem value={"employee"}>Employee</MenuItem>
              <MenuItem value={"contractor"}>Contractor</MenuItem>
              <MenuItem value={"intern"}>Intern</MenuItem>
            </Select>
            <Select
              id="category-select"
              value={category}
              onChange={handleCategoryChange}
            >
              <MenuItem value={"all"}>Role - All</MenuItem>
              <MenuItem value={"sales"}>Delivery</MenuItem>
              <MenuItem value={"fieldexec"}>Sales</MenuItem>
              <MenuItem value={"tl"}>Team Lead</MenuItem>
              <MenuItem value={"collection"}>Collection</MenuItem>
              <MenuItem value={"manager"}>Manager</MenuItem>
            </Select>
            <Select
              id="status-select"
              value={status}
              onChange={handleStatusChange}
            >
              <MenuItem value={"documentation"}>Document</MenuItem>
              <MenuItem value={"active"}>Active</MenuItem>
              <MenuItem value={"inactive"}>Inactive</MenuItem>
              <MenuItem value={"pending"}>Pending</MenuItem>
              <MenuItem value={"training"}>Training</MenuItem>
              <MenuItem value={"onleave"}>On Leave</MenuItem>
            </Select>
          </Stack>
        </Stack>
        {customerData.length > 0 && (
          <TableContainer sx={{ minHeight: 100 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.alignRight ? "right" : "left"}
                      sortDirection={false}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {customerData.map((item) => {
                  return (
                    <TableRow
                      hover
                      onClick={() => onEmpSelected(item)}
                      style={{
                        backgroundColor:
                          item.status === "inactive" ? "#ddd" : "white",
                      }}
                    >
                      <TableCell>
                        <b>{item ? item.name : ""}</b>
                      </TableCell>
                      <TableCell>
                        {item ? item.address.substring(0, 30) : ""}
                      </TableCell>
                      <TableCell>{item ? item.uin : ""}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </Card>
  );
}
