import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import { dataFetch } from "../../utils/data-fetch";

import { SearchRounded } from "@mui/icons-material";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {
  Card,
  Container,
  Typography,
  Table,
  Box,
  OutlinedInput,
  InputAdornment,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Stack,
  TextField,
} from "@mui/material";
import { getTimeStringNew } from "src/utils/date-time-helper";
import Label from "../crm/Label";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "address", label: "Address", alignRight: false },
  { id: "category", label: "Category", alignRight: false },
  { id: "start_m", label: "Mor Start", alignRight: false },
  { id: "start_eve", label: "Eve Start", alignRight: false },
  { id: "cart", label: "Cart", alignRight: false },
];

export default function ApartmentSearch({ counter, onAptSelected }) {
  const [apartmentData, setApartmentData] = useState([]);

  const [loadingData, setLoadingData] = useState(false);

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [selectedField, setSelectedField] = useState("name");
  const [cartData, setCartData] = useState([]);
  const [selectCart, setSelectCart] = useState("all");

  const [sort, setSort] = useState("_id");
  const [dir, setDir] = useState("d");

  const { getAccessToken, activeFranchise } = useAuth();

  let timer = "";

  const handleChangeQuery = (event) => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      setApartmentData([]);
      setPage(0);
      setSearch(event.target.value);
    }, 1000);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFieldChange = (event) => {
    setSelectedField(event.target.value);
    setPage(0);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
    setPage(0);
  };

  const handleDirChange = (event) => {
    setDir(event.target.value);
    setPage(0);
  };

  const handleSearch = async () => {
    let url = `/api/apartments/mgract/query?skip=${
      page * 5
    }&limit=${5}&sort=${sort}&dir=${dir}`;

    if (selectCart === "all") {
    } else if (selectCart === "none") {
      url += "&cart=none";
    } else {
      url += `&cart=${selectCart}`;
    }

    if (search && search.length > 0)
      url += `&search=${search}&field=${selectedField}`;

    setLoadingData(true);

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setApartmentData(ret.data);
    } else {
      setApartmentData([]);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    handleSearch();
  }, [
    search,
    page,
    selectedField,
    activeFranchise,
    counter,
    sort,
    dir,
    selectCart,
  ]);

  const getMilkcarts = async () => {
    let url = `/api/milkcarts/mgract/query?sort=name&dir=a`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setCartData(ret.data);
    }
  };

  useEffect(() => {
    getMilkcarts();
  }, [activeFranchise]);

  return (
    <Card title="Apartment Search">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={0}
          mt={2}
          spacing={2}
        >
          <Select id="sort-select" value={sort} onChange={handleSortChange}>
            <MenuItem value={"_id"}>Created</MenuItem>
            <MenuItem value={"name"}>Name</MenuItem>
            <MenuItem value={"address"}>Address</MenuItem>
            <MenuItem value={"cart"}>Cart</MenuItem>
          </Select>

          <TextField
            select
            id="dir-select"
            value={dir}
            onChange={handleDirChange}
            label="Sort"
            sx={{ width: 200 }}
          >
            <MenuItem value={"a"}>ASC</MenuItem>
            <MenuItem value={"d"}>DESC</MenuItem>
          </TextField>

          <SearchStyle
            onChange={handleChangeQuery}
            placeholder="Search Apartment"
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>
                  <SearchRounded />
                </Box>
              </InputAdornment>
            }
            sx={{ mt: 4, mb: 4 }}
            endAdornment={
              loadingData === true && <CircularProgress color="secondary" />
            }
            autoFocus
          />

          <TextField
            select
            id="field-select"
            value={selectedField}
            onChange={handleFieldChange}
            label="Search in"
            sx={{ width: 200 }}
          >
            <MenuItem value={"name"}>Name</MenuItem>
            <MenuItem value={"address"}>Address</MenuItem>
          </TextField>

          <TextField
            select
            id="cart-select"
            value={selectCart}
            onChange={(e) => {
              setSelectCart(e.target.value);
              setPage(0);
            }}
            label="Cart"
            sx={{ width: 200 }}
          >
            <MenuItem value={"all"}>ALL</MenuItem>
            <MenuItem value={"none"}>No Cart</MenuItem>
            {cartData?.map((cart) => (
              <MenuItem value={cart._id}>{cart.name}</MenuItem>
            ))}
          </TextField>
        </Stack>

        <TableContainer sx={{ minHeight: 100 }}>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.alignRight ? "right" : "left"}
                    sortDirection={false}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {apartmentData.length > 0 &&
                apartmentData.map((item) => {
                  return (
                    <TableRow
                      hover
                      onClick={() => onAptSelected(item)}
                      style={{
                        backgroundColor:
                          item.active === true ? "white" : "#ddd",
                      }}
                    >
                      <TableCell>
                        <b>{item ? item.name : ""}</b>
                      </TableCell>
                      <TableCell>
                        {(item.location && item.location.detail) || ""}
                      </TableCell>
                      <TableCell>
                        <Label
                          variant="filled"
                          color={item?.category == "zone" ? "primary" : "info"}
                        >
                          {item?.category || "apartment"}
                        </Label>
                      </TableCell>
                      <TableCell>
                        {item ? getTimeStringNew(item.start_time) : ""}
                      </TableCell>
                      <TableCell>
                        {item ? getTimeStringNew(item.start_time_e) : ""}
                      </TableCell>
                      <TableCell>
                        {(item && item.cart && item.cart.name) || "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={-1}
          rowsPerPage={5}
          rowsPerPageOptions={[5]}
          page={page}
          onPageChange={handleChangePage}
        />
      </Container>
    </Card>
  );
}
