import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch, dataPost } from "src/utils/data-fetch";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { Edit as EditIcon, EditRounded } from "@mui/icons-material";

import {
  Card,
  Typography,
  Box,
  Grid,
  Stack,
  CircularProgress,
  Avatar,
  Button,
  Modal,
  TableBody,
  Switch,
  TableRow,
  TableCell,
  IconButton,
} from "@mui/material";

import { getDateStringFullWithMonthSync } from "src/utils/date-time-helper";
import EditEmployeeModal from "./EditEmployeeModal";
import ToffCodeModal from "./ToffCodeModal";
import UploadAgreementPdf from "./doc/UploadAgreementPdf";

export default function EmployeeProfileSection({ employee }) {
  const [currentData, setCurrentData] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [loadingData2, setLoadingData2] = useState(false);

  const [status, setStatus] = useState(employee.status);

  const { getAccessToken, activeFranchise } = useAuth();

  const loadData = async () => {
    setLoadingData2(true);
    let url = `/api/employees/mgract/v2/employee/${employee.employee}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCurrentData(ret.data);
      setStatus(ret.data.employee_det.status);
      console.log(ret.data);
    }
    setLoadingData2(false);
  };

  useEffect(() => {
    setLoadingData2(true);
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateStatusInServer = async (status) => {
    setLoadingData(true);

    let url = `/api/employees/mgract/v2/employee/${employee.employee}/status`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      { status: status },
      activeFranchise
    );
    if (ret.success === true) {
      setStatus(status);
    } else {
      alert(ret.message);
    }
    setLoadingData(false);
  };

  const handleStatusChange = (event) => {
    updateStatusInServer(event.target.value);
  };

  const [loadingData3, setLoadingData3] = useState(false);
  const [docStatus, setDocStatus] = useState(employee.doc_status);

  const updateDocStatus = async (status) => {
    setLoadingData3(true);

    let url = `/api/employees/mgract/v2/employee/${employee.employee}/documents`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      { status: status },
      activeFranchise
    );
    if (ret.success === true) {
      setDocStatus(status);
    } else {
      alert(ret.message);
    }
    setLoadingData3(false);
  };

  const [toffCodeModal, setToffCodeModal] = useState(false);
  const closeToffModalFn = () => {
    setToffCodeModal(false);
    loadData();
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Card sx={{ px: 2, py: 2 }}>
                <Stack direction="column" alignItems="center" pt={5}>
                  <Avatar
                    sx={{
                      width: "200px",
                      height: "200px",
                    }}
                  >
                    <img src={employee.image} alt="Employee" />
                  </Avatar>

                  <Typography variant="h6" sx={{ mt: 1 }}>
                    {employee.name}
                  </Typography>

                  <Typography variant="h7" sx={{ mb: 3, mt: 1 }}>
                    {currentData?.employee_det?.payroll_type?.toUpperCase() ||
                      "-"}
                  </Typography>

                  <Select
                    id="status-select"
                    value={status}
                    onChange={handleStatusChange}
                    placeholder="-"
                    size="small"
                  >
                    <MenuItem value={"-"}>-</MenuItem>
                    <MenuItem value={"training"}>Training</MenuItem>
                    <MenuItem value={"active"}>Active</MenuItem>
                    <MenuItem value={"inactive"}>Inactive</MenuItem>
                    <MenuItem value={"pending"}>Pending</MenuItem>
                    <MenuItem value={"onleave"}>On Leave</MenuItem>
                  </Select>

                  {loadingData === true && <CircularProgress />}
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Card sx={{ p: 2 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <Typography variant="h6">Doc enabled</Typography>
                  {loadingData3 === true && <CircularProgress />}

                  <Switch
                    label="Doc Enabled"
                    checked={!docStatus}
                    disabled={loadingData3 === true || loadingData2 === true}
                    onChange={(event) => updateDocStatus(!event.target.checked)}
                  />
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Card sx={{ p: 2 }}>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="h6">Toff Code</Typography>

                  <Stack direction="row" alignItems={"center"} spacing={2}>
                    <Typography variant="subtitle1">
                      {currentData?.employee_det?.toff_code || "-"}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        setToffCodeModal(true);
                      }}
                    >
                      <EditRounded />
                    </IconButton>
                  </Stack>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <EmployeeDetailsCardSecondary
            loading={loadingData2}
            doc={currentData}
            reload={() => loadData()}
          />
        </Grid>
      </Grid>

      <Modal open={toffCodeModal} onClose={closeToffModalFn}>
        <ToffCodeModal
          handleM1Close={closeToffModalFn}
          employee={currentData}
        />
      </Modal>
    </>
  );
}

export const EmployeeDetailsCardSecondary = ({ loading, doc, reload }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModalFn = () => {
    setModalOpen(false);
    reload();
  };

  const openModalFn = async () => {
    setModalOpen(true);
  };

  const [uploadAgreementModal, setUploadAgreementModal] = useState(false);

  return (
    <>
      <Card>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: 3, mx: 3 }}
        >
          <Typography variant="h5" gutterBottom>
            Details
          </Typography>

          {loading === true && <CircularProgress />}

          <Button
            variant="outlined"
            color="error"
            startIcon={<EditIcon />}
            sx={{ mr: 1 }}
            onClick={openModalFn}
          >
            Edit
          </Button>
        </Stack>

        {doc && (
          <Grid container columnSpacing={1} sx={{ pl: 2, pr: 2 }}>
            <Grid item xs={12} md={6} lg={6}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>UIN:</b>
                  </TableCell>
                  <TableCell>{doc.employee_det.uin}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Phone:</b>
                  </TableCell>
                  <TableCell>{doc.phone}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Alt Ph:</b>
                  </TableCell>
                  <TableCell>{doc.employee_det.alt_phone}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Address:</b>
                  </TableCell>
                  <TableCell>{doc.employee_det.address}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Roles:</b>
                  </TableCell>
                  <TableCell>
                    {JSON.stringify(doc.employee_det.roles)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Joining:</b>
                  </TableCell>
                  <TableCell>
                    {getDateStringFullWithMonthSync(doc.employee_det.join_date)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>DOB:</b>
                  </TableCell>
                  <TableCell>
                    {getDateStringFullWithMonthSync(doc.employee_det.dob)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Base:</b>
                  </TableCell>
                  <TableCell>₹{doc.employee_det.base_salary}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Allowance:</b>
                  </TableCell>
                  <TableCell>₹ {doc.employee_det.allowance}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Incentive:</b>
                  </TableCell>
                  <TableCell>₹ {doc.employee_det.var_salary} pL</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Product:</b>
                  </TableCell>
                  <TableCell>
                    Bonus{" "}
                    {doc.employee_det.product_bonus === true
                      ? "Active"
                      : "Inactive"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Leaves:</b>
                  </TableCell>
                  <TableCell>{doc.employee_det.leaves_pm} per month</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Tshirt:</b>
                  </TableCell>
                  <TableCell>{doc.employee_det.tshirt_size}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Blood Grp:</b>
                  </TableCell>
                  <TableCell>{doc.employee_det.blood_group}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Agreement:</b>
                  </TableCell>
                  <TableCell>
                    {(doc?.employee_det?.signed_agreement && (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() =>
                          window.open(doc.employee_det.signed_agreement, "_blank")
                        }
                      >
                        Download
                      </Button>
                    )) || (
                      <Button
                        color="error"
                        variant="contained"
                        onClick={() => setUploadAgreementModal(true)}
                      >
                        Upload
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Grid>
          </Grid>
        )}

        <Stack direction="row">
          <Box sx={{ flexGrow: 1 }} />
        </Stack>
      </Card>

      <Modal open={modalOpen} onClose={closeModalFn}>
        <EditEmployeeModal handleM1Close={closeModalFn} employee={doc} />
      </Modal>

      <Modal
        open={uploadAgreementModal}
        onClose={() => setUploadAgreementModal(false)}
      >
        <UploadAgreementPdf
          handleM1Close={() => {
            setUploadAgreementModal(false);
            reload();
          }}
          empId={doc?._id}
        />
      </Modal>
    </>
  );
};
