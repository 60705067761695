import { Card, Typography, Grid, Stack, Modal } from "@mui/material";

import EmployeeBalanceCard from "./EmployeeBalanceCard";
import BankAccountCard from "./BankAccountCard";
import NewPayoutModal from "../payroll/NewPayoutModal";

import {
  Add as AddIcon,
  ArrowRightRounded,
  ImportExportRounded,
} from "@mui/icons-material";

import { useState } from "react";
import NewSalaryModal from "../payroll/NewSalaryModal";
import NewAdjustmentModal from "../payroll/NewAdjustmentModal";

export default function BankAccountSection({ employee }) {
  const [payoutModalOpen, setPayoutModalOpen] = useState(false);
  const closePayoutModal = () => {
    setPayoutModalOpen(false);
  };
  const openPayoutModal = () => {
    setPayoutModalOpen(true);
  };

  const [salaryModalOpen, setSalaryModalOpen] = useState(false);
  const closeSalaryModal = () => {
    setSalaryModalOpen(false);
    reloadBalance();
  };
  const openSalaryModal = () => {
    setSalaryModalOpen(true);
  };

  const [adjModalOpen, setAdjModalOpen] = useState(false);
  const closeAdjModal = () => {
    setAdjModalOpen(false);
    reloadBalance();
  };
  const openAdjModal = () => {
    setAdjModalOpen(true);
  };

  const [balanceCounter, setBalanceCounter] = useState(0);
  const reloadBalance = () => {
    setBalanceCounter(balanceCounter + 1);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={9} lg={9}>
          <BankAccountCard employee={employee} />
        </Grid>

        <Grid item xs={6} md={3} lg={3}>
          <Stack direction="column" spacing={2}>
            <EmployeeBalanceCard
              employee={employee.employee}
              counter={balanceCounter}
            />

            <Card
              sx={{
                p: 2,
                backgroundColor: "primary.light",
                color: "black",
              }}
              onClick={openPayoutModal}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6">Payout</Typography>
                <ArrowRightRounded />
              </Stack>
            </Card>

            <Card
              sx={{
                p: 2,
                backgroundColor: "success.main",
                color: "white",
              }}
              onClick={openSalaryModal}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6">Salary</Typography>
                <AddIcon />
              </Stack>
            </Card>

            <Card
              sx={{
                p: 2,
                backgroundColor: "info.main",
                color: "white",
              }}
              onClick={openAdjModal}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6">Adjustment</Typography>
                <ImportExportRounded />
              </Stack>
            </Card>
          </Stack>
        </Grid>
      </Grid>
      <Modal open={payoutModalOpen} onClose={closePayoutModal}>
        <NewPayoutModal handleM1Close={closePayoutModal} employee={employee} />
      </Modal>
      <Modal open={salaryModalOpen} onClose={closeSalaryModal}>
        <NewSalaryModal handleM1Close={closeSalaryModal} employee={employee} />
      </Modal>
      <Modal open={adjModalOpen} onClose={closeAdjModal}>
        <NewAdjustmentModal handleM1Close={closeAdjModal} employee={employee} />
      </Modal>
    </>
  );
}
