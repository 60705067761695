import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  CircularProgress,
  Stack,
  Tooltip,
  Drawer,
  IconButton,
} from "@mui/material";

import { getDateStringFullWithTimeSync } from "src/utils/date-time-helper";
import InternalTxnDetailsModalAdmin from "./txns/InternalTxnDetailsModalAdmin";
import {
  ControlPointDuplicateRounded,
  RefreshRounded,
} from "@mui/icons-material";
import InternalAccDetailsPage from "./details/InternalAccDetailsPage";

const TABLE_HEAD = [
  { id: "amount", label: "Amount", alignRight: false },
  { id: "entity", label: "Entity", alignRight: false },
  { id: "from", label: "From", alignRight: false },
  { id: "to", label: "To", alignRight: false },
  { id: "date", label: "Date", alignRight: false },
  { id: "center", label: "Center", alignRight: false },
  { id: "comments", label: "Comments", alignRight: false },
];

export default function PendingTxnApprovals() {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/internalaccs/coreact/txns/pending?sort=_id&dir=d`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    handleSearch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [payoutModalOpen, setPayoutModalOpen] = useState(null);

  const closePayoutModal = () => {
    setPayoutModalOpen(null);
    handleSearch();
  };

  const openPayoutModal = async (id) => {
    setPayoutModalOpen(id);
  };

  const [accDrawerState, setAccDrawerState] = useState(false);
  const [accDrawerData, setAccDrawerData] = useState(null);

  const onAccClick = (acc) => {
    if (acc) {
      setAccDrawerData(acc);
      setAccDrawerState(true);
    }
  };

  return (
    <Card sx={{ minHeight: "150px" }}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            Pending Txns
          </Typography>

          {(loadingData === true && <CircularProgress color="secondary" />) || (
            <IconButton onClick={() => handleSearch()}>
              <RefreshRounded />
            </IconButton>
          )}
        </Stack>
        {customerData.length > 0 && (
          <TableContainer sx={{ minHeight: 100 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.alignRight ? "right" : "left"}
                      sortDirection={false}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {customerData.map((item) => {
                  const color =
                    item.status === "success"
                      ? "white"
                      : [
                          "pending",
                          "processing",
                          "initiated",
                          "approved",
                        ].includes(item.status)
                      ? "lightcyan"
                      : "lightsalmon";
                  return (
                    <TableRow
                      hover
                      onClick={() => openPayoutModal(item._id)}
                      style={{ backgroundColor: color }}
                    >
                      <TableCell>
                        <b>₹ {item.amount}</b>
                      </TableCell>
                      <TableCell>{item.entity}</TableCell>
                      <TableCell
                        sx={{ color: "success.dark" }}
                        onClick={(e) => {
                          onAccClick(item?.from);
                          e.stopPropagation();
                        }}
                      >
                        {item && item.from ? item.from.name : "-"}
                      </TableCell>
                      <TableCell
                        sx={{ color: "error.main" }}
                        onClick={(e) => {
                          onAccClick(item?.to);
                          e.stopPropagation();
                        }}
                      >
                        {item && item.to ? item.to.name : "-"}
                      </TableCell>
                      <TableCell>
                        {item && getDateStringFullWithTimeSync(item.date)}
                      </TableCell>

                      <TableCell>
                        {(item && item.franchisee && item.franchisee.name) ||
                          "-"}
                      </TableCell>

                      <Tooltip title={item.comments || ""} arrow>
                        <TableCell>
                          {item.comments ? item.comments.substring(0, 15) : "-"}
                        </TableCell>
                      </Tooltip>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {customerData.length === 0 && loadingData === false && (
          <Typography gutterBottom align="center" variant="subtitle1" mb={2}>
            No pending txns!
          </Typography>
        )}
      </Container>

      <InternalTxnDetailsModalAdmin
        handleM1Close={closePayoutModal}
        poutId={payoutModalOpen}
        drawerOpen={payoutModalOpen ? true : false}
      />

      <Drawer
        anchor={"right"}
        open={accDrawerState}
        onClose={() => setAccDrawerState(false)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        <div style={{ padding: "20px" }}>
          <InternalAccDetailsPage inpAcc={accDrawerData} />
        </div>
      </Drawer>
    </Card>
  );
}
