import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";
import { styled } from "@mui/material/styles";

import {
  ControlPointDuplicateRounded,
  RefreshRounded,
} from "@mui/icons-material";
import { SearchRounded } from "@mui/icons-material";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Stack,
  Modal,
  Tooltip,
  Select,
  MenuItem,
  InputAdornment,
  OutlinedInput,
  Box,
  Drawer,
  IconButton,
  TextField,
} from "@mui/material";

import { getDateStringFullWithTimeSync } from "src/utils/date-time-helper";

import InternalTxnDetailsModalAdmin from "../txns/InternalTxnDetailsModalAdmin";
import AddTxnModal from "../txns/AddTxnModal";
import InternalAccDetailsPage from "./InternalAccDetailsPage";

const TABLE_HEAD = [
  { id: "date", label: "Date", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "entity", label: "Entity", alignRight: false },
  { id: "from", label: "From", alignRight: false },
  { id: "to", label: "To", alignRight: false },
  { id: "method", label: "Method", alignRight: false },
  { id: "center", label: "Center", alignRight: false },
  { id: "comments", label: "Comments", alignRight: false },
];

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "70%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: "75%" },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function InternalTxnsByAccountPaginated({ account }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState("all");
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(0);

  const { getAccessToken } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  let timer = "";

  const handleChangeQuery = (event) => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      setSearch(event.target.value);
    }, 1000);
  };

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/internalaccs/coreact/txns/query?sort=date&dir=d&account=${
      account._id
    }&skip=${page * 8}&limit=8`;

    if (selectedCategory !== "all") url += `&entity=${selectedCategory}`;
    if (search && search.length > 0) url += `&comments=${search}`;

    const ret = await dataFetch(url, await getAccessToken());
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [page, selectedCategory, search]); // eslint-disable-line react-hooks/exhaustive-deps

  const [payoutModalOpen, setPayoutModalOpen] = useState(null);

  const closePayoutModal = () => {
    setPayoutModalOpen(null);
  };

  const openPayoutModal = async (id) => {
    setPayoutModalOpen(id);
  };

  const duplicateTxnClicked = () => {
    setDuplicateTxnModalOpen(true);
  };

  const [duplicateTxnModalOpen, setDuplicateTxnModalOpen] = useState(false);
  const [duplicateData, setDuplicateData] = useState();

  const closeDuplicateTxnModal = () => {
    setDuplicateTxnModalOpen(false);
  };

  const [accDrawerState, setAccDrawerState] = useState(false);
  const [accDrawerData, setAccDrawerData] = useState(null);

  const onAccClick = (acc) => {
    if (acc) {
      setAccDrawerData(acc);
      setAccDrawerState(true);
    }
  };

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
          spacing={1}
        >
          <Typography variant="h5" gutterBottom>
            Txns Data
          </Typography>

          <SearchStyle
            placeholder="Search Comments"
            onChange={handleChangeQuery}
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>
                  <SearchRounded />
                </Box>
              </InputAdornment>
            }
          />

          <TextField
            select
            id="expense-select"
            label="Category"
            sx={{ width: 100 }}
            value={selectedCategory}
            onChange={(event) => setSelectedCategory(event.target.value)}
          >
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"cashflow"}>Cash Flow</MenuItem>
            <MenuItem value={"farmerpay"}>Farmer Pay</MenuItem>
            <MenuItem value={"dispensein"}>Dispense Cash</MenuItem>
            <MenuItem value={"bill"}>Purchase Bill</MenuItem>
            <MenuItem value={"salebill"}>Sale Bill</MenuItem>
            <MenuItem value={"salary"}>Salary</MenuItem>
            <MenuItem value={"payroll"}>Payroll</MenuItem>
            <MenuItem value={"expense"}>Expense</MenuItem>
            <MenuItem value={"writeoff"}>Write Off</MenuItem>
          </TextField>

          {(loadingData === true && <CircularProgress color="secondary" />) || (
            <IconButton onClick={() => handleSearch()}>
              <RefreshRounded />
            </IconButton>
          )}
        </Stack>

        {customerData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {customerData.map((item) => {
                    const color =
                      item.status === "success"
                        ? "white"
                        : [
                            "pending",
                            "processing",
                            "initiated",
                            "approved",
                          ].includes(item.status)
                        ? "lightcyan"
                        : "lightsalmon";
                    return (
                      <TableRow
                        hover
                        onClick={() => openPayoutModal(item._id)}
                        style={{ backgroundColor: color }}
                      >
                        <TableCell sx={{ color: "primary.dark" }}>
                          <b>{getDateStringFullWithTimeSync(item.date)}</b>
                        </TableCell>
                        <TableCell>
                          <b>₹ {item.amount.toFixed(1)}</b>
                        </TableCell>
                        <TableCell>{item.entity}</TableCell>
                        <TableCell
                          sx={{ color: "success.dark" }}
                          onClick={(e) => {
                            onAccClick(item?.from);
                            e.stopPropagation();
                          }}
                        >
                          {item.from ? item.from.name : "-"}
                        </TableCell>
                        <TableCell
                          sx={{ color: "error.main" }}
                          onClick={(e) => {
                            onAccClick(item?.to);
                            e.stopPropagation();
                          }}
                        >
                          {item.to ? item.to.name : "-"}
                        </TableCell>
                        <TableCell>
                          {item.method && item.method.toUpperCase()}
                        </TableCell>
                        <TableCell>
                          {item.franchisee ? item.franchisee.name : "-"}
                        </TableCell>
                        <Tooltip title={item.comments || ""} arrow>
                          <TableCell>
                            {item.comments
                              ? item.comments.substring(0, 15)
                              : "-"}
                          </TableCell>
                        </Tooltip>

                        <TableCell
                          onClick={(e) => {
                            e.stopPropagation();
                            setDuplicateData(item);
                            duplicateTxnClicked();
                          }}
                        >
                          <ControlPointDuplicateRounded />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={-1}
              rowsPerPage={8}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[8]}
            />
          </div>
        )}

        {customerData.length === 0 && loadingData === false && (
          <div>
            <Typography gutterBottom align="center" variant="subtitle1">
              Not found
            </Typography>
            <Typography variant="body2" align="center" sx={{ mb: 4 }}>
              No results here.
            </Typography>
          </div>
        )}
      </Container>

      <InternalTxnDetailsModalAdmin
        handleM1Close={closePayoutModal}
        poutId={payoutModalOpen}
        drawerOpen={payoutModalOpen ? true : false}
      />

      <Modal open={duplicateTxnModalOpen} onClose={closeDuplicateTxnModal}>
        <AddTxnModal
          handleM1Close={closeDuplicateTxnModal}
          duplicateData={duplicateData}
        />
      </Modal>

      <Drawer
        anchor={"right"}
        open={accDrawerState}
        onClose={() => setAccDrawerState(false)}
        sx={{ zIndex: 1300, p: 3}}
      >
        <div style={{ padding: "20px" }}>
          <InternalAccDetailsPage inpAcc={accDrawerData} />
        </div>
      </Drawer>
    </Card>
  );
}
