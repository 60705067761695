import {
  Grid,
  Container,
  Typography,
  Stack,
  Button,
  Modal,
  Card,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useState } from "react";

import Page from "../../components/Page";
import PartySearch from "./PartySearch";
import TodayTxns from "./TodayTxns";
import OrdersToday from "./OrdersToday";
import AddPartyModal from "./AddPartyModal";
import PartyProfileSection from "./PartyProfileSection";
import { PartyDetailsModal } from "./PartyDetailsModal";
import BulkOrderDetails from "./BulkOrderDetails";

export default function BulkSale() {
  const [addModalState, setAddModalState] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const closeAddPartyModal = () => {
    setAddModalState(false);
  };

  const addPartyClicked = () => {
    setAddModalState(true);
  };

  const handleOrderSelected = (order) => {
    setSelectedOrder(order);
    setOrderDetailsModal(true);
  };

  const [orderDetailsModal, setOrderDetailsModal] = useState(false);

  const handleOrderDetailsModalClose = () => {
    setOrderDetailsModal(false);
  };

  const [currData, setCurrData] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handlePartySelected = (d) => {
    setCurrData(d);
    setDrawerOpen(true);
  };

  return (
    <Page title="B2B Sales Dashboard">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
          spacing={2}
        >
          <Typography variant="h4">B2B Sales Dashboard</Typography>

          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            justifyContent="space-between"
          >
            <Button variant="contained" onClick={addPartyClicked}>
              Add Party
            </Button>
          </Stack>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={7}>
            <Stack direction="column" spacing={2}>
              <PartySearch onDataSelected={handlePartySelected} />
              <OrdersToday onDataSelected={handleOrderSelected} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={5} lg={5}>
            <TodayTxns />
          </Grid>
        </Grid>
      </Container>

      <AddPartyModal
        drawerOpen={addModalState}
        handleM1Close={closeAddPartyModal}
      />

      <PartyDetailsModal
        party={currData}
        handleClose={() => setDrawerOpen(false)}
        drawerOpen={drawerOpen}
      />

      <Modal open={orderDetailsModal} onClose={handleOrderDetailsModalClose}>
        <BulkOrderDetails bulkOrder={selectedOrder} />
      </Modal>

      {/* 
      <FullScreenEmpDetailsDialog
        emp={currEmp}
        handleClose={() => setDrawerOpen(false)}
        drawerOpen={drawerOpen}
      />  */}
    </Page>
  );
}
