import {
  Card,
  Typography,
  Stack,
  CircularProgress,
  Avatar,
} from "@mui/material";
import Label from "../crm/Label";

export default function PartyImageCard({ party, loadingData }) {
  console.log("party", party);

  return (
    <>
      <Card sx={{ pl: 2, pr: 2, minHeight: "350px" }}>
        <Stack direction="column" alignItems="center" pt={5}>
          <Avatar
            sx={{
              width: "200px",
              height: "200px",
            }}
          >
            {party && party.image && <img src={party.image} alt="party" />}
          </Avatar>

          <Typography variant="h6" sx={{ mt: 3 }}>
            {(party && party.name) || "-"}
          </Typography>

          <Stack direction="row" alignItems="center">
            <Typography variant="body1" sx={{ mt: 3 }}>
              Balance:
            </Typography>

            <Typography variant="body1" sx={{ mt: 3, ml: 2 }}>
              <Label color={party && party.balance > 0 ? "success" : "error"} >
                ₹ {(party && party.balance) || "0"}
              </Label>
            </Typography>
          </Stack>

          {loadingData === true && <CircularProgress />}
        </Stack>
      </Card>
    </>
  );
}
