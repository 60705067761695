import { SearchRounded } from "@mui/icons-material";
import {
  Grid,
  List,
  ListItem,
  Typography,
  Box,
  TextField,
  Button,
  Stack,
  InputAdornment,
  CircularProgress,
  styled,
  OutlinedInput,
  Card,
  Container,
} from "@mui/material";
import { Avatar } from "@mui/material";
import { useEffect, useState } from "react";

export const ChatSystem = () => {
  const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: "100%",
    transition: theme.transitions.create(["box-shadow", "width"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),

    "& fieldset": {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`,
    },
  }));
  const [loadingData, setLoadingData] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const customers = [
    {
      name: "John Doe",
      email: "johndoe@gmail.com",
      lastActive: 1725598800000,
      chats: [
        {
          message: "Hello",
          time: 1725597900000,
          sender: "customer",
        },
        {
          message: "Hi",
          time: 1725598200000,
          sender: "admin",
        },
        {
          message: "How can I help you?",
          time: 1725598800000,
          sender: "admin",
        },
      ],
    },
    {
      name: "Rivu Naskar",
      email: "rivunaskar0@gmail.com",
      lastActive: 1725598800000,
      chats: [
        {
          message: "Hello",
          time: 1725597900000,
          sender: "customer",
        },
        {
          message: "Hi",
          time: 1725598200000,
          sender: "admin",
        },
        {
          message: "How can I help you?",
          time: 1725598800000,
          sender: "admin",
        },
      ],
    },
    {
      name: "Rahul Kumar",
      email: "rahulkumar@gmail.com",
      lastActive: 1725598800000,
      chats: [
        {
          message: "Hello",
          time: 1725597900000,
          sender: "customer",
        },
        {
          message: "Hi",
          time: 1725598200000,
          sender: "admin",
        },
        {
          message: "How can I help you?",
          time: 1725598800000,
          sender: "admin",
        },
        {
          message: "I have a query",
          time: 1725598900000,
          sender: "customer",
        },
      ],
    },
  ];

  const sortedCustomers = customers.sort((a, b) => {
    const timeDidd = b.lastActive - a.lastActive;

    if (timeDidd === 0) {
      return a.name.localeCompare(b.name);
    }

    return timeDidd;
  });

  useEffect(() => {
    setLoadingData(true);
    setTimeout(() => {
      setLoadingData(false);
    }, 2000);
  }, []);

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Typography
        variant="h4"
        component="h1"
        sx={{ padding: 2, borderBottom: "1px solid lightgray" }}
      >
        Chat System
      </Typography>

      <Grid container sx={{ flex: 1 }}>
        <Grid
          item
          xs={12}
          md={3}
          sx={{ borderRight: "1px solid lightgray", padding: 2 }}
        >
          <SearchStyle
            placeholder="Search Customers......"
            //onChange={handleChangeQuery}
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>
                  <SearchRounded />
                </Box>
              </InputAdornment>
            }
            endAdornment={
              loadingData === true && <CircularProgress color="secondary" />
            }
            autoFocus={true}
          />
          <List>
            {sortedCustomers.map((customer, index) => (
              <ListItem key={index} sx={{ padding: 0, marginBottom: 1 }}>
                <Card
                  sx={{
                    p: 2,
                    borderRadius: 2,
                    width: "100%",
                    backgroundColor:
                      customer?.name === selectedCustomer?.name
                        ? "lightgray"
                        : "white",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedCustomer(customer);
                    console.log("Selected Customer:", customer);
                  }}
                >
                  <Stack direction="row" spacing={2}>
                    <Avatar>{customer?.name.charAt(0)}</Avatar>
                    <Typography variant="body1" sx={{ pt: 1 }}>
                      <b>{customer?.name}</b>
                    </Typography>
                  </Stack>
                </Card>
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            borderRight: "1px solid lightgray",
            padding: 1,
            position: "relative",
          }}
        >
          <Grid
            container
            width="100%"
            sx={{
              padding: 1,
              borderBottom: "1px solid lightgray",
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              marginBottom: 2,
            }}
          >
            <Stack direction="column" spacing={1}>
              <Typography variant="h6">
                {selectedCustomer ? selectedCustomer.name : "Select a customer"}
              </Typography>

              <Typography variant="body2">
                {selectedCustomer ? selectedCustomer.email : ""}
              </Typography>
            </Stack>
          </Grid>

          <Box sx={{ height: "calc(100vh - 220px)", overflowY: "auto" }}>
            {selectedCustomer ? (
              selectedCustomer.chats && selectedCustomer.chats.length > 0 ? (
                selectedCustomer.chats.map((chat, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection:
                        chat.sender === "admin" ? "row-reverse" : "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginBottom: 2,
                    }}
                  >
                    <Card
                      sx={{
                        minWidth: "5%",
                        borderRadius: 2,
                        padding: 1,
                        backgroundColor:
                          chat.sender === "admin" ? "primary.main" : "grey.200",
                        color: chat.sender === "admin" ? "white" : "black",
                      }}
                    >
                      {chat.message}
                    </Card>
                  </Box>
                ))
              ) : (
                <Typography>No chats available for this customer.</Typography>
              )
            ) : (
              <Typography>Select a customer to start chatting</Typography>
            )}
          </Box>

          <Box
            sx={{
              padding: 2,
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
            }}
          >
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                multiline
                type="text"
                label="Type a message"
              />
              <Button variant="contained" color="primary" sx={{ px: 2 }}>
                Send
              </Button>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} md={3} sx={{ padding: 2 }}>
          <Typography variant="h6">Details</Typography>
          <Box sx={{ height: "calc(100vh - 160px)", overflowY: "auto" }}>
            <Typography>Select a customer to view details</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
