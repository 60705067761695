import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Alert,
  Stack,
  TextField,
  Typography,
  AppBar,
  Toolbar,
  Slide,
  Dialog,
  Button,
  Switch,
  IconButton,
  Modal,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";
import {
  CloseRounded,
  UploadRounded,
} from "@mui/icons-material";

import { forwardRef } from "react";
import UploadNotifImage from "./UploadNotifImage";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddNotificationMsgModal({
  drawerOpen,
  handleM1Close,
  notification,
}) {
  const [error, setError] = useState();
  const { getAccessToken, activeFranchise } = useAuth();
  const [imageAvailable, setImageAvailable] = useState(
    notification?.imageUrl ? true : false
  );

  const [actionButtonAvailable, setActionButtonAvailable] = useState(false);
  const [scheduleAvailable, setScheduleAvailable] = useState(false);
  const [selectedDate, setSelectedDate] = useState(false);

  const [uploadModalState, setUploadModalState] = useState(false);

  const approveNow = async (values) => {
    const payload = {
      title: values.title,
      body: values.body,
      send_now: true,
    };

    if (imageAvailable) {
      payload.imageUrl = values.imageUrl;
    }

    if (values.link && values.link.length > 0) {
      payload.link = values.link;
      payload.link_text = values.link_text || "Open";
    }

    if (values.description && values.description.length > 0) {
      payload.description = values.description
    }

    try {
      const ret = await dataPost(
        `/api/sendnotification/mgract/send/customers`,
        await getAccessToken(),
        payload,
        activeFranchise
      );

      if (imageAvailable) {
        payload.imageUrl = values.imageUrl;
      }

      if (ret.success === true) {
        console.log("Success:", ret.data);
        formik.resetForm();
        handleM1Close();
      } else {
        setError(ret.message + " - " + ret.code);
        console.error("API Error:", ret.message + " - " + ret.code);
      }
    } catch (err) {
      setError("Unexpected error: " + err.message);
      console.error("Unexpected error:", err);
    }
  };

  const DataSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    body: Yup.string().required("Subtitle is required"),
    description: Yup.string().optional(),
    link: Yup.string().optional(),
    link_text: Yup.string().optional(),
    imageUrl: Yup.string().optional(),
  });

  const formik = useFormik({
    initialValues: {
      title: notification?.title || "",
      body: notification?.body || "",
      description: notification?.description || "",
      link: notification?.link || "",
      link_text: notification?.link_text || "",
      imageUrl: notification?.imageUrl || "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      try {
        await approveNow(values);
      } catch (error) {
        console.error("Submission error:", error);
      }
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="body"
      open={drawerOpen}
      onClose={handleM1Close}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            Add Notification Msg
          </Typography>
          <Button variant="contained" color="error" onClick={handleM1Close}>
            <CloseRounded />
          </Button>
        </Toolbar>
      </AppBar>

      <Stack direction="column" spacing={2} p={2}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField
                autoComplete="off"
                fullWidth
                type="text"
                label="Notification Title"
                {...getFieldProps("title")}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
              <TextField
                fullWidth
                multiline
                type="text"
                label="Notification Subtitle"
                {...getFieldProps("body")}
                error={Boolean(touched.body && errors.body)}
                helperText={touched.body && errors.body}
              />

              <TextField
                fullWidth
                type="text"
                multiline
                label="Description (after opening notification)"
                {...getFieldProps("description")}
                error={Boolean(touched.link && errors.link)}
                helperText={touched.link && errors.link}
              />

              <Stack direction="row" spacing={2} alignItems={"center"}>
                Link?
                <Switch
                  checked={actionButtonAvailable}
                  onChange={() =>
                    setActionButtonAvailable(!actionButtonAvailable)
                  }
                />
                {actionButtonAvailable && (
                  <TextField
                    fullWidth
                    multiline
                    type="text"
                    label="Action Button Text"
                    {...getFieldProps("link_text")}
                    error={Boolean(touched.link_text && errors.link_text)}
                    helperText={touched.link_text && errors.link_text}
                  />
                )}
              </Stack>

              {actionButtonAvailable && (
                <TextField
                  fullWidth
                  multiline
                  type="text"
                  label="Action Link"
                  {...getFieldProps("link")}
                  error={Boolean(touched.link && errors.link)}
                  helperText={touched.link && errors.link}
                />
              )}

              <Stack direction="row" spacing={2} alignItems={"center"}>
                Image?
                <Switch
                  checked={imageAvailable}
                  onChange={() => setImageAvailable(!imageAvailable)}
                />
                {imageAvailable && (
                  <>
                    <TextField
                      fullWidth
                      type="text"
                      label="Image URL or upload"
                      {...getFieldProps("imageUrl")}
                      error={Boolean(touched.imageUrl && errors.imageUrl)}
                      helperText={touched.imageUrl && errors.imageUrl}
                    />
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setUploadModalState(true);
                      }}
                    >
                      <UploadRounded />
                    </IconButton>
                  </>
                )}
              </Stack>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Stack>

      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      <Modal open={uploadModalState} onClose={() => setUploadModalState(false)}>
        <UploadNotifImage
          handleM1Close={() => setUploadModalState(false)}
          returnUrl={(url) => setFieldValue("imageUrl", url)}
        />
      </Modal>
    </Dialog>
  );
}
