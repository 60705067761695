import {
  Grid,
  Container,
  Typography,
  Stack,
  Button,
  Modal,
} from "@mui/material";

import Page from "src/components/Page";

import AdminEmployeeSearch from "./AdminEmployeeSearch";

import { useState } from "react";
import PendingEmployeePayroll from "./PendingEmployeePayroll";
import AddEmployeeModal from "src/pages/employee/AddEmployeeModal";

export default function AdminEmployees() {
  const [modalOpen, setModalOpen] = useState(false);

  const closeEmployeeModal = () => {
    setModalOpen(false);
  };

  const addEmployeeClicked = () => {
    setModalOpen(true);
  };

  return (
    <Page title="Employee Dashboard">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4">Employee Dashboard</Typography>

          <Button variant="contained" onClick={addEmployeeClicked}>
            Add Employee
          </Button>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <AdminEmployeeSearch />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <PendingEmployeePayroll />
          </Grid>
        </Grid>
      </Container>

      <Modal open={modalOpen} onClose={closeEmployeeModal}>
        <AddEmployeeModal handleM1Close={closeEmployeeModal} />
      </Modal>
    </Page>
  );
}
