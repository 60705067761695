import React from "react";
import { useState, useEffect } from "react";

import {
  Container,
  Stack,
  Typography,
  CircularProgress,
  Grid,
  Box,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

import { dataFetch, dataPost } from "../../utils/data-fetch";
import { useAuth } from "../../contexts/AuthContext";
import { DoneAllRounded } from "@mui/icons-material";

import { getDateStringFullSync } from "src/utils/date-time-helper";

export default function MyTask({ counter }) {
  const { getAccessToken, activeFranchise } = useAuth();

  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [open, setOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  const handleOpenDialog = (task) => {
    setSelectedTask(task);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedTask(null);
  };

  const handleConfirmUpdate = () => {
    if (selectedTask) {
      updateTask(selectedTask);
    }
    handleCloseDialog();
  };

  const handleSearch = async () => {
    let url = `/api/mgrtasks/mgract/my?status=pending`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  const updateTask = async (task) => {
    let url = `/api/mgrtasks/mgract/task/${task._id}/complete`;

    const ret = await dataPost(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData((prev) => prev.filter((item) => item._id !== task._id));
      console.log("done");
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [activeFranchise, counter]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box sx={{ pt: 2, pb: 2 }}>
      {customerData.length > 0 ? (
        <Grid direction="column">
          {customerData.map((data) => (
            <Grid item xs={12} key={data._id}>
              <Card
                sx={{
                  backgroundColor:
                    data?.status === "pending" ? "#ffecb3" : "#e0f7fa",
                  borderLeft:
                    data?.status === "pending"
                      ? "4px solid #ff6f00"
                      : "4px solid #00838f",
                  m: 1,
                  p: 1,
                }}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6" component="div">
                    {data.name}
                  </Typography>

                  <DoneAllRounded
                    onClick={() => handleOpenDialog(data)}
                    sx={{
                      cursor: "pointer",
                      color: data?.status === "pending" ? "#ff6f00" : "#00838f",
                    }}
                  />
                </Stack>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {data.details}
                </Typography>
                {data.due_date > 0 && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {getDateStringFullSync(data.due_date)}
                  </Typography>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="body2" align="center" sx={{ mt: 3, mb: 2 }}>
          No Data found
        </Typography>
      )}

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to mark this task as completed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmUpdate} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
