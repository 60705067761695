import {
  Grid,
  Container,
  Typography,
  Stack,
  Button,
  Modal,
  Card,
  Box,
  InputAdornment,
  OutlinedInput,
  CircularProgress,
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  TablePagination,
  IconButton,
  TextField,
  MenuItem,
  Tooltip,
} from "@mui/material";

import Page from "../../components/Page";

import { useEffect, useState } from "react";
import { RefreshRounded, SearchRounded } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { dataFetch } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";
import {
  getDateStringFullSync,
  getDateStringFullWithTimeSync,
  getDateStringFullWithTimeSync2,
} from "src/utils/date-time-helper";
import AddInAppMsgModal from "./AddInAppMsgModal";
import AddNotificationMsgModal from "./AddNewNotifcationModal";
import NotificationDetailsPage from "./det/NotificationDetails";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "entity", label: "Entity", alignRight: false },
  { id: "title", label: "Title", alignRight: false },
  { id: "image", label: "Image", alignRight: false },
  { id: "date", label: "Date", alignRight: false },
  { id: "by", label: "Sender", alignRight: false },
  { id: "type", label: "Type", alignRight: false },
];

export default function NotificationsPage() {
  const { getAccessToken, activeFranchise } = useAuth();

  const [loadingData, setLoadingData] = useState(false);
  const [allData, setAllData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [entity, setEntity] = useState("all");

  let timer = "";

  const handleChangeQuery = (event) => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      setAllData([]);
      setPage(0);
      setSearch(event.target.value);
    }, 1000);
  };

  const [currNoti, setCurrNoti] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleNotiSelected = (notiData) => {
    setCurrNoti(notiData);
    setDrawerOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchNotifications = async () => {
    setLoadingData(true);

    let url = `/api/sendnotification/mgract/query?sort=_id&dir=d&skip=${
      page * 5
    }&limit=5`;

    if (search && search.length > 0) {
      url += `&search=${search}`;
    }

    if (entity !== "all") {
      url += `&entity=${entity}`;
    }

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setAllData(ret.data);
      console.log(ret.data);
    } else {
      console.log("error in fetching data");
    }

    setLoadingData(false);
  };

  const onModalClose = () => {
    setModalOpen(false);
    fetchNotifications();
  };
  useEffect(() => {
    fetchNotifications();
  }, [page, search, entity]);

  const onNotificationModalClose = () => {
    setNotificationModalOpen(false);
    fetchNotifications();
  };

  return (
    <Page title="Notifications Dashboard">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h4">Notifications Dashboard</Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button
              variant="contained"
              onClick={() => setNotificationModalOpen(true)}
            >
              New Notif
            </Button>
            <Button variant="contained" onClick={() => setModalOpen(true)}>
              New InApp Msg
            </Button>
          </Stack>
        </Stack>

        <Card sx={{ p: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
            spacing={2}
          >
            <TextField
              select
              id="entity-select"
              value={entity}
              onChange={(e) => setEntity(e.target.value)}
              label="Entity"
              sx={{ minWidth: 100 }}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"inapp"}>In-App Msg</MenuItem>
              <MenuItem value={"notification"}>Notification</MenuItem>
            </TextField>

            <SearchStyle
              placeholder="Search Notifications..."
              onChange={handleChangeQuery}
              startAdornment={
                <InputAdornment position="start">
                  <Box sx={{ color: "text.disabled" }}>
                    <SearchRounded />
                  </Box>
                </InputAdornment>
              }
            />

            {(loadingData === true && (
              <CircularProgress color="secondary" />
            )) || (
              <IconButton onClick={fetchNotifications}>
                <RefreshRounded />
              </IconButton>
            )}
          </Stack>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TableContainer sx={{ minHeight: 100 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.alignRight ? "right" : "left"}
                          sortDirection={false}
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {allData.map((item) => {
                      return (
                        <Tooltip title={item?.body}>
                          <TableRow
                            hover
                            onClick={() => handleNotiSelected(item)}
                            style={{
                              backgroundColor:
                                item.sent === false ? "#ddd" : "white",
                            }}
                          >
                            <TableCell>
                              <b>{item?.entity || "--"}</b>
                            </TableCell>
                            <TableCell>{item?.title || "--"}</TableCell>
                            <TableCell>
                              {(item?.imageUrl && "Yes") || "No"}
                            </TableCell>
                            <TableCell>
                              {item.date
                                ? getDateStringFullWithTimeSync2(item.date)
                                : "--"}
                            </TableCell>
                            <TableCell>{item?.sent_by?.name || "--"}</TableCell>
                            <TableCell>{item?.entity || "--"}</TableCell>
                          </TableRow>
                        </Tooltip>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                component="div"
                count={-1}
                rowsPerPage={5}
                rowsPerPageOptions={[5]}
                page={page}
                onPageChange={handleChangePage}
              />
            </Grid>
          </Grid>
        </Card>
      </Container>

      <NotificationDetailsPage
        notification={currNoti}
        handleClose={() => setDrawerOpen(false)}
        drawerOpen={drawerOpen}
      />

      {modalOpen === true && (
        <AddInAppMsgModal drawerOpen={modalOpen} handleM1Close={onModalClose} />
      )}
      {notificationModalOpen ===true && (
        <AddNotificationMsgModal
          drawerOpen={notificationModalOpen}
          handleM1Close={onNotificationModalClose}
        />
      )}
    </Page>
  );
}
