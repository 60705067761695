import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import {
  Card,
  Alert,
  Stack,
  Typography,
  CircularProgress,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import { dataFetch } from "src/utils/data-fetch";

export default function InternalAccDetailsModal({ handleM1Close, accId }) {
  const [error, setError] = useState();
  const [currentData, setCurrentData] = useState();
  const [loadingData, setLoadingData] = useState();

  const { getAccessToken } = useAuth();

  const load = async (values) => {
    setLoadingData(true);

    const ret = await dataFetch(
      `/api/internalaccs/coreact/acc/${accId}`,
      await getAccessToken()
    );
    if (ret.success === true) {
      console.log(ret.data);
      setCurrentData(ret.data);
    } else {
      console.log(ret.message);
      setError(ret.message + " - " + ret.code);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Card sx={{ p: 3 }}>
        {(loadingData === true && <CircularProgress />) ||
          (currentData && (
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>Name:</b>
                </TableCell>
                <TableCell>
                  <b>{currentData.name}</b> ({currentData.entity})
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Phone:</b>
                </TableCell>
                <TableCell>{currentData.phone || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Active:</b>
                </TableCell>
                <TableCell>
                  {(currentData.active === true && "Yes") || "No"}
                </TableCell>
              </TableRow>

              {currentData.franchisee && (
                <TableRow>
                  <TableCell>
                    <b>Franchisee:</b>
                  </TableCell>
                  <TableCell>{currentData.franchisee.name}</TableCell>
                </TableRow>
              )}

              {currentData.employee_det && (
                <>
                  <TableRow>
                    <TableCell>
                      <b>UIN:</b>
                    </TableCell>
                    <TableCell>{currentData.employee_det.uin}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Roles:</b>
                    </TableCell>
                    <TableCell>
                      {JSON.stringify(currentData.employee_det.roles)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Address:</b>
                    </TableCell>
                    <TableCell>{currentData.employee_det.address}</TableCell>
                  </TableRow>
                </>
              )}

              {currentData.bank_account && (
                <>
                  <TableRow>
                    <TableCell>
                      <b>Acc Number:</b>
                    </TableCell>
                    <TableCell>{currentData.bank_account.acc_no}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>IFSC:</b>
                    </TableCell>
                    <TableCell>{currentData.bank_account.ifsc}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Acc Name:</b>
                    </TableCell>
                    <TableCell>{currentData.bank_account.acc_name}</TableCell>
                  </TableRow>
                </>
              )}

              {currentData.center && currentData.center.official && (
                <>
                  <TableRow>
                    <TableCell>
                      <b>Handler:</b>
                    </TableCell>
                    <TableCell>{currentData.center.handler}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Official:</b>
                    </TableCell>
                    <TableCell>{currentData.center.official}</TableCell>
                  </TableRow>
                </>
              )}

              {currentData.manager && (
                <>
                  <TableRow>
                    <TableCell>
                      <b>Max Expense:</b>
                    </TableCell>
                    <TableCell>{currentData.manager.max_expense}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Max Txn:</b>
                    </TableCell>
                    <TableCell>{currentData.manager.max_txn}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Active Now:</b>
                    </TableCell>
                    <TableCell>
                      {currentData.manager.active_now === true ? "Yes" : "No"}
                    </TableCell>
                  </TableRow>
                </>
              )}

              {currentData.bulksale && (
                <TableRow>
                  <TableCell>
                    <b>Handler:</b>
                  </TableCell>
                  <TableCell>{currentData.bulksale.address}</TableCell>
                </TableRow>
              )}
              {currentData.ledger && (
                <TableRow>
                  <TableCell>
                    <b>Address:</b>
                  </TableCell>
                  <TableCell>{currentData.ledger.address}</TableCell>
                </TableRow>
              )}

              {currentData.created_by && (
                <TableRow>
                  <TableCell>
                    <b>Created By:</b>
                  </TableCell>
                  <TableCell>{currentData.created_by.name}</TableCell>
                </TableRow>
              )}
            </TableBody>
          ))}

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
