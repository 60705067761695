import { LoadingButton } from "@mui/lab";
import {
  Box,
  CardContent,
  CardHeader,
  Container,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Card,
} from "@mui/material";

import { Form, FormikProvider, useFormik } from "formik";

import { useState } from "react";

import { useAuth } from "src/contexts/AuthContext";

import { dataPost } from "src/utils/data-fetch";
import { DateTimeSelector } from "src/utils/date-selector";

import * as Yup from "yup";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddOrderModal({ party, handleM1Close }) {
  const { getAccessToken, activeFranchise } = useAuth();

  const [error, setError] = useState();

  const [selectedDate, setSelectedDate] = useState(Date.now());

  const newOrder = async (values) => {
    const ret = await dataPost(
      `/api/extrasales/mgract/users/user/${party._id}/addorder`,
      await getAccessToken(),
      {
        product: values.product,
        quantity: values.qty,
        rate: values.rate,
        unit: "kg",
        date: selectedDate,
        comments: values.comments,
      },
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    product: Yup.string().required("Product is required"),
    qty: Yup.number().required("Quantity is required"),
    rate: Yup.number().required("Rate is required"),
    comments: Yup.string().optional(),
  });
  const formik = useFormik({
    initialValues: {
      product: "",
      comments: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await newOrder(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Card
      sx={{
        width: "auto",
        maxWidth: "600px", // Adjust this value as needed
        height: "auto",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
      }}
    >
      <CardHeader title="Add Order" />

      <CardContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Select
                  id="prd-select"
                  fullWidth
                  {...getFieldProps("product")}
                  MenuProps={MenuProps}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box sx={{ color: "text.disabled" }}>Product</Box>
                    </InputAdornment>
                  }
                >
                  <MenuItem value={"Milk"}>Milk</MenuItem>
                  <MenuItem value={"Ghee"}>Ghee</MenuItem>
                  <MenuItem value={"Butter"}>Butter</MenuItem>
                  <MenuItem value={"Curd"}>Curd</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={6}>
                <DateTimeSelector
                  value={selectedDate}
                  onChange={setSelectedDate}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Quantity"
                  {...getFieldProps("qty")}
                  error={Boolean(touched.qty && errors.qty)}
                  helperText={touched.qty && errors.qty}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Kg</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Rate"
                  {...getFieldProps("rate")}
                  error={Boolean(touched.rate && errors.rate)}
                  helperText={touched.rate && errors.rate}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="text"
                  label="Comments"
                  {...getFieldProps("comments")}
                  error={Boolean(touched.comments && errors.comments)}
                  helperText={touched.comments && errors.comments}
                />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  sx={{ mt: 3 }}
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </CardContent>
    </Card>
  );
}
