import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import Select from "@mui/material/Select";

import { MobileDateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
    Card,
    Alert,
    Stack,
    TextField,
    Typography,
    Grid,


} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";



export default function PayModal({ handleM1Close, manager }) {
    const [error, setError] = useState();


    const { getAccessToken } = useAuth();

    const payNow = async (values) => {
        const ret = await dataPost(
            `/api/managers/mgract/paytomanager`,
            await getAccessToken(),
            {
                manager: manager._id,
                amount: values.amount,
                comments: values.comments.length > 0 ? values.comments : undefined,
            }
        );
        if (ret.success === true) {
            alert("Sucess");
            handleM1Close();
        } else {
            setError(ret.message + " - " + ret.code);
        }
    };

    const DataSchema = Yup.object().shape({
        amount: Yup.number().required("Enter amount!"),
        comments: Yup.string().optional(),
    });

    const formik = useFormik({
        initialValues: {
            amount: "",
            comments: "",
        },
        validationSchema: DataSchema,
        onSubmit: async (values) => {
            await payNow(values);
        },
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <div>
            <Card
                sx={{
                    px: 4,
                    py: 4,
                    minWidth: 400,
                    height: "fit-content",
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ mb: 2 }}
                >
                    <Typography variant="h5" gutterBottom>
                        {manager.name} - Pay
                    </Typography>
                </Stack>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Stack spacing={3}></Stack>
                    </Grid>
                </Grid>

                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <Stack spacing={3}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Amount"
                                        {...getFieldProps("amount")}
                                        error={Boolean(touched.amount && errors.amount)}
                                        helperText={touched.amount && errors.amount}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Stack spacing={3}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="Comments"
                                        {...getFieldProps("comments")}
                                        error={Boolean(touched.comments && errors.comments)}
                                        helperText={touched.comments && errors.comments}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            sx={{ mt: 3 }}
                        >
                            Pay
                        </LoadingButton>
                    </Form>
                </FormikProvider>

                {error && (
                    <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
                        {error}
                    </Alert>
                )}
            </Card>

        </div>
    );
}
