import { Navigate, useRoutes } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";

// layouts
// import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import DashboardLayout from "./layouts/dashboard";
import AdminLayout from "./layouts/admin";

//
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import AdminMap from "./pages/map/AdminMap";

import MilkCartsPage from "./pages/milkcart/MilkCartsPage";

import Dispense from "./pages/dispense/Dispense";
import Farmers from "./pages/farmer/Farmers";
import Centers from "./pages/collection/Centers";
import OnlinePayments from "./pages/op/OnlinePayments";
import Employees from "./pages/employee/Employees";
import Managers from "./pages/manager/Managers";
import EmployeeDetails from "./pages/employee/det/EmployeeDetailsPage";
import InternalAccPage from "./pages/internalacc/InternalAccPage";
import InternalAccPageAdmin from "./pages/admin/internalacc/InternalAccPageAdmin";
import ExpenseCatPage from "./pages/internalacc/ExpenseCatPage";
import TxnsCatPage from "./pages/internalacc/TxnsCatPage";
import TxnsCatPageAdmin from "./pages/admin/internalacc/txns/TxnsCatPageAdmin";
import InternalAccDetailsPage from "./pages/admin/internalacc/details/InternalAccDetailsPage";
import MilkCartDetails from "./pages/milkcart/det/MilkCartDetails";
import AdminEmployees from "./pages/admin/employee/AdminEmployees";
import CRM from "./pages/crm/CRM";
import EmployeePayrollPage from "./pages/employee/payroll/EmployeePayrollPage";
import LedgersPageAdmin from "./pages/admin/internalacc/ledgers/LedgersPageAdmin";
import FarmerDetailsPage from "./pages/farmer/det/FarmerDetailsPage";
import CustomerDetailsPage from "./pages/home/det/CustomerDetailsPage";
import ManagerDetailsPage from "./pages/manager/det/ManagerDetailsPage";
import Dispensers from "./pages/admin/dispensers/Dispensers";
import AdminVehicles from "./pages/admin/vehicles/AdminVehicles";
import AdminVehiclesDetailsPage from "./pages/admin/vehicles/details/AdminVehiclesDetailsPage";
import DispensersDetailsPage from "./pages/admin/dispensers/details/DispenserDetailsPage";
import Vehicles from "./pages/vehicle/Vehicles";
import VehiclesDetailsPage from "./pages/vehicle/details/VehiclesDetailsPage";
import AdminManagers from "./pages/admin/manager/AdminManagers";
import AdminManagerDetailsPage from "./pages/admin/manager/details/AdminManagerDetailsPage";
import DispenseDetailsPage from "./pages/dispense/DispenseDetailsPage";
import AdminFranchisee from "./pages/admin/franchisee/AdminFranchisee";
import Expenses from "./pages/expense/Expenses";
import AdminFranchiseeDetailsPage from "./pages/admin/franchisee/details/AdminFranchiseeDetailsPage";
import EmployeeComplaintsPage from "./pages/employee/complaint/EmployeeComplaintsPage";
import EmployeeAttn from "./pages/employee/attn/EmployeeAttn";
import ApartmentPage from "./pages/apartment/ApartmentPage";
import AdminEmployeeDetailsPage from "./pages/admin/employee/details/AdminEmployeeDetails";
import ApartmentDetailsPage from "./pages/apartment/det/ApartmentDetailsPage";
import Reports from "./pages/reports/Reports";
import MilkflowDashboard from "./pages/milkflow/MilkflowDashboard";
import MilkflowPage from "./pages/admin/manager/MilkflowPage";
import PLDashboard from "./pages/P&L/PLDshboard";
import Tasks from "./pages/tasks/Tasks";
import BulkSale from "./pages/bulksale/BulkSale";
import Products from "./pages/products/Products";
import Calls from "./pages/calls/Calls";
import CampaignPage from "./pages/campaign/CampaignPage";
import NotificationsPage from "./pages/notif/NotificationsPage";
import MapLayout from "./layouts/map";
import AdminMapSame from "./pages/map/AdminMapSame";
import { ChatSystem } from "./pages/customer-support/ChatSystem";
import CROHome from "./pages-cro/home";
import CROLayout from "./layouts/cro";

// ----------------------------------------------------------------------

export default function Router() {
  const { currentUser } = useAuth();

  const rts = useRoutes([
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        // { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/dashboard" /> },
      ],
    },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { path: "", element: <Navigate to="/dashboard/milkcarts" replace /> },
        { path: "tasks", element: <Tasks /> },
        { path: "bulksale", element: <BulkSale /> },
        { path: "reports", element: <Reports /> },
        { path: "customers", element: <Home /> },
        { path: "products", element: <Products /> },
        { path: "customers/details", element: <CustomerDetailsPage /> },
        { path: "map", element: <AdminMapSame /> },
        { path: "milkcarts", element: <MilkCartsPage /> },
        { path: "milkcarts/details", element: <MilkCartDetails /> },
        { path: "dispenses", element: <Dispense /> },
        { path: "dispenses/details", element: <DispenseDetailsPage /> },
        { path: "farmers", element: <Farmers /> },
        { path: "farmers/details", element: <FarmerDetailsPage /> },
        { path: "collection", element: <Centers /> },
        { path: "onlinepayments", element: <OnlinePayments /> },
        { path: "employees", element: <Employees /> },
        { path: "employees/details", element: <EmployeeDetails /> },
        { path: "employees/attendance", element: <EmployeeAttn /> },
        { path: "employees/payroll", element: <EmployeePayrollPage /> },
        { path: "employees/complaints", element: <EmployeeComplaintsPage /> },
        { path: "managers", element: <Managers /> },
        { path: "managers/details", element: <ManagerDetailsPage /> },
        { path: "crm", element: <CRM /> },
        { path: "calls", element: <Calls /> },
        { path: "expenses", element: <Expenses /> },
        { path: "accounts", element: <InternalAccPage /> },
        { path: "accounts/expensecat", element: <ExpenseCatPage /> },
        { path: "accounts/txnscat", element: <TxnsCatPage /> },
        { path: "vehicles", element: <Vehicles /> },
        { path: "vehicles/details", element: <VehiclesDetailsPage /> },
        { path: "apartments", element: <ApartmentPage /> },
        { path: "apartments/details", element: <ApartmentDetailsPage /> },
        { path: "milkflow", element: <MilkflowDashboard /> },
        { path: "campaign", element: <CampaignPage /> },
        { path: "notifications", element: <NotificationsPage /> },
        { path: "pl", element: <PLDashboard /> },
      ],
    },
    {
      path: "/admin",
      element: <AdminLayout />,
      children: [
        { path: "", element: <Navigate to="/admin/home" replace /> },
        { path: "home", element: <Home /> },
        { path: "employees", element: <AdminEmployees /> },
        { path: "employees/details", element: <AdminEmployeeDetailsPage /> },
        { path: "managers", element: <AdminManagers /> },
        { path: "managers/details", element: <AdminManagerDetailsPage /> },
        { path: "accounts", element: <InternalAccPageAdmin /> },
        { path: "accounts/details", element: <InternalAccDetailsPage /> },
        { path: "accounts/txnscat", element: <TxnsCatPageAdmin /> },
        { path: "accounts/ledgers", element: <LedgersPageAdmin /> },
        { path: "vehicles", element: <AdminVehicles /> },
        { path: "vehicles/details", element: <AdminVehiclesDetailsPage /> },
        { path: "dispensers", element: <Dispensers /> },
        { path: "dispensers/details", element: <DispensersDetailsPage /> },
        { path: "franchisee", element: <AdminFranchisee /> },
        { path: "franchisee/details", element: <AdminFranchiseeDetailsPage /> },
        { path: "milkflow", element: <MilkflowPage /> },
      ],
    },
    {
      path: "/map",
      element: <MapLayout />,
      children: [
        { path: "", element: <Navigate to="/map/home" replace /> },
        { path: "home", element: <AdminMap /> },
      ],
    },
    {
      path: "/customer-support",
      children: [{ path: "chat-system", element: <ChatSystem /> }],
    },
    {
      path: "/cro",
      element: <CROLayout />,
      children: [
        { path: "", element: <Navigate to="/cro/home" replace /> },
        { path: "home", element: <CROHome /> },
      ],
    },

    { path: "*", element: <Navigate to="/404" replace /> },
  ]);

  const rts2 = useRoutes([
    { path: "*", element: <Navigate to="/login" replace /> },
    { path: "/login", element: <Login /> },
  ]);

  return currentUser ? rts : rts2;
}
