import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";
import {
  Card,
  Typography,
  Grid,
  Stack,
  Modal,
  Button,
  IconButton,
} from "@mui/material";


import ManagerInfoCard from "./ManagerInfoCard";
import ManagerImageCard from "./ManagerImageCard";
import PayModal from "./PayModal";
import ReturnModal from "./ReturnModal";
import CredentialsModal from "./CredentialsModal";

export default function ManagerProfileSection({ manager }) {
  const [currentData, setCurrentData] = useState();
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const [payModal, setPayModal] = useState(false);
  const [returnModal, setReturnModal] = useState(false);
  const [credentialsModal, setCredentialsModal] = useState(false);

  const closePayModal = () => {
    setPayModal(false);
    loadData();
  };

  const closeReturnModal = () => {
    setReturnModal(false);
    loadData();
  };

  const closeCredentialsModal = () => {
    setCredentialsModal(false);
    loadData();
  };

  const loadData = async () => {
    setLoadingData(true);
    let url = `/api/managers/mgract/manager/${manager._id}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCurrentData(ret.data);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <ManagerImageCard loadingData={loadingData} manager={currentData} />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >


                  <Stack direction="row" alignItems={"space_between"} spacing={2}>
                    <Button variant="contained" onClick={() => {
                      setPayModal(true);
                    }}> PAY </Button>
                    <Button variant="contained" onClick={() => {
                      setReturnModal(true);
                    }}>RETURN</Button>
                    <Button variant="contained" onClick={() => {
                      setCredentialsModal(true);
                    }}>CREDENTIALS</Button>
                  </Stack>
                </Stack>
            
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <ManagerInfoCard
            loadingData={loadingData}
            manager={currentData}
            reload={() => loadData()}
          />
        </Grid>
      </Grid>
      <Modal open={payModal} onClose={closePayModal}>
        <PayModal
          handleM1Close={closePayModal}
          manager={manager}
        />
      </Modal>
      <Modal open={returnModal} onClose={closeReturnModal}>
        <ReturnModal
          handleM1Close={closeReturnModal}
          manager={manager}
        />
      </Modal>
      <Modal open={credentialsModal} onClose={closeCredentialsModal}>
        <CredentialsModal
          handleM1Close={closeCredentialsModal}
          manager={manager}
        />
      </Modal>

    </>
  );
}
