import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "src/contexts/AuthContext";

import { styled } from "@mui/material/styles";
import { dataFetch } from "src/utils/data-fetch";

import { SearchRounded } from "@mui/icons-material";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {
  Card,
  Container,
  Table,
  Box,
  OutlinedInput,
  InputAdornment,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  CircularProgress,
  Stack,
} from "@mui/material";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "driver_name", label: "Driver Name", alignRight: true },
];

export default function MilkCartSearch({ onCartSelected }) {
  const [field, setField] = useState("name");
  const [cartData, setCartData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [page, setPage] = useState(0);

  const { getAccessToken, activeFranchise } = useAuth();

  const navigate = useNavigate();

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/milkcarts/mgract/query`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCartData(ret.data);
      console.log(ret.data);
    } else {
      console.log("error in fetching data");
    }

    setLoadingData(false);
  };

  useEffect(() => {
    handleSearch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card title="Search Carts">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          mt={2}
          spacing={2}
        >
          <SearchStyle
            placeholder="Search Carts..."
            //onChange={handleChangeQuery}
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>
                  <SearchRounded />
                </Box>
              </InputAdornment>
            }
            endAdornment={
              loadingData === true && <CircularProgress color="secondary" />
            }
            sx={{ mt: 4, mb: 4 }}
            autoFocus={true}
          />

          <Select
            id="field-select"
            value={field}
            label="Search In"
            onChange={(e) => setField(e.target.value)}
          >
            <MenuItem value={"name"}>Name</MenuItem>
            <MenuItem value={"driver_name"}>Driver Name</MenuItem>
          </Select>
        </Stack>
        {cartData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow
                    hover
                    onClick={() => onCartSelected({ _id: null })}
                    style={{
                      backgroundColor: "#ddd",
                    }}
                  >
                    <TableCell>
                      <b>{"Remove Assigned Cart"}</b>
                    </TableCell>
                    <TableCell>{"-"}</TableCell>
                  </TableRow>
                  {cartData.map((item) => { 
                    return (
                      <TableRow
                        hover
                        onClick={() => onCartSelected(item)}
                        style={{
                          backgroundColor:
                            item.active === false ? "#ddd" : "white",
                        }}
                      >
                        <TableCell>
                          <b>{item ? item.name : ""}</b>
                        </TableCell>
                        <TableCell>
                          {item && item.driver_name ? item.driver_name : "-"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </Container>
    </Card>
  );
}
