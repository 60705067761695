import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  OutlinedInput,
  InputAdornment,
  Box,
  Drawer,
  IconButton,
  MenuItem,
  CircularProgress,
  Button,
  Modal,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";
import {
  AddRounded,
  CalendarViewDay,
  CancelRounded,
  PauseCircleRounded,
  PlayCircleRounded,
  RemoveRounded,
} from "@mui/icons-material";
import { useSnackbar } from "src/contexts/SnackbarContext";
import MilkCartSearch from "src/pages/apartment/det/CartSearch";
import { ModifySubDDateModal } from "./ModifySubDDateModal";
import { getDayStartAndEndSync } from "src/utils/date-time-helper";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ModifySubDModal({ handleM1Close, currentSub }) {
  const [error, setError] = useState();
  const [openDateModify, setOpenDateModify] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [selectedQty, setSelectedQty] = useState(currentSub?.quantity || 1);
  const [slot, setSlot] = useState(`${currentSub?.slot || 1}`);
  const [message, setMessage] = useState(currentSub?.comments || "");

  const [loadingData, setLoadingData] = useState(false);

  const handleDateModifyClose = () => {
    setOpenDateModify(false);
    handleM1Close();
  };

  const approveNow = async (values) => {
    setError(null);

    setLoadingData(true);

    let up = {
      quantity: selectedQty,
      slot: slot,
      comments: message,
      date: currentSub.date,
    };

    if (currentSub.subscription) {
      up.subscription = currentSub.subscription;
    } else {
      up.id = currentSub._id;
    }

    const ret = await dataPost(
      `/api/products/mgract/subdeliveries/modify`,
      await getAccessToken(),
      up,
      activeFranchise
    );
    if (ret.success === true) {
      showSnackbar(ret.message || "Subscription modified", "success");
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }

    setLoadingData(false);
  };

  const pauseResumeSub = async () => {
    setLoadingInitial(true);

    const up = {};
    if (!currentSub.subscription) {
      up.id = currentSub._id;
      up.date = currentSub.date;
      up.pause = true;
    } else if (currentSub.status === "pending") {
      up.subscription = currentSub.subscription;
      up.date = currentSub.date;
      up.pause = true;
    } else if (currentSub.status === "paused") {
      up.subscription = currentSub.subscription;
      up.date = currentSub.date;
      up.resume = true;
    } else {
      return;
    }

    const ret = await dataPost(
      `/api/products/mgract/subdeliveries/modify`,
      await getAccessToken(),
      up,
      activeFranchise
    );

    if (ret.success === true) {
      showSnackbar(ret.message || "Entry modified", "success");
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }

    setLoadingInitial(false);
  };

  const [changeCartDrawerState, setChangeCartDrawerState] = useState(false);

  const cartSearchDrawerReturn = (cart) => {
    setChangeCartDrawerState(false);
    updateCartInSubDelivery(cart._id);
  };

  const updateCartInSubDelivery = async (cart) => {
    setLoadingInitial(true);

    const up = { cart: cart };

    const ret = await dataPost(
      `/api/products/mgract/subdelivery/${currentSub._id}/cart`,
      await getAccessToken(),
      up,
      activeFranchise
    );

    if (ret.success === true) {
      showSnackbar(ret.message || "Entry modified", "success");
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }

    setLoadingInitial(false);
  };

  const todayStart = getDayStartAndEndSync(Date.now()).start;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Modify Delivery Entry
          </Typography>

          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="info"
              onClick={() => setOpenDateModify(true)}
              startIcon={<CalendarViewDay />}
            >
              Change Date
            </Button>

            {["pending", "paused"].includes(currentSub?.status) &&
              currentSub.date >= todayStart &&
              currentSub?._id && (
                <Button
                  variant="contained"
                  color={"info"}
                  onClick={() => setChangeCartDrawerState(true)}
                >
                  Change Cart
                </Button>
              )}

            {["pending", "paused"].includes(currentSub?.status) && (
              <Button
                variant="contained"
                color={currentSub?.status === "paused" ? "success" : "error"}
                startIcon={
                  currentSub.subscription ? (
                    currentSub?.status === "paused" ? (
                      <PlayCircleRounded />
                    ) : (
                      <PauseCircleRounded />
                    )
                  ) : (
                    <CancelRounded />
                  )
                }
                onClick={pauseResumeSub}
              >
                {currentSub.subscription
                  ? currentSub?.status === "paused"
                    ? "Resume"
                    : "Pause"
                  : "Cancel"}
              </Button>
            )}
          </Stack>
        </Stack>

        {loadingInitial && <CircularProgress />}

        {loadingInitial === false && currentSub && (
          <Stack spacing={3}>
            <OutlinedInput
              fullWidth
              type="text"
              value={currentSub?.user?.name || ""}
              startAdornment={
                <InputAdornment position="start">
                  <Box sx={{ color: "text.disabled" }}>Customer</Box>
                </InputAdornment>
              }
            />

            <OutlinedInput
              fullWidth
              type="text"
              value={currentSub?.product?.name || ""}
              startAdornment={
                <InputAdornment position="start">
                  <Box sx={{ color: "text.disabled" }}>Product</Box>
                </InputAdornment>
              }
            />

            <Stack direction={"row"} spacing={3} alignItems={"center"}>
              <Stack direction={"row"} spacing={3} alignItems={"center"}>
                <Box sx={{ color: "black.main" }}>Quantity:</Box>
                <IconButton
                  onClick={() => {
                    let qt = currentSub?.product?.quantum || 1;
                    if (selectedQty > qt) {
                      setSelectedQty(selectedQty - qt);
                    }
                  }}
                  color="black"
                >
                  <RemoveRounded />
                </IconButton>

                <Typography variant="h5">{selectedQty?.toFixed(1)}</Typography>

                <IconButton
                  onClick={() => {
                    let qt = currentSub?.product?.quantum || 1;
                    setSelectedQty(selectedQty + qt);
                  }}
                  color="black"
                >
                  <AddRounded />
                </IconButton>
              </Stack>

              <TextField
                select
                label="Slot"
                sx={{ minWidth: 150 }}
                value={slot}
                onChange={(e) => setSlot(e.target.value)}
              >
                <MenuItem value="1">5AM - 7AM</MenuItem>
                <MenuItem value="2">7AM - 9AM</MenuItem>
                <MenuItem value="3">9AM - 11AM</MenuItem>
                <MenuItem value="4">5PM - 7PM</MenuItem>
                <MenuItem value="5">7PM - 9PM</MenuItem>
              </TextField>
            </Stack>

            <TextField
              fullWidth
              type="text"
              label="Instructions"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loadingData}
              sx={{ mt: 3 }}
              onClick={approveNow}
            >
              Save
            </LoadingButton>
          </Stack>
        )}

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>

      <Modal open={openDateModify} onClose={handleDateModifyClose}>
        <ModifySubDDateModal
          subd={currentSub}
          handleM1Close={handleDateModifyClose}
        />
      </Modal>

      {changeCartDrawerState === true && (
        <Drawer
          anchor={"right"}
          open={changeCartDrawerState}
          onClose={() => setChangeCartDrawerState(false)}
          sx={{ zIndex: 1300, p: 3 }}
        >
          <MilkCartSearch onCartSelected={cartSearchDrawerReturn} />
        </Drawer>
      )}
    </div>
  );
}
