import { useState, useEffect } from "react";
import { useAuth } from "../../../../contexts/AuthContext";

import { dataFetch } from "../../../../utils/data-fetch";

import { ControlPointDuplicateRounded, RefreshRounded } from "@mui/icons-material";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Stack,
  Modal,
  Tooltip,
  IconButton,
} from "@mui/material";

import {
  getDateStringFullWithTimeSync,
  getDayStart,
  getDayEnd,
  getDayStartAndEndSync,
} from "src/utils/date-time-helper";
import InternalTxnDetailsModalAdmin from "../txns/InternalTxnDetailsModalAdmin";
import { MobileDatePicker } from "@mui/x-date-pickers";

import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AddTxnModal from "../txns/AddTxnModal";

const TABLE_HEAD = [
  { id: "amount", label: "Amount", alignRight: false },
  { id: "entity", label: "Entity", alignRight: false },
  { id: "from", label: "From", alignRight: false },
  { id: "to", label: "To", alignRight: false },
  { id: "date", label: "Date", alignRight: false },
  { id: "method", label: "Method", alignRight: false },
  { id: "center", label: "Center", alignRight: false },
  { id: "comments", label: "Comments", alignRight: false },
];

export default function InternalTxnsByAccount({ account }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken } = useAuth();

  const [startDate, setStartDate] = useState(getDayStart(Date.now()));
  const [endDate, setEndDate] = useState(getDayEnd(Date.now()));

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/internalaccs/coreact/txns/query?sort=date&dir=a&date_gt=${startDate}&date_lt=${endDate}&account=${account._id}`;

    const ret = await dataFetch(url, await getAccessToken());
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const [payoutModalOpen, setPayoutModalOpen] = useState(null);

  const closePayoutModal = () => {
    setPayoutModalOpen(null);
  };

  const openPayoutModal = async (id) => {
    setPayoutModalOpen(id);
  };

  const duplicateTxnClicked = () => {
    setDuplicateTxnModalOpen(true);
  };

  const [duplicateTxnModalOpen, setDuplicateTxnModalOpen] = useState(false);
  const [duplicateData, setDuplicateData] = useState();

  const closeDuplicateTxnModal = () => {
    setDuplicateTxnModalOpen(false);
  };

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            Txns Data
          </Typography>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={"en-in"}
            >
              <MobileDatePicker
                value={moment(startDate)}
                onChange={(newValue) => {
                  if (newValue) {
                    const d1 = getDayStartAndEndSync(newValue.valueOf());
                    if (startDate !== d1.start) {
                      setStartDate(d1.start);
                      setEndDate(d1.end);
                    }
                  }
                }}
                closeOnSelect={true}
                format="DD/MM/YYYY"
                loading={loadingData}
              />
            </LocalizationProvider>

            <b>-</b>

            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={"en-in"}
            >
              <MobileDatePicker
                value={moment(endDate)}
                onChange={(newValue) => {
                  if (newValue) {
                    const d1 = getDayEnd(newValue.valueOf());
                    if (endDate !== d1) setEndDate(d1);
                  }
                }}
                closeOnSelect={true}
                format="DD/MM/YYYY"
                loading={loadingData}
              />
            </LocalizationProvider>
          </Stack>

          {(loadingData === true && <CircularProgress color="secondary" />) || (
            <IconButton onClick={() => handleSearch()}>
              <RefreshRounded />
            </IconButton>
          )}
        </Stack>

        {customerData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {customerData.map((item) => {
                    const color =
                      item.status === "success"
                        ? "white"
                        : [
                            "pending",
                            "processing",
                            "initiated",
                            "approved",
                          ].includes(item.status)
                        ? "lightcyan"
                        : "lightsalmon";
                    return (
                      <TableRow
                        hover
                        onClick={() => openPayoutModal(item._id)}
                        style={{ backgroundColor: color }}
                      >
                        <TableCell>
                          <b>₹ {item.amount.toFixed(1)}</b>
                        </TableCell>
                        <TableCell>{item.entity}</TableCell>
                        <TableCell sx={{ color: "success.dark" }}>
                          {item.from ? item.from.name : "-"}
                        </TableCell>
                        <TableCell sx={{ color: "error.main" }}>
                          {item.to ? item.to.name : "-"}
                        </TableCell>
                        <TableCell sx={{ color: "primary.dark" }}>
                          <b>{getDateStringFullWithTimeSync(item.date)}</b>
                        </TableCell>
                        <TableCell>
                          {item.method && item.method.toUpperCase()}
                        </TableCell>
                        <TableCell>
                          {item.franchisee ? item.franchisee.name : "-"}
                        </TableCell>
                        <Tooltip title={item.comments || ""} arrow>
                          <TableCell>
                            {item.comments
                              ? item.comments.substring(0, 15)
                              : "-"}
                          </TableCell>
                        </Tooltip>

                        <TableCell
                          onClick={(e) => {
                            e.stopPropagation();
                            setDuplicateData(item);
                            duplicateTxnClicked();
                          }}
                        >
                          <ControlPointDuplicateRounded />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}

        {customerData.length === 0 && loadingData === false && (
          <div>
            <Typography gutterBottom align="center" variant="subtitle1">
              Not found
            </Typography>
            <Typography variant="body2" align="center" sx={{ mb: 4 }}>
              No results here.
            </Typography>
          </div>
        )}
      </Container>

      <InternalTxnDetailsModalAdmin
        handleM1Close={closePayoutModal}
        poutId={payoutModalOpen}
        drawerOpen={payoutModalOpen ? true : false}
      />

      <Modal open={duplicateTxnModalOpen} onClose={closeDuplicateTxnModal}>
        <AddTxnModal
          handleM1Close={closeDuplicateTxnModal}
          duplicateData={duplicateData}
        />
      </Modal>
    </Card>
  );
}
