import { CloseRounded } from "@mui/icons-material";
import {
  Typography,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Button,
} from "@mui/material";
import { forwardRef } from "react";
import EmployeeDetailsPage from "./EmployeeDetailsPage";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenEmpDetailsDialog({
  emp,
  handleClose,
  drawerOpen,
}) {
  return (
    <Dialog
      maxWidth="lg"
      scroll="body"
      fullWidth
      open={drawerOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            {emp?.name} ({emp?.address || "-"})
          </Typography>
          <Button
            edge="end"
            color="error"
            onClick={handleClose}
            variant="contained"
          >
            <CloseRounded />
          </Button>
        </Toolbar>
      </AppBar>
      <EmployeeDetailsPage incEmployee={emp} />
    </Dialog>
  );
}
