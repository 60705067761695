import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import Select from "@mui/material/Select";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Grid,
  Box,
  Switch,
  Chip,
  MenuItem,
  Drawer,
  InputAdornment,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPatch } from "src/utils/data-fetch";
import {
  getTimeStringNew,
  getTimeStringFull,
  parseTimeString,
} from "src/utils/date-time-helper";
import MilkCartSearch from "./CartSearch";
// No import for doesSectionFormatHaveLeadingZeros

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const {
  getSlotValue,
  societyApps,
  societyTypes,
} = require("../addapt/AddAptModal");

export const EditApartmentModal = ({ handleM1Close, apartment }) => {
  const [error, setError] = useState(null); // Initialize error as null

  const { getAccessToken, activeFranchise } = useAuth();

  const updateData = async (values) => {
    try {
      const up = {
        name: values.name,
        flats: values.flats,
        flats_occupied: values.flats_occupied,
        tower_count: values.tower_count,
        pincode: values.pincode,
        society_type: values.society_type,
        property_price: values.property_price,
        property_rent: values.property_rent,
        elevator: values.elevator,
        society_app: values.society_app,
        slots: values.slots,
        active: values.active,
      };

      const response = await dataPatch(
        `/api/apartments/mgract/appt/${apartment._id}`,
        await getAccessToken(),
        up,
        activeFranchise
      );

      if (response.success) {
        handleM1Close();
      } else {
        setError(response.message + " - " + response.code);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const DataSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    flats: Yup.number().required("Flats are required"),
    flats_occupied: Yup.number().required("Occupied flats are required"),
    tower_count: Yup.number().required("Tower count is required"),
    pincode: Yup.string().required("Pincode is required"),
    society_type: Yup.string().required("Society type is required"),
    property_price: Yup.number().required("Property price is required"),
    property_rent: Yup.number().required("Property rent is required"),
    elevator: Yup.boolean().required("Elevator status is required"),
    society_app: Yup.string().required("Society app is required"),
    slots: Yup.array().required("Slots are required"),
    active: Yup.boolean().required("Active status is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: apartment?.name,
      flats: apartment?.flats || 0,
      flats_occupied: apartment?.flats_occupied || 0,
      tower_count: apartment?.tower_count || 1,
      pincode: apartment?.pincode || "560035",
      society_type: apartment?.society_type || "Default",
      property_price: apartment?.property_price || 0,
      property_rent: apartment?.property_rent || 0,
      elevator: apartment?.elevator || true,
      society_app: apartment?.society_app || "none",
      slots: apartment?.slots || [1, 2, 3, 4, 5],
      active: apartment?.active || true,
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await updateData(values);
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h4" align="center">
            Edit Apartment
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={2} direction={"column"}>
                  <TextField
                    fullWidth
                    label="Name"
                    type="text"
                    {...getFieldProps("name")}
                    helperText={touched.name && errors.name}
                    error={Boolean(touched.name && errors.name)}
                    inputProps={{
                      style: { textTransform: "capitalize" },
                    }}
                  />

                  <TextField
                    fullWidth
                    type="number"
                    label="Total Flats"
                    {...getFieldProps("flats")}
                    helperText={touched.flats && errors.flats}
                    error={Boolean(touched.flats && errors.flats)}
                  />

                  <TextField
                    fullWidth
                    type="number"
                    label="Occupied Flats"
                    {...getFieldProps("flats_occupied")}
                    helperText={touched.flats_occupied && errors.flats_occupied}
                    error={Boolean(
                      touched.flats_occupied && errors.flats_occupied
                    )}
                  />

                  <TextField
                    fullWidth
                    type="number"
                    label="No of Towers / Lanes"
                    {...getFieldProps("tower_count")}
                    helperText={touched.tower_count && errors.tower_count}
                    error={Boolean(touched.tower_count && errors.tower_count)}
                  />

                  <Select
                    fullWidth
                    multiple
                    {...getFieldProps("slots")}
                    renderValue={(selected) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                          alignItems: "center",
                        }}
                      >
                        Slots:
                        {selected.map((value) => (
                          <Chip key={value} label={getSlotValue(value)} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {[1, 2, 3, 4, 5].map((slot) => (
                      <MenuItem value={slot}>{getSlotValue(slot)}</MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={2} direction={"column"}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Pincode"
                    {...getFieldProps("pincode")}
                    helperText={touched.pincode && errors.pincode}
                    error={Boolean(touched.pincode && errors.pincode)}
                  />

                  <TextField
                    fullWidth
                    select
                    label="Society Type"
                    {...getFieldProps("society_type")}
                    helperText={touched.society_type && errors.society_type}
                    error={Boolean(touched.society_type && errors.society_type)}
                  >
                    {societyTypes.map((type) => (
                      <MenuItem value={type}>{type}</MenuItem>
                    ))}
                  </TextField>

                  <Stack direction={"row"} spacing={2}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Property Price"
                      {...getFieldProps("property_price")}
                      helperText={
                        touched.property_price && errors.property_price
                      }
                      error={Boolean(
                        touched.property_price && errors.property_price
                      )}
                    />

                    <TextField
                      fullWidth
                      type="number"
                      label="Rent"
                      {...getFieldProps("property_rent")}
                      helperText={touched.property_rent && errors.property_rent}
                      error={Boolean(
                        touched.property_rent && errors.property_rent
                      )}
                    />
                  </Stack>

                  <TextField
                    fullWidth
                    label="Elevator Available?"
                    value={getFieldProps("elevator").value ? "Yes" : "No"}
                    onChange={(e) => {
                      if (e.target.value?.includes("y")) {
                        setFieldValue("elevator", true);
                      } else if (e.target.value?.includes("n")) {
                        setFieldValue("elevator", false);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Switch
                            checked={getFieldProps("elevator").value}
                            onChange={(e) =>
                              setFieldValue("elevator", e.target.checked)
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    fullWidth
                    select
                    label="Society App"
                    {...getFieldProps("society_app")}
                  >
                    {Object.keys(societyApps).map((app) => (
                      <MenuItem value={app}>{societyApps[app]}</MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    fullWidth
                    label="Active?"
                    value={getFieldProps("active").value ? "Yes" : "No"}
                    onChange={(e) => {
                      if (e.target.value?.includes("y")) {
                        setFieldValue("active", true);
                      } else if (e.target.value?.includes("n")) {
                        setFieldValue("active", false);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Switch
                            checked={getFieldProps("active").value}
                            onChange={(e) =>
                              setFieldValue("active", e.target.checked)
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
};
