import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import Select from "@mui/material/Select";

import { MobileDateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Grid,
  Box,
  InputAdornment,
  MenuItem,
  Drawer,
  OutlinedInput,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";

import InternalAccSearch from "../ledgers/InternalAccSearch";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddTxnModal({ handleM1Close, duplicateData }) {
  const [error, setError] = useState();

  const [selectedFrom, setSelectedFrom] = useState(
    (duplicateData && duplicateData.from) || {}
  );
  const [selectedTo, setSelectedTo] = useState(
    (duplicateData && duplicateData.to) || {}
  );
  const [selectedDate, setSelectedDate] = useState(
    (duplicateData && duplicateData.date) || Date.now()
  );

  const { getAccessToken, activeFranchise, allFranchisees } = useAuth();

  const approveNow = async (values) => {
    const ret = await dataPost(
      `/api/internalaccs/coreact/txns/add`,
      await getAccessToken(),
      {
        franchisee: values.franchisee,
        entity: values.entity,
        amount: values.amount,
        method: values.method,
        comments: values.comments.length > 0 ? values.comments : undefined,
        date: selectedDate,
        from: (selectedFrom && selectedFrom._id) || undefined,
        to: (selectedTo && selectedTo._id) || undefined,
      }
    );
    if (ret.success === true) {
      alert("Txn added");
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    franchisee: Yup.string().optional(),
    entity: Yup.string().required("Entity is required"),
    amount: Yup.number().required("Enter amount!"),
    method: Yup.string().required("Method is required"),
    comments: Yup.string().optional(),
  });

  const formik = useFormik({
    initialValues: {
      franchisee:
        (duplicateData &&
          duplicateData.franchisee &&
          duplicateData.franchisee._id) ||
        undefined,
      entity: (duplicateData && duplicateData.entity) || "cashflow",
      method: (duplicateData && duplicateData.method) || "cash",
      amount: "",
      comments: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const [searchDrawerState, setSearchDrawerState] = useState(null);

  const drawerSearchInit = (type) => {
    setSearchDrawerState(type);
  };

  const drawerReturnSearch = (acc) => {
    console.log(acc);
    if (searchDrawerState === "from") {
      setSelectedFrom(acc);
    } else if (searchDrawerState === "to") {
      setSelectedTo(acc);
    }
    setSearchDrawerState(null);
  };

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Add Txn
          </Typography>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Stack spacing={3}></Stack>
          </Grid>
        </Grid>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={3}>
                  <Select
                    id="fr-select"
                    {...getFieldProps("franchisee")}
                    MenuProps={MenuProps}
                    startAdornment={
                      <InputAdornment position="start">
                        <Box sx={{ color: "text.disabled" }}>Franchise</Box>
                      </InputAdornment>
                    }
                  >
                    {allFranchisees.map((item) => (
                      <MenuItem value={item._id}>{item.name}</MenuItem>
                    ))}
                  </Select>

                  <OutlinedInput
                    fullWidth
                    type="text"
                    value={(selectedFrom && selectedFrom.name) || ""}
                    onClick={() => drawerSearchInit("from")}
                    startAdornment={
                      <InputAdornment position="start">
                        <Box sx={{ color: "text.disabled" }}>From</Box>
                      </InputAdornment>
                    }
                  />

                  <OutlinedInput
                    fullWidth
                    type="text"
                    value={(selectedTo && selectedTo.name) || ""}
                    onClick={() => drawerSearchInit("to")}
                    startAdornment={
                      <InputAdornment position="start">
                        <Box sx={{ color: "text.disabled" }}>To</Box>
                      </InputAdornment>
                    }
                  />
                  <Select
                    id="entity-select"
                    label="Entity"
                    {...getFieldProps("entity")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={"cashflow"}>Cash Flow</MenuItem>
                    <MenuItem value={"farmerpay"}>Farmer Pay</MenuItem>
                    <MenuItem value={"dispensein"}>Dispense Cash</MenuItem>
                    <MenuItem value={"bill"}>Purchase Bill</MenuItem>
                    <MenuItem value={"salebill"}>Sale Bill</MenuItem>
                    <MenuItem value={"salary"}>Salary</MenuItem>
                    <MenuItem value={"payroll"}>Payroll</MenuItem>
                    <MenuItem value={"expense"}>Expense</MenuItem>
                    <MenuItem value={"writeoff"}>Write Off</MenuItem>
                  </Select>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Amount"
                    {...getFieldProps("amount")}
                    error={Boolean(touched.amount && errors.amount)}
                    helperText={touched.amount && errors.amount}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Comments"
                    {...getFieldProps("comments")}
                    error={Boolean(touched.comments && errors.comments)}
                    helperText={touched.comments && errors.comments}
                  />

                  <Select
                    id="method-select"
                    label="Method"
                    {...getFieldProps("method")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={"cash"}>CASH</MenuItem>
                    <MenuItem value={"online"}>ONLINE</MenuItem>
                    <MenuItem value={"razorpayx"}>RAZORPAYX</MenuItem>
                  </Select>

                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale={"en-in"}
                  >
                    <MobileDateTimePicker
                      value={moment(selectedDate)}
                      onAccept={(newValue) => {
                        if (newValue) {
                          setSelectedDate(newValue.valueOf());
                        }
                      }}
                      closeOnSelect={false}
                      format="DD-MM-YYYY hh:mm:ss A"
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
            </Grid>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>

      <Drawer
        anchor={"right"}
        open={searchDrawerState ? true : false}
        onClose={() => setSearchDrawerState(null)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        {(searchDrawerState === "from" || searchDrawerState === "to") && (
          <InternalAccSearch
            onAccSelected={drawerReturnSearch}
            initFr={getFieldProps("franchisee").value}
          />
        )}
      </Drawer>
    </div>
  );
}
