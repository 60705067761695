import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  OutlinedInput,
  InputAdornment,
  Box,
  Drawer,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPatch, dataPost } from "src/utils/data-fetch";
import ProductSearch from "src/pages/products/ProductSearch";
import { AddRounded, RemoveRounded } from "@mui/icons-material";
import { DateSelector } from "src/utils/date-selector";
import {
  getDayStartAndEndSync,
  TWENTY4HOURS,
} from "src/utils/date-time-helper";
import { useSnackbar } from "src/contexts/SnackbarContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ClaimSampleModal({ handleM1Close, customer }) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [slot, setSlot] = useState("1");
  const [message, setMessage] = useState("");

  const [day1, setDay1] = useState(Date.now());
  const [day2, setDay2] = useState(Date.now() + TWENTY4HOURS);
  const [day3, setDay3] = useState(Date.now() + 2 * TWENTY4HOURS);

  const handleDay1Change = (date) => {
    setDay1(date);
    setDay2(date + TWENTY4HOURS);
    setDay3(date + 2 * TWENTY4HOURS);
  };

  const handleDay2Change = (date) => {
    setDay2(date);
    setDay3(date + TWENTY4HOURS);
  };

  const handleDay3Change = (date) => {
    setDay3(date);
  };

  const approveNow = async (values) => {
    setError(null);

    if (!customer) {
      setError("Please select a customer");
      return;
    }

    const times = getDayStartAndEndSync(Date.now()).start

    if (day1 < times || day2 < times || day3 < times) {
      setError("Date not allowed in past");
      return;
    }

    setLoading(true);

    let up = {
      day1: day1,
      day2: day2,
      day3: day3,
      slot: slot,
    };

    if (message && message.length > 0) {
      up.message = message;
    }

    const ret = await dataPost(
      `/api/offers/mgract/user/${customer._id}/claimfreesample`,
      await getAccessToken(),
      up,
      activeFranchise
    );
    if (ret.success === true) {
      showSnackbar("Free Sample Claimed", "success");
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }

    setLoading(false);
  };

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Claim Free Sample
          </Typography>
        </Stack>

        <Stack spacing={3}>
          <OutlinedInput
            fullWidth
            type="text"
            value={(customer && customer.name) || ""}
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>Customer</Box>
              </InputAdornment>
            }
          />

          <Stack direction={"row"} spacing={3} alignItems={"center"}>
            <DateSelector
              value={day1}
              onChange={handleDay1Change}
              title={"Day 1"}
              displayFormat={"DD MMM YYYY"}
              minDate={Date.now()}
              maxDate={Date.now() + 10 * TWENTY4HOURS}
            />
            <DateSelector
              value={day2}
              onChange={handleDay2Change}
              title={"Day 2"}
              displayFormat={"DD MMM YYYY"}
              minDate={day1 + TWENTY4HOURS}
              maxDate={Date.now() + 10 * TWENTY4HOURS}
            />
            <DateSelector
              value={day3}
              onChange={handleDay3Change}
              title={"Day 3"}
              displayFormat={"DD MMM YYYY"}
              minDate={day2 + TWENTY4HOURS}
              maxDate={Date.now() + 10 * TWENTY4HOURS}
            />
          </Stack>

          <TextField
            select
            label="Slot"
            sx={{ minWidth: 150 }}
            value={slot}
            onChange={(e) => setSlot(e.target.value)}
          >
            <MenuItem value="1">5AM - 7AM</MenuItem>
            <MenuItem value="2">7AM - 9AM</MenuItem>
            <MenuItem value="3">9AM - 11AM</MenuItem>
            <MenuItem value="4">5PM - 7PM</MenuItem>
            <MenuItem value="5">7PM - 9PM</MenuItem>
          </TextField>

          <TextField
            fullWidth
            type="text"
            label="Instructions"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            onClick={approveNow}
            sx={{ mt: 3 }}
          >
            Save
          </LoadingButton>
        </Stack>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
