import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import Select from "@mui/material/Select";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Grid,
  Box,
  Switch,
  Chip,
  MenuItem,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPatch } from "src/utils/data-fetch";

import {
  getDateStringFullWithSlash,
  parseDateStringFull,
} from "src/utils/date-time-helper";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const employeeRoles = {
  sales: "Delivery",
  tl: "Team Lead",
  fieldexec: "Field Sales",
  collection: "Collection",
  mechanic: "Mechanic",
  helper: "Helper",
  cook: "Cook",
  lab: "Lab Assistant",
  manager: "Manager",
  transport: "Transport",
  hr: "HR",
  crm: "CRM",
  account: "Accounts",
};

export default function EditEmployeeModal({ handleM1Close, employee }) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise } = useAuth();

  const approveNow = async (values) => {
    let up = {
      name: values.name,
      address: values.address,
      phone: `+91${values.phone}`,
      alt_phone: `+91${values.alt_phone}`,
      base_salary: values.base_salary,
      allowance: values.allowance,
      var_salary: values.var_salary,
      join_date: parseDateStringFull(values.join_date),
      roles: values.roles,
      product_bonus: values.product_bonus,
      leaves_pm: values.leaves_pm,
      tshirt_size: values.tshirt_size,
      dob: parseDateStringFull(values.dob),
      blood_group: values.blood_group,
      payroll_type: values.payroll_type,
    };

    const ret = await dataPatch(
      `/api/employees/mgract/v2/employee/${employee?._id}`,
      await getAccessToken(),
      up,
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Address is required"),
    phone: Yup.number()
      .required("Enter 10 digit number")
      .max(9999999999)
      .min(1111111111),
    alt_phone: Yup.number()
      .required("Enter 10 digit number")
      .max(9999999999)
      .min(1111111111),
    dob: Yup.string().matches(
      /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/,
      "Enter in format DD/MM/YYYY"
    ),
    base_salary: Yup.number().required("Enter base salary").min(0).default(0),
    allowance: Yup.number().required("Enter allowance"),
    var_salary: Yup.number().required("Enter var salary"),
    join_date: Yup.string()
      .required("Please select join date")
      .matches(
        /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/,
        "Enter in format DD/MM/YYYY"
      ),
    roles: Yup.array(Yup.string()).required("Select roles"),
    product_bonus: Yup.boolean(),
    leaves_pm: Yup.number().required("Enter leaves per month"),
    blood_group: Yup.string(),
    tshirt_size: Yup.string().required("Enter Tshirt size"),
    payroll_type: Yup.string().required("Select payroll type"),
  });

  const formik = useFormik({
    initialValues: {
      join_date: getDateStringFullWithSlash(employee?.employee_det?.join_date),
      name: employee?.name,
      address: employee?.employee_det?.address,
      phone: employee?.phone?.substring(3),
      alt_phone: employee?.employee_det?.alt_phone?.substring(3),
      base_salary: employee?.employee_det?.base_salary,
      allowance: employee?.employee_det?.allowance,
      var_salary: employee?.employee_det?.var_salary,
      roles: employee?.employee_det?.roles,
      product_bonus: employee?.employee_det?.product_bonus,
      leaves_pm: employee?.employee_det?.leaves_pm,
      tshirt_size: employee?.employee_det?.tshirt_size,
      blood_group: employee?.employee_det?.blood_group,
      payroll_type: employee?.employee_det?.payroll_type,
      dob: getDateStringFullWithSlash(employee?.employee_det?.dob),
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Add Employee
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={3}>
                  <TextField
                    select
                    fullWidth
                    label="Pay Type"
                    {...getFieldProps("payroll_type")}
                    error={Boolean(touched.payroll_type && errors.payroll_type)}
                    helperText={touched.payroll_type && errors.payroll_type}
                  >
                    <MenuItem value="contractor">Contractor</MenuItem>
                    <MenuItem value="employee">Employee</MenuItem>
                    <MenuItem value="intern">Intern</MenuItem>
                  </TextField>

                  <TextField
                    fullWidth
                    type="text"
                    label="Name"
                    {...getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Address"
                    {...getFieldProps("address")}
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                  />

                  <TextField
                    fullWidth
                    type="phone"
                    contentEditable={"false"}
                    label="Phone"
                    {...getFieldProps("phone")}
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                  />

                  <TextField
                    fullWidth
                    type="phone"
                    contentEditable={"false"}
                    label="Alternate Phone"
                    {...getFieldProps("alt_phone")}
                    error={Boolean(touched.alt_phone && errors.alt_phone)}
                    helperText={touched.alt_phone && errors.alt_phone}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Date of Birth (DD/MM/YYYY)"
                    {...getFieldProps("dob")}
                    error={Boolean(touched.dob && errors.dob)}
                    helperText={touched.dob && errors.dob}
                  />

                  <TextField
                    fullWidth
                    type="number"
                    label="Leaves per month"
                    {...getFieldProps("leaves_pm")}
                    error={Boolean(touched.leaves_pm && errors.leaves_pm)}
                    helperText={touched.leaves_pm && errors.leaves_pm}
                  />

                  <Select
                    id="roles-select"
                    label="Roles"
                    multiple
                    {...getFieldProps("roles")}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={employeeRoles[value]} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {Object.keys(employeeRoles).map((key) => (
                      <MenuItem value={key}>{employeeRoles[key]}</MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Base Salary"
                    {...getFieldProps("base_salary")}
                    error={Boolean(touched.base_salary && errors.base_salary)}
                    helperText={touched.base_salary && errors.base_salary}
                  />

                  <TextField
                    fullWidth
                    type="number"
                    label="Allowance"
                    {...getFieldProps("allowance")}
                    error={Boolean(touched.allowance && errors.allowance)}
                    helperText={touched.allowance && errors.allowance}
                  />

                  <TextField
                    fullWidth
                    type="number"
                    label="Per Liter Incentive"
                    {...getFieldProps("var_salary")}
                    error={Boolean(touched.var_salary && errors.var_salary)}
                    helperText={touched.var_salary && errors.var_salary}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="T-shirt Size"
                    {...getFieldProps("tshirt_size")}
                    error={Boolean(touched.tshirt_size && errors.tshirt_size)}
                    helperText={touched.tshirt_size && errors.tshirt_size}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Blood Group"
                    {...getFieldProps("blood_group")}
                    error={Boolean(touched.blood_group && errors.blood_group)}
                    helperText={touched.blood_group && errors.blood_group}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Join Date"
                    {...getFieldProps("join_date")}
                    error={Boolean(touched.join_date && errors.join_date)}
                    helperText={touched.join_date && errors.join_date}
                  />

                  <Box pl={1}>
                    Product Bonus
                    <Switch
                      {...getFieldProps("product_bonus")}
                      label="Product Bonus"
                      checked={getFieldProps("product_bonus").value}
                    />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
