import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import Select from "@mui/material/Select";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Grid,
  Chip,
  Box,
  InputAdornment,
  MenuItem,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const managerRoles = {
  sm: "Delivery Mgr",
  smv: "Delivery Mgr View Only",
  cm: "Collection Manager",
  cmv: "Collection Mgr View Only",
  hm: "Head Manager",
  fo: "Franchisee Owner",
  fov: "Fr Owner View Only",
  crm: "CRM",
  crmv: "CRM View Only",
  acc: "Accounts",
  acce: "Accounts + Edit",
  acca: "Accounts + Approve",
  hr: "HR",
  hre: "HR + Edit",
  hra: "HR + Approve",
  disp: "Dispenser",
  mt: "Manager Txn",
  pms: "Planned Maintenance",
  cg: "Credentials Generate",
  dh: "Dispense Hisaab",
};

export default function AddAcessControlModal({ handleM1Close, manager }) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise, allFranchisees } = useAuth();

  const addMgrAccess = async (values) => {
    const ret = await dataPost(
      `/api/managers/coreact/manager/${manager._id}/access`,
      await getAccessToken(),
      {
        roles: values.roles,
        franchisee: values.franchisee,
      }
    );
    if (ret.success === true) {
      alert("Access Control added");
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    roles: Yup.array(Yup.string()).required("Select roles"),
    franchisee: Yup.string().required("Select franchisee"),
  });

  const formik = useFormik({
    initialValues: {
      roles: [],
      franchisee: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await addMgrAccess(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Card
      sx={{
        px: 4,
        py: 4,
        minWidth: 800,
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Typography variant="h5" gutterBottom>
          Add Access Control
        </Typography>
      </Stack>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Stack spacing={3}></Stack>
        </Grid>
      </Grid>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <Stack spacing={3}>
                <Select
                  id="fr-select"
                  {...getFieldProps("franchisee")}
                  MenuProps={MenuProps}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box sx={{ color: "text.disabled" }}>Franchise</Box>
                    </InputAdornment>
                  }
                >
                  {allFranchisees.map((item) => (
                    <MenuItem value={item._id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Stack spacing={3}>
                <Select
                  id="roles-select"
                  label="Roles"
                  multiple
                  {...getFieldProps("roles")}
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                      }}
                    >
                      {selected.map((value) => (
                        <Chip key={value} label={managerRoles[value]} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {Object.keys(managerRoles).map((key) => (
                    <MenuItem value={key}>{managerRoles[key]}</MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>
          </Grid>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ mt: 3 }}
          >
            Save
          </LoadingButton>
        </Form>
      </FormikProvider>

      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Card>
  );
}
