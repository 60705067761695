import {
  Container,
  Typography,
  Stack,
  Box,
  InputAdornment,
  OutlinedInput,
  CircularProgress,
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  TablePagination,
  IconButton,
  Tooltip,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  Button,
  Modal,
} from "@mui/material";

import { forwardRef, useEffect, useState } from "react";
import {
  AddCircleRounded,
  CloseRounded,
  RefreshRounded,
  SearchRounded,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { dataFetch } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";
import { useSnackbar } from "src/contexts/SnackbarContext";
import AddLink from "./AddLink";
import LinkDetailsPage from "./LinkDetailsPage";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TABLE_HEAD = [
  { id: "key", label: "Key", alignRight: false },
  { id: "target", label: "Target", alignRight: false },
  { id: "campaign", label: "Campaign", alignRight: false },
];

export default function ShortLinksPage({ handleClose, drawerOpen }) {
  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [loadingData, setLoadingData] = useState(false);
  const [allData, setAllData] = useState([]);

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);

  let timer = "";

  const handleChangeQuery = (event) => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      setAllData([]);
      setPage(0);
      setSearch(event.target.value);
    }, 1000);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchData = async () => {
    setLoadingData(true);

    let url = `/api/campaigns/mgract/shortlinks/query?sort=_id&dir=d&skip=${
      page * 5
    }&limit=5`;

    if (search && search.length > 0) {
      url += `&search=${search}`;
    }

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setAllData(ret.data);
    } else {
      showSnackbar(ret.message || "Failed to fetch data", "error");
    }

    setLoadingData(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, search]);

  const [addLinkModalState, setAddLinkModalState] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      scroll="body"
      open={drawerOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            SHORT LINKS
          </Typography>

          <Stack direction={"row"} spacing={1} edge="end">
            <Button
              variant="contained"
              color="white"
              startIcon={<AddCircleRounded />}
              onClick={() => setAddLinkModalState(true)}
            >
              Add Link
            </Button>

            <Button variant="contained" color="error" onClick={handleClose}>
              <CloseRounded />
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>

      <Container sx={{ minHeight: "500px" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          spacing={2}
        >
          <SearchStyle
            placeholder="Search Links..."
            onChange={handleChangeQuery}
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>
                  <SearchRounded />
                </Box>
              </InputAdornment>
            }
          />

          {(loadingData === true && <CircularProgress color="secondary" />) || (
            <IconButton onClick={fetchData}>
              <RefreshRounded />
            </IconButton>
          )}
        </Stack>

        <TableContainer sx={{ minHeight: 100 }}>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.alignRight ? "right" : "left"}
                    sortDirection={false}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {allData.map((item) => {
                return (
                  <Tooltip title={item?.description}>
                    <TableRow
                      hover
                      onClick={() => setSelectedLink(item)}
                      style={{
                        backgroundColor:
                          item.active === false ? "#ddd" : "white",
                      }}
                    >
                      <TableCell>
                        <b>{item?.key || "--"}</b>
                      </TableCell>
                      <TableCell>{item?.target || "--"}</TableCell>
                      <TableCell>{item?.campaign || "--"}</TableCell>
                    </TableRow>
                  </Tooltip>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={-1}
          rowsPerPage={5}
          rowsPerPageOptions={[5]}
          page={page}
          onPageChange={handleChangePage}
        />
      </Container>

      {addLinkModalState && (
        <Modal
          open={addLinkModalState}
          onClose={() => {
            setAddLinkModalState(false);
            fetchData();
          }}
        >
          <AddLink
            handleM1Close={() => {
              setAddLinkModalState(false);
              fetchData();
            }}
          />
        </Modal>
      )}

      {selectedLink && (
        <LinkDetailsPage
          link={selectedLink}
          handleClose={() => setSelectedLink(null)}
          drawerOpen={selectedLink !== null}
        />
      )}
    </Dialog>
  );
}
