import {
  AppBar,
  Button,
  Card,
  Container,
  Dialog,
  Grid,
  Slide,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";

import { useState, forwardRef, useEffect } from "react";
import { CloseRounded } from "@mui/icons-material";
import { useSnackbar } from "src/contexts/SnackbarContext";
import { getDateStringFullWithTimeSync } from "src/utils/date-time-helper";
import { dataFetch } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LinkDetailsPage({ link, handleClose, drawerOpen }) {
  const [currentData, setCurrentData] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const loadData = async () => {
    setLoadingData(true);

    const response = await dataFetch(
      `/api/campaigns/mgract/shortlink/${link._id}`,
      await getAccessToken(),
      activeFranchise
    );

    if (response.success === true) {
      setCurrentData(response.data);
    } else {
      showSnackbar(response.message || "Failed to fetch data", "error");
    }
    setLoadingData(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={drawerOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            Link Details
          </Typography>

          <Stack direction="row" spacing={2}>
            <Button variant="contained" color="error" onClick={handleClose}>
              <CloseRounded />
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>

      <Container sx={{ minHeight: 500 }}>
        {loadingData === false && currentData ? (
          <DetailsCard doc={currentData} reload={loadData} />
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Container>
    </Dialog>
  );
}

const DetailsCard = ({ doc, reload }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Card sx={{ px: 3, py: 2 }}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>Key:</b>
                </TableCell>
                <TableCell>{doc?.key}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Target:</b>
                </TableCell>
                <TableCell>{doc?.target}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Campaign:</b>
                </TableCell>
                <TableCell>{doc?.campaign}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <b>Description:</b>
                </TableCell>
                <TableCell>{doc?.description}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <b>Created By:</b>
                </TableCell>
                <TableCell>{doc?.created_by?.name}</TableCell>
              </TableRow>
            </TableBody>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <Card sx={{ px: 3, py: 2 }}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>Clicks:</b>
                </TableCell>
                <TableCell>{doc?.clicks?.length || 0}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Last Click:</b>
                </TableCell>
                <TableCell>
                  {getDateStringFullWithTimeSync(
                    doc?.clicks[(doc?.clicks?.length || 0) - 1]
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>First Click:</b>
                </TableCell>
                <TableCell>
                  {getDateStringFullWithTimeSync(doc?.clicks[0])}
                </TableCell>
              </TableRow>
            </TableBody>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
