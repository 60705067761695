import {
  MapOutlined,
  Home,
  DriveEtaRounded,
  WaterDrop,
  Person4,
  Payment,
  Person3,
  Person2,
  BookRounded,
  LocalShipping,
  LocationCity,
  ReportSharp,
  SummarizeRounded,
  AccountTree,
  TrendingUp,
  TaskRounded,
  FoodBankRounded,
  RestaurantMenuRounded,
  CallRounded,
  Campaign,
  NotificationsRounded,
  CreditScoreRounded,
} from "@mui/icons-material";
import MilkFlow from "src/pages/milkflow/MilkflowDashboard";

// ----------------------------------------------------------------------

// const getIcon = (name) => <Icon width={22} height={22} />;

export function getConfig(manager, currentAccess) {
  const config = [];

  if (currentAccess) {
    if (currentAccess.fr.features.includes("sale")) {
      if (
        [
          "fo",
          "fov",
          "sm",
          "smv",
          "crm",
          "crmv",
          "disp",
          "mt",
          "pms",
          "cg",
          "hm",
        ].some((v) => currentAccess.roles.includes(v))
      ) {
        let a = [];

        a.push({
          title: "Milk Carts",
          path: "/dashboard/milkcarts",
          icon: <DriveEtaRounded />,
        });

        a.push({
          title: "Reports",
          path: "/dashboard/reports",
          icon: <SummarizeRounded />,
        });

        a.push({
          title: "Vehicles",
          path: "/dashboard/vehicles",
          icon: <LocalShipping />,
        });

        config.push(a);
      }

      if (
        ["fo", "fov", "sm", "dh", "hm"].some((v) =>
          currentAccess.roles.includes(v)
        )
      ) {
        let a = [];

        a.push({
          title: "Cart Hisaab",
          path: "/dashboard/dispenses",
          icon: <WaterDrop />,
        });
        config.push(a);
      }

      if (
        ["fo", "fov", "sm", "smv", "crm", "crmv", "hm"].some((v) =>
          currentAccess.roles.includes(v)
        )
      ) {
        let a = [];

        a.push({
          title: "Customers",
          path: "/dashboard/customers",
          icon: <Home />,
        });

        a.push({
          title: "CRM",
          path: "/dashboard/crm",
          icon: <Person2 />,
        });

        a.push({
          title: "Calls",
          path: "/dashboard/calls",
          icon: <CallRounded />,
        });

        a.push({
          title: "Zones / Apts",
          path: "/dashboard/apartments",
          icon: <LocationCity />,
        });

        a.push({
          title: "Map",
          path: "/dashboard/map",
          icon: <MapOutlined />,
        });

        a.push({
          title: "Online Payments",
          path: "/dashboard/onlinepayments",
          icon: <Payment />,
        });

        a.push({
          title: "Products",
          path: "/dashboard/products",
          icon: <RestaurantMenuRounded />,
          isNew: false,
        });

        config.push(a);
      }

      if (
        ["fo", "fov", "hm", "mrk", "mrke"].some((v) =>
          currentAccess.roles.includes(v)
        )
      ) {
        let a = [];

        a.push({
          title: "Campaign",
          path: "/dashboard/campaign",
          icon: <Campaign />,
        });

        a.push({
          title: "Notifications",
          path: "/dashboard/notifications",
          icon: <NotificationsRounded />,
        });

        config.push(a);
      }
    }

    if (
      ["fo", "fov", "sm", "smv", "b2b", "hm"].some((v) =>
        currentAccess.roles.includes(v)
      )
    ) {
      let a = [];

      a.push({
        title: "Bulk Sale",
        path: "/dashboard/bulksale",
        icon: <FoodBankRounded />,
        isNew: false,
      });
      config.push(a);
    }

    if (currentAccess.fr.features.includes("collection")) {
      if (
        ["fo", "fov", "cm", "cmv", "sc", "hm"].some((v) =>
          currentAccess.roles.includes(v)
        )
      ) {
        let a = [];

        a.push({
          title: "Farmers",
          path: "/dashboard/farmers",
          icon: <Person4 />,
        });

        a.push({
          title: "Collection",
          path: "/dashboard/collection",
          icon: <DriveEtaRounded />,
        });
        config.push(a);
      }
    }

    if (
      ["fo", "fov", "sm", "smv", "sc", "cm", "hm"].some((v) =>
        currentAccess.roles.includes(v)
      )
    ) {
      let a = [];

      a.push({
        title: "Milkflow",
        path: "/dashboard/milkflow",
        icon: <AccountTree />,
      });
      config.push(a);
    }

    if (
      ["fo", "fov", "hre", "hr", "acce", "hm"].some((v) =>
        currentAccess.roles.includes(v)
      )
    ) {
      let a = [];

      a.push({
        title: "Employees",
        path: "/dashboard/employees",
        icon: <Person3 />,
      });
      config.push(a);
    }

    if (
      ["fo", "fov", "acc", "acce"].some((v) => currentAccess.roles.includes(v))
    ) {
      let a = [];

      a.push({
        title: "Expenses",
        path: "/dashboard/expenses",
        icon: <CreditScoreRounded />,
      });

      a.push({
        title: "P & L",
        path: "pl",
        icon: <TrendingUp />,
      });

      a.push({
        title: "Accounts",
        path: "/dashboard/accounts",
        icon: <BookRounded />,
      });
      config.push(a);
    }

    if (true) {
      let a = [];

      a.push({
        title: "Tasks",
        path: "/dashboard/tasks",
        icon: <TaskRounded />,
      });
      config.push(a);
    }

    if (
      ["fo", "fov", "hm", "mt"].some((v) => currentAccess.roles.includes(v))
    ) {
      let a = [];

      a.push({
        title: "Managers",
        path: "/dashboard/managers",
        icon: <Person2 />,
      });

      config.push(a);
    }
  }

  return config;
}
