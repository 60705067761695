import {
  Grid,
  Container,
  Typography,
  Stack,
  Button,
  Tab,
  Box,
  Modal,
} from "@mui/material";

import { useLocation } from "react-router-dom";

import { useState } from "react";

import Page from "src/components/Page";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InternalAccDetailsModal from "./InternalAccDetailsCard";
import InternalTxnsByAccount from "./InternalTxnsByAccount";
import AddTxnModal from "../txns/AddTxnModal";
import InternalTxnsByAccountPaginated from "./InternalTxnsByAccountPaginated";
import AccBalanceCard from "./AccBalanceCard";
import ExportLedgerSection from "./ExportLedgerSection";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function InternalAccDetailsPage({ inpAcc }) {
  const location = useLocation();
  let account = location?.state?.account;
  if (!account) account = inpAcc;

  const [currentMenu, setCurrentMenu] = useState("general");

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
  };

  const addTxnClicked = () => {
    setAddTxnModalOpen(true);
  };

  const [addTxnModalOpen, setAddTxnModalOpen] = useState(false);

  const closeAddTxnModal = () => {
    setAddTxnModalOpen(false);
  };

  return (
    <Page title="Account Details">
      <Container sx={{ minWidth:"70vw"}}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4">
            {(account && account.name) || "Account Details"}
          </Typography>

          <Button variant="contained" onClick={addTxnClicked}>
            Add Txn
          </Button>
        </Stack>

        <TabContext value={currentMenu}>
          <Box>
            <TabList onChange={handleTabChange}>
              <Tab label="Profile" value="general" {...a11yProps(0)} />
              <Tab label="Txns By Date" value="txns" {...a11yProps(1)} />
              <Tab label="Txns List" value="txns2" {...a11yProps(2)} />
            </TabList>
          </Box>
          <TabPanel value="general">
            <Grid container spacing={2}>
              <Grid item xs={12} md={8} lg={8}>
                <InternalAccDetailsModal accId={account._id} />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <AccBalanceCard accId={account._id} />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <ExportLedgerSection accId={account._id} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value="txns">
            <InternalTxnsByAccount account={account} />
          </TabPanel>

          <TabPanel value="txns2">
            <InternalTxnsByAccountPaginated account={account} />
          </TabPanel>
        </TabContext>
      </Container>

      <Modal open={addTxnModalOpen} onClose={closeAddTxnModal}>
        <AddTxnModal handleM1Close={closeAddTxnModal} />
      </Modal>
    </Page>
  );
}
