import {
  AppBar,
  Button,
  Container,
  Dialog,
  IconButton,
  Slide,
  Stack,
  Tab,
  Toolbar,
  Typography,
} from "@mui/material";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState, forwardRef } from "react";

import ApartmentProfile from "./ApartmentProfile";
import AptTowersSection from "./AptTowersSection";
import AptGuardsSection from "./AptGuardsSection";
import AptLocationSection from "./AptLocationSection";

import { CloseRounded } from "@mui/icons-material";
import { ApartmentCustomerSection } from "./ApartmentCustomersSection";
import ZoneMap from "./ZoneMap";
import CustomerDetailsPage from "src/pages/home/det/CustomerDetailsPage";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ApartmentDetailsPage({
  apartment,
  handleClose,
  drawerOpen,
}) {
  const [currentMenu, setCurrentMenu] = useState("profile");

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
  };

  const [custDetails, setCustDetails] = useState(null);
  const [custDetailsState, setCustDetailsState] = useState(false);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      scroll="body"
      open={drawerOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            {apartment?.name}
          </Typography>
          <Button
            edge="end"
            color="error"
            onClick={handleClose}
            variant="contained"
          >
            <CloseRounded />
          </Button>
        </Toolbar>
      </AppBar>

      <Container sx={{ minHeight: 600 }}>
        <TabContext value={currentMenu}>
          <TabList onChange={handleTabChange}>
            <Tab label="Profile" value="profile" {...a11yProps(0)} />
            <Tab label="Towers" value="towers" {...a11yProps(1)} />
            <Tab label="Guards" value="guards" {...a11yProps(2)} />
            <Tab label="Location" value="location" {...a11yProps(3)} />
            <Tab label="Customers" value="cust" {...a11yProps(4)} />
            {apartment?.category === "zone" && (
              <Tab label="Geometry" value="geometry" {...a11yProps(5)} />
            )}
          </TabList>

          <TabPanel value="profile">
            <ApartmentProfile apartment={apartment} />
          </TabPanel>
          <TabPanel value="towers">
            <AptTowersSection apt={apartment} />
          </TabPanel>
          <TabPanel value="guards">
            <AptGuardsSection apt={apartment} />
          </TabPanel>
          <TabPanel value="location">
            <AptLocationSection apt={apartment} />
          </TabPanel>
          <TabPanel value="cust">
            <ApartmentCustomerSection
              apt={apartment}
              onCustomerSelected={(item) => {
                setCustDetails(item);
                setCustDetailsState(true);
              }}
            />
          </TabPanel>
          <TabPanel value="geometry">
            <ZoneMap apartment={apartment} />
          </TabPanel>
        </TabContext>
      </Container>

      <CustomerDetailsPage
        customer={custDetails}
        drawerOpen={custDetailsState}
        handleClose={() => setCustDetailsState(false)}
      />
    </Dialog>
  );
}
