import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  IconButton,
  Switch,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";
import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from "@mui/icons-material";

import { MobileDatePicker } from "@mui/x-date-pickers";

import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  getDateOfMonth,
  getMonthStartAndEndSync,
} from "src/utils/date-time-helper";

export default function NewSalaryModal({ handleM1Close, employee }) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise } = useAuth();

  const dt = getDateOfMonth(Date.now());
  const mse = getMonthStartAndEndSync(
    dt > 15 ? Date.now() : Date.now() - 20 * 24 * 60 * 60 * 1000
  );
  const [currentDate, setCurrentDate] = useState(mse.end);

  const approveNow = async (values) => {
    const ret = await dataPost(
      `/api/employeetransactions/mgract/newincome`,
      await getAccessToken(),
      {
        amount: values.amount,
        comments: values.comments,
        employee: employee.employee,
        date: currentDate,
        tds: values.tds,
      },
      activeFranchise
    );
    if (ret.success === true) {
      handleM1Close();
    } else {
      console.log(ret.message);
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    amount: Yup.number().required("Amount is required!").min(1),
    comments: Yup.string().optional(),
    tds: Yup.boolean().optional(),
  });

  const formik = useFormik({
    initialValues: {
      amount: "",
      comments: "",
      tds: true
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  let tdsAmt = Math.round(formik.values.amount * 0.01);

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          height: "fit-content",
          minWidth: 400,
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mb: 2 }}
          spacing={2}
        >
          <Typography variant="h5" gutterBottom>
            New Salary / Income ({employee.name})
          </Typography>

          <Stack direction={"row"} alignItems={"center"}>
            <IconButton
              sx={{ display: "inline-flex" }}
              onClick={() => {
                const newD = moment(currentDate).subtract(1, "month").valueOf();
                setCurrentDate(newD);
              }}
            >
              <KeyboardArrowLeftRounded />
            </IconButton>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={"en-in"}
            >
              <MobileDatePicker
                views={["year", "month"]}
                value={moment(currentDate)}
                onAccept={(newValue) => {
                  if (newValue) {
                    const d1 = newValue.valueOf();
                    if (currentDate !== d1) setCurrentDate(d1);
                  }
                }}
                sx={{ maxWidth: "130px" }}
                closeOnSelect={true}
                format="MMM YYYY"
              />
            </LocalizationProvider>
            <IconButton
              sx={{ display: "inline-flex" }}
              onClick={() => {
                const newD = moment(currentDate).add(1, "month").valueOf();
                setCurrentDate(newD);
              }}
            >
              <KeyboardArrowRightRounded />
            </IconButton>
          </Stack>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                type="number"
                label="Amount"
                {...getFieldProps("amount")}
                error={Boolean(touched.amount && errors.amount)}
                helperText={touched.amount && errors.amount}
              />

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                px={1}
              >
                <b>Deduct TDS for contractor? (₹ {tdsAmt})</b>
                <Switch
                  id="switch-tds"
                  {...getFieldProps("tds")}
                  error={Boolean(touched.tds && errors.tds)}
                  helperText={touched.tds && errors.tds}
                />
              </Stack>

              <TextField
                fullWidth
                type="text"
                label="Comments"
                {...getFieldProps("comments")}
                error={Boolean(touched.comments && errors.comments)}
                helperText={touched.comments && errors.comments}
              />
            </Stack>

            <LoadingButton
              fullWidth
              disabled={formik.values.amount <= 0}
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Add{" ₹"}
              {formik.values.amount}
              {formik.values.tds === true && `(TDS ₹${tdsAmt})`}
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
