import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";

import { styled } from "@mui/material/styles";
import { dataFetch } from "../../utils/data-fetch";
import { useNavigate } from "react-router-dom";

import { RefreshRounded, SearchRounded } from "@mui/icons-material";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {
  Card,
  Container,
  Typography,
  Table,
  Box,
  OutlinedInput,
  InputAdornment,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Stack,
  IconButton,
  Tab,
} from "@mui/material";
import PartyProfileSection from "./PartyProfileSection";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "address", label: "Address", alignRight: false },
  { id: "balance", label: "Balance", alignRight: true },
];

export default function PartySearch({ onDataSelected }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [selectedField, setSelectedField] = useState("name");

  const { getAccessToken, activeFranchise } = useAuth();

  let timer = "";

  const handleChangeQuery = (event) => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      setCustomerData([]);
      setPage(0);
      setSearch(event.target.value);
    }, 1000);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFieldChange = (event) => {
    setSelectedField(event.target.value);
    setPage(0);
  };

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/extrasales/mgract/users/query?skip=${page * 5}&limit=${5}`;

    if (search && search.length > 0)
      url += `&search=${search}&field=${selectedField}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
      console.log(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [page, search, activeFranchise, selectedField]); // eslint-disable-line react-hooks/exhaustive-deps

  const totalLength = page * 5 + customerData.length;

  return (
    <Card title="Search Employees">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          mt={2}
          spacing={2}
        >
          <SearchStyle
            placeholder="Search Party..."
            onChange={handleChangeQuery}
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>
                  <SearchRounded />
                </Box>
              </InputAdornment>
            }
            endAdornment={
              loadingData === true && <CircularProgress color="secondary" />
            }
            sx={{ mt: 4, mb: 4 }}
          />
          <Select
            id="field-select"
            value={selectedField}
            onChange={handleFieldChange}
            label="Search In"
          >
            <MenuItem value={"name"}>Name</MenuItem>
            <MenuItem value={"phone"}>Phone</MenuItem>
            <MenuItem value={"bulksale.address"}>Address</MenuItem>
          </Select>

          <IconButton onClick={handleSearch}>
            <RefreshRounded />
          </IconButton>
        </Stack>
        {customerData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {customerData.map((item) => {
                    return (
                      <TableRow
                        hover
                        onClick={() => onDataSelected(item)}
                        style={{
                          backgroundColor:
                            item.status === "inactive" ? "#ddd" : "white",
                        }}
                      >
                        <TableCell>
                          <b>{item?.name || "-"}</b>
                        </TableCell>
                        <TableCell>
                          {item?.bulksale?.address.substring(0, 30) || "-"}
                        </TableCell>
                        <TableCell>
                          <b>{item?.balance ? item.balance : "-"}</b>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        <TablePagination
          component="div"
          count={totalLength < (page + 1) * 5 ? totalLength : -1}
          rowsPerPage={5}
          rowsPerPageOptions={[5]}
          page={page}
          onPageChange={handleChangePage}
        />
      </Container>
    </Card>
  );
}
