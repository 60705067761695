import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import { Card, Alert, Stack, TextField, Typography, Grid } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";


export default function AddVehicleModal({
  handleM1Close,
  duplicateData,
}) {
  const [error, setError] = useState();

  const { getAccessToken } = useAuth();

  const addVehicle = async (values) => {
    const ret = await dataPost(
      `/api/pms/vehicles/mgract/add`,
      await getAccessToken(),
      {
        name: values.name,
        category: values.category,
        number: values.number,
        model: values.model,
        base: values.base,
        battery: values.battery,
        voltage: values.voltage,
      }
    );
    if (ret.success === true) {
      alert("Vehicle added");
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    name: Yup.string().required("name is required"),
    category: Yup.number().required("Enter category!"),
    number: Yup.string().optional(),
    model: Yup.string().optional(),
    base: Yup.string().optional(),
    battery: Yup.string().optional(),
    voltage: Yup.number().optional(),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      category: null,
      number: "",
      model: "",
      base: "",
      battery: "",
      voltage: null,
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await addVehicle(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Add Dispenser
          </Typography>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Stack spacing={3}></Stack>
          </Grid>
        </Grid>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Name"
                    {...getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Number"
                    {...getFieldProps("number")}
                    error={Boolean(touched.number && errors.number)}
                    helperText={touched.number && errors.number}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Base"
                    {...getFieldProps("base")}
                    error={Boolean(touched.base && errors.base)}
                    helperText={touched.base && errors.base}
                  />

                  <TextField
                    fullWidth
                    type="number"
                    label="Voltage"
                    {...getFieldProps("voltage")}
                    error={Boolean(touched.voltage && errors.voltage)}
                    helperText={touched.voltage && errors.voltage}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Category"
                    {...getFieldProps("category")}
                    error={Boolean(touched.category && errors.category)}
                    helperText={touched.category && errors.category}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Model"
                    {...getFieldProps("model")}
                    error={Boolean(touched.model && errors.model)}
                    helperText={touched.model && errors.model}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Battery"
                    {...getFieldProps("battery")}
                    error={Boolean(touched.battery && errors.battery)}
                    helperText={touched.battery && errors.battery}
                  />
                </Stack>
              </Grid>
            </Grid>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
