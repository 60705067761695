import { CloseRounded } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  AppBar,
  Box,
  Container,
  Dialog,
  IconButton,
  Slide,
  Stack,
  Tab,
  Toolbar,
  Typography,
  Button,
  Modal,
} from "@mui/material";
import PartyProfileSection from "./PartyProfileSection";
import { forwardRef, useState } from "react";
import { BulkOrders } from "./BulkOrders";
import AddOrderModal from "./AddOrderModal";
import AddSaleModal from "./AddSaleModal";
import SaleTxns from "./SaleTxns";
import AddNewPaymentModal from "./AddNewPaymentModal";
import MatchPaymentModal from "./MatchPaymentModal";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const PartyDetailsModal = ({ party, handleClose, drawerOpen }) => {
  const [currentMenu, setCurrentMenu] = useState("profile");
  const [addModalState, setAddModalState] = useState(false);
  const [saleModalState, setSaleModalState] = useState(false);
  const [addPaymentModalState, setAddPaymentModalState] = useState(false);
  const [matchPaymentModalState, setMatchPaymentModalState] = useState(false);

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
  };

  const handleAddPaymentModalState = () => {
    setAddPaymentModalState(true);
  };

  const handleMmatchPaymentModalState = () => {
    setMatchPaymentModalState(true);
  };

  const handleAddSale = () => {
    setSaleModalState(true);
  };

  const handleAddorder = () => {
    setAddModalState(true);
  };

  const handleM1Close = () => {
    setAddModalState(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      scroll="body"
      open={drawerOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            {party?.name}
          </Typography>
          <IconButton edge="end" color="black" onClick={handleClose}>
            <CloseRounded />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Container sx={{minHeight: 500}}>
        <TabContext value={currentMenu}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleTabChange}
                aria-label="simple tabs example"
              >
                <Tab label="Profile" value="profile" {...a11yProps(0)} />
                <Tab label="Orders" value="orders" {...a11yProps(3)} />
                <Tab label="Txns" value="txns" {...a11yProps(4)} />
              </TabList>
            </Box>

            <Stack direction="row" spacing={2}>
              <Button
                onClick={handleMmatchPaymentModalState}
                variant="contained"
              >
                Match Payment
              </Button>

              <Button onClick={handleAddPaymentModalState} variant="contained">
                New Payment
              </Button>

              <Button onClick={handleAddSale} variant="contained">
                New Sale
              </Button>
              <Button onClick={handleAddorder} variant="contained">
                New Order
              </Button>
            </Stack>
          </Stack>

        
          <TabPanel value="profile">
            <PartyProfileSection party={party} />
          </TabPanel>
          <TabPanel value="orders">
            <BulkOrders party={party} />
          </TabPanel>
          <TabPanel value="txns">
            <SaleTxns party={party} />
            {/* <CustomerTxnsPaginated customer={party} /> */}
          </TabPanel>
        
        </TabContext>
      </Container>

      <Modal
        open={addPaymentModalState}
        onClose={() => setAddPaymentModalState(false)}
      >
        <AddNewPaymentModal
          party={party}
          handleM1Close={() => setAddPaymentModalState(false)}
        />
      </Modal>

      <Modal
        open={matchPaymentModalState}
        onClose={() => setMatchPaymentModalState(false)}
      >
        <MatchPaymentModal
          account={party?._id}
          handleM1Close={() => setMatchPaymentModalState(false)
          }
        />
      </Modal>

      <Modal open={addModalState} onClose={handleM1Close}>
        <AddOrderModal party={party} handleM1Close={handleM1Close} />
      </Modal>

      <Modal open={saleModalState} onClose={() => setSaleModalState(false)}>
        <AddSaleModal
          party={party}
          handleM1Close={() => setSaleModalState(false)}
        />
      </Modal>
    </Dialog>
  );
};
