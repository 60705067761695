import {
  Card,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Grid,
  Stack,
  TableHead,
  Table,
  IconButton,
} from "@mui/material";

import { Edit as EditIcon, FileCopy } from "@mui/icons-material";

import { useEffect, useState } from "react";
import {
  getDateStringFullSync,
  getDateStringSync,
} from "src/utils/date-time-helper";

import { dataFetch } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";

export default function NotificationProfile({ notification }) {
  const [currentData, setCurrentData] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const loadData = async () => {
    setLoadingData(true);
    const response = await dataFetch(
      `/api/sendnotification/mgract/msg/${notification._id}`,
      await getAccessToken(),
      activeFranchise
    );

    if (response.success === true) {
      setCurrentData(response.data);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    textField.innerText =
      currentData?.imageUrl || "https://milkvilla.in/images/milkvilla_text.svg";
    navigator.clipboard.writeText(textField.innerText);
    alert("Copied to clipboard");
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Stack direction={"column"} spacing={2}>
            <Card>
              <Stack direction="column" alignItems="center" spacing={2} pb={2}>
                <div>
                  <img
                    src={
                      (currentData && currentData?.imageUrl) ||
                      "https://milkvilla.in/images/milkvilla_text.svg"
                    }
                    alt="Notification"
                  />
                </div>

                <Typography variant="h6">{currentData?.title || ""}</Typography>
              </Stack>
              <IconButton
                sx={{ position: "absolute", bottom: 8, right: 8 }}
                onClick={copyToClipboard}
              >
                <FileCopy />
              </IconButton>
            </Card>
          </Stack>
        </Grid>

        <Grid item xs={12} md={8} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              {notification && (
                <NotificationDetailsCard
                  doc={currentData || notification}
                  reload={loadData}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const NotificationDetailsCard = ({ doc, reload }) => {
  return (
    <>
      <Card sx={{ px: 3, py: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5" gutterBottom>
            Details
          </Typography>

          <Button
            variant="outlined"
            color="error"
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
        </Stack>

        {doc && (
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={6}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Body:</b>
                  </TableCell>
                  <TableCell>{doc?.body}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Type:</b>
                  </TableCell>
                  <TableCell>{doc?.entity}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Link:</b>
                  </TableCell>
                  <TableCell>{doc?.link}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Link Text:</b>
                  </TableCell>
                  <TableCell>
                    {doc?.link_text || "No link text for notification"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <TableBody>
                <TableRow>
                  {doc?.entity === "inapp" && (
                    <>
                      <TableCell>
                        <b>In App Type:</b>
                      </TableCell>
                      <TableCell>
                        {doc?.inapp_type.toUpperCase() || "--"}
                      </TableCell>
                    </>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Date:</b>
                  </TableCell>
                  <TableCell>{getDateStringFullSync(doc?.date)}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Start Date:</b>
                  </TableCell>
                  <TableCell>{getDateStringSync(doc.start)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>End Date:</b>
                  </TableCell>
                  <TableCell>{getDateStringSync(doc.end)}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Send Log:</b>
                  </TableCell>
                  <TableCell>{doc?.send_log || "-"}</TableCell>
                </TableRow>
              </TableBody>
            </Grid>
          </Grid>
        )}
      </Card>
      <Card sx={{ px: 3, py: 2, my: 2 }}>
        <Stack direction="column">
          <Typography variant="h5" gutterBottom>
            Activity
          </Typography>

          <EventTable actions={doc?.actions || []} />
        </Stack>
      </Card>
    </>
  );
};

const EventTable = ({ actions }) => {
  const uniqueViews = [];

  const eventCounts = actions.reduce((acc, action) => {
    const eventType = action.event;
    if (!uniqueViews.includes(action.user)) uniqueViews.push(action.user);
    if (!acc[eventType]) {
      acc[eventType] = 1;
    } else {
      acc[eventType]++;
    }
    return acc;
  }, {});

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <b>Action</b>
          </TableCell>
          <TableCell align="right">
            <b>Count</b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(eventCounts).map(([eventType, count]) => (
          <TableRow key={eventType}>
            <TableCell>{eventType}</TableCell>
            <TableCell align="right">{count}</TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell>
            <b>Unique</b>
          </TableCell>
          <TableCell align="right">
            <b>{uniqueViews.length}</b>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
