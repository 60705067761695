import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";

import { styled } from "@mui/material/styles";
import { dataFetch } from "../../utils/data-fetch";
import { useNavigate } from "react-router-dom";

import { SearchRounded } from "@mui/icons-material";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {
  Card,
  Container,
  Typography,
  Table,
  Box,
  OutlinedInput,
  InputAdornment,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Stack,
} from "@mui/material";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "address", label: "Address", alignRight: false },
  { id: "uin", label: "UIN", alignRight: false },
  { id: "roles", label: "Roles", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
];

export default function EmployeeSearch({onEmpSelected}) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [status, setStatus] = useState("all");
  const [role, setRole] = useState("all");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [selectedField, setSelectedField] = useState("name");

  const { getAccessToken, activeFranchise } = useAuth();

  let timer = "";

  const handleChangeQuery = (event) => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      setCustomerData([]);
      setPage(0);
      setSearch(event.target.value);
    }, 1000);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setPage(0);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFieldChange = (event) => {
    setSelectedField(event.target.value);
    setPage(0);
  };

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/employees/mgract/v2/query?skip=${
      page * 5
    }&limit=${5}&sort=join_date&dir=d`;

    if (status && status !== "all") url += `&status=${status}`;
    if (role && role !== "all") url += `&role=${role}`;

    if (search && search.length > 0)
      url += `&search=${search}&field=${selectedField}`;
    else {
      setCustomerData([]);
      setLoadingData(false);
      return;
    }

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
      console.log(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [page, status, role, search, activeFranchise, selectedField]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card title="Search People">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          mt={2}
          spacing={2}
        >
          <Select
            id="status-select"
            value={status}
            onChange={handleStatusChange}
          >
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"documentation"}>Document</MenuItem>
            <MenuItem value={"active"}>Active</MenuItem>
            <MenuItem value={"inactive"}>Inactive</MenuItem>
            <MenuItem value={"pending"}>Pending</MenuItem>
            <MenuItem value={"training"}>Training</MenuItem>
            <MenuItem value={"onleave"}>On Leave</MenuItem>
          </Select>

          <Select id="role-select" value={role} onChange={handleRoleChange}>
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"sales"}>Delivery</MenuItem>
            <MenuItem value={"fieldexec"}>Sales</MenuItem>
            <MenuItem value={"tl"}>Team Lead</MenuItem>
            <MenuItem value={"collection"}>Collection</MenuItem>
            <MenuItem value={"helper"}>Helper</MenuItem>
          </Select>

          <SearchStyle
            placeholder="Search Employee / Contractor..."
            onChange={handleChangeQuery}
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>
                  <SearchRounded />
                </Box>
              </InputAdornment>
            }
            endAdornment={
              loadingData === true && <CircularProgress color="secondary" />
            }
            sx={{ mt: 4, mb: 4 }}
          />
          <Select
            id="field-select"
            value={selectedField}
            onChange={handleFieldChange}
            label="Search In"
          >
            <MenuItem value={"name"}>Name</MenuItem>
            <MenuItem value={"uin"}>UIN</MenuItem>
            <MenuItem value={"address"}>Address</MenuItem>
          </Select>
        </Stack>
        {customerData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {customerData.map((item) => {
                    return (
                      <TableRow
                        hover
                        onClick={() =>
                          onEmpSelected(item)
                        }
                        style={{
                          backgroundColor:
                            item.status === "inactive" ? "#ddd" : "white",
                        }}
                      >
                        <TableCell>
                          <b>{item ? item.name : ""}</b>
                        </TableCell>
                        <TableCell>
                          {item ? item.address.substring(0, 30) : ""}
                        </TableCell>
                        <TableCell>{item ? item.uin : ""}</TableCell>
                        <TableCell>
                          {item ? JSON.stringify(item.roles) : ""}
                        </TableCell>
                        <TableCell>{item ? item.status : ""}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              component="div"
              count={-1}
              rowsPerPage={5}
              page={page}
              onPageChange={handleChangePage}
            />
          </div>
        )}
      </Container>
    </Card>
  );
}
