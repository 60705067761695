import {
  Card,
  CircularProgress,
  Container,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";
import {
  getDateStringFullWithMonthSync,
  getDateStringFullWithTimeSync,
} from "src/utils/date-time-helper";
import BulkOrderDetails from "./BulkOrderDetails";
import { Refresh, RefreshRounded } from "@mui/icons-material";

const TABLE_HEAD = [
  { id: "datetime", label: "Date & Time", alignRight: false },
  { id: "item", label: "Item", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "orderstatus", label: "Order Status", alignRight: false },
  { id: "deliverytime", label: "Delivery Time", alignRight: false },
  { id: "deliveredby", label: "Delivered by", alignRight: false },
];

export const BulkOrders = ({ party, defaultList = 10 }) => {
  const [bulkOrder, setBulkOrder] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [page, setPage] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const { getAccessToken, activeFranchise } = useAuth();

  const handleOrderSelected = (order) => {
    setSelectedOrder(order);
    setOrderDetailsModal(true);
    console.log(order);
  };

  const [orderDetailsModal, setOrderDetailsModal] = useState(false);

  const handleOrderDetailsModalClose = () => {
    setOrderDetailsModal(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const loadData = async () => {
    setLoadingData(true);

    let url = `/api/extrasales/mgract/orders/query?sort=date&dir=d&user=${
      party._id
    }&skip=${page * defaultList}&limit=${defaultList}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setBulkOrder(ret.data);
      console.log(ret.data);
    }

    setLoadingData(false);
  };

  let totalLength = page * defaultList + bulkOrder.length;

  useEffect(() => {
    loadData();
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <Container>
        <Stack
          direction="row" /*  */
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            Orders Data
          </Typography>

          <Stack direction="row">
            {loadingData === false && (
              <IconButton onClick={loadData}>
                <RefreshRounded />
              </IconButton>
            )}

            {loadingData === true && <CircularProgress color="secondary" />}
          </Stack>
        </Stack>

        {bulkOrder.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {bulkOrder.map((item) => {
                    const color =
                      item.status === "white"
                        ? "white"
                        : [
                            "pending",
                            "processing",
                            "initiated",
                            "approved",
                          ].includes(item.status)
                        ? "white"
                        : "white";
                    return (
                      <Tooltip title={item?.comments}>
                        <TableRow
                          hover
                          style={{ backgroundColor: color }}
                          onClick={() => handleOrderSelected(item)}
                        >
                          <TableCell>
                            <b>{getDateStringFullWithMonthSync(item?.date)}</b>
                          </TableCell>
                          <TableCell>{item?.item.toUpperCase()}</TableCell>
                          <TableCell>₹ {item?.amount?.toFixed(1)}</TableCell>
                          <TableCell>{item?.status?.toUpperCase()}</TableCell>
                          <TableCell>
                            {(item.order_status === "delivered" &&
                              getDateStringFullWithTimeSync(
                                item.delivery_timestamp
                              )) ||
                              "-"}
                          </TableCell>
                          <TableCell>
                            {((item.status === "delivered" ||
                              item.status === "out for delivery") &&
                              item.delivery_boy &&
                              item.delivery_boy.name) ||
                              "-"}
                          </TableCell>
                        </TableRow>
                      </Tooltip>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}

        {bulkOrder.length === 0 && loadingData === false && (
          <div>
            <Typography gutterBottom align="center" variant="subtitle1">
              Not found
            </Typography>
            <Typography variant="body2" align="center" sx={{ mb: 4 }}>
              No results here.
            </Typography>
          </div>
        )}
      </Container>
      <TablePagination
        component="div"
        count={totalLength < (page + 1) * defaultList ? totalLength : -1}
        rowsPerPage={defaultList}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[defaultList]}
      />

      <Modal open={orderDetailsModal} onClose={handleOrderDetailsModalClose}>
        <BulkOrderDetails bulkOrder={selectedOrder} />
      </Modal>
    </Card>
  );
};
