import { useState, useEffect, forwardRef } from "react";
import { useAuth } from "src/contexts/AuthContext";

import {
  CloseRounded as CloseIcon,
  CloseRounded,
  EditRounded as EditIcon,
  ResetTvRounded,
  RestoreRounded,
  RotateLeftRounded,
  SaveRounded as SaveIcon,
  UndoRounded,
} from "@mui/icons-material";

import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

import { useConfirm } from "material-ui-confirm";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {
  Card,
  Alert,
  Stack,
  Typography,
  Grid,
  Box,
  CircularProgress,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Avatar,
  Button,
  Drawer,
  Container,
  TableContainer,
  Table,
  Modal,
  TextField,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  CardActionArea,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";

import {
  LoadingButton,
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";

import { MobileDateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { dataFetch, dataPatch } from "src/utils/data-fetch";
import {
  getDateStringFullSync,
  getDateStringFullWithTimeSync,
  getTimeStringFullSync,
} from "src/utils/date-time-helper";

import InternalAccSearch from "../ledgers/InternalAccSearch";
import UploadImageModal from "./UploadImage";
import { styled } from "@mui/styles";
import Label from "./Label";
import ApproveRejectTxn from "./ApproveRejectTxn";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProductImgStyle = styled("img")({
  width: "100%",
  height: "100%",
});

export default function InternalTxnDetailsModalAdmin({
  handleM1Close,
  poutId,
  drawerOpen,
}) {
  const [currentData, setCurrentData] = useState();
  const [loadingData, setLoadingData] = useState();

  const { getAccessToken } = useAuth();

  useEffect(() => {
    loadDetails();
  }, [poutId]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadDetails = async () => {
    if (!poutId) return;

    setLoadingData(true);

    const ret = await dataFetch(
      `/api/internalaccs/coreact/txn/${poutId}`,
      await getAccessToken()
    );
    if (ret.success === true) {
      setCurrentData(ret.data);
    } else {
      console.log(ret.message);
      // setError(ret.message + " - " + ret.code);
    }
    setLoadingData(false);
  };

  return (
    <Dialog
      fullWidth
      scroll="body"
      maxWidth="lg"
      open={drawerOpen}
      onClose={handleM1Close}
      TransitionComponent={Transition}
      PaperComponent="div"
    >
      <Grid container spacing={2} p={1}>
        <Grid item xs={12} md={8} lg={8}>
          {currentData && (
            <MainData
              currentData={currentData}
              loadingData={loadingData}
              setLoadingData={setLoadingData}
              poutId={poutId}
              reloadData={loadDetails}
              handleM1Close={handleM1Close}
            />
          )}
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <Stack direction={"column"} spacing={2}>
            {currentData?.timeline && currentData.timeline.length > 0 && (
              <TimelineData data={currentData?.timeline} />
            )}

            <ImageData
              data={currentData?.image}
              reloadData={loadDetails}
              poutId={poutId}
            />
          </Stack>
        </Grid>

        {currentData?.bill && (
          <Grid item xs={12} md={4} lg={4}>
            <BillData data={currentData.bill} />
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
}

const SetComments = ({ onAccSelected, exstComments }) => {
  const DataSchema = Yup.object().shape({
    comments: Yup.string().optional(),
  });

  const formik = useFormik({
    initialValues: {
      comments: exstComments ?? "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      onAccSelected(values.comments);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
          spacing={2}
        >
          <Typography variant="h5">Set Comments</Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack width={"400px"} mx={1}>
              <TextField
                fullWidth
                multiline
                type="text"
                label="Comments"
                {...getFieldProps("comments")}
                error={Boolean(touched.comments && errors.comments)}
                helperText={touched.comments && errors.comments}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </div>
  );
};

const TimelineData = ({ data }) => {
  return (
    <Card sx={{ px: 2, py: 2 }}>
      <Timeline position="alternate">
        {data?.map((item) => (
          <TimelineItem>
            <TimelineOppositeContent color="text.secondary">
              {getDateStringFullSync(item.ts)}
              <br />
              {getTimeStringFullSync(item.ts)}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="success" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              {item.status.toUpperCase()}
              <br />
              {(item.by && item.by.name) || "Auto"}
              <br />
              {item.by && item.by.entity}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Card>
  );
};

const ImageData = ({ data, reloadData, poutId }) => {
  const [uploadImageModal, setUploadImageModal] = useState(false);

  const closeuploadImageModal = () => {
    setUploadImageModal(false);
    reloadData();
  };

  return (
    <>
      {(data && (
        <Card>
          <ProductImgStyle alt={"Txn image"} src={data} />
        </Card>
      )) || (
        <Card>
          <CardActionArea
            onClick={() => setUploadImageModal(true)}
            sx={{ p: 2 }}
          >
            <Typography variant="h5" textAlign={"center"}>
              Upload Image
            </Typography>
          </CardActionArea>
        </Card>
      )}

      <Modal open={uploadImageModal} onClose={closeuploadImageModal}>
        <UploadImageModal
          handleM1Close={closeuploadImageModal}
          txnId={poutId}
        />
      </Modal>
    </>
  );
};

const BillData = ({ data }) => {
  return (
    <Card sx={{ px: 2, py: 2 }}>
      <Typography variant="h5" gutterBottom>
        Bill Details
      </Typography>
      <TableBody>
        {data.items &&
          data.items.map((item) => {
            return (
              <TableRow>
                <TableCell>
                  <b>{item?.name?.toUpperCase()}</b>
                </TableCell>
                <TableCell>
                  {item?.quantity} {item?.unit || "KG"} @ ₹ {item?.rate}
                </TableCell>
              </TableRow>
            );
          })}

        <TableRow>
          <TableCell>
            <b>Amount</b>
          </TableCell>
          <TableCell>{data?.net_amount?.toFixed(2)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Comments</b>
          </TableCell>
          <TableCell>{data?.comments || "-"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Category</b>
          </TableCell>
          <TableCell>{data?.category?.toUpperCase() || "-"}</TableCell>
        </TableRow>
      </TableBody>
    </Card>
  );
};

const MainData = ({
  currentData,
  loadingData,
  setLoadingData,
  poutId,
  reloadData,
  handleM1Close,
}) => {
  const [error, setError] = useState(null);
  const confirm = useConfirm();

  const { getAccessToken, allFranchisees } = useAuth();

  const [edited, setEdited] = useState({
    from: false,
    to: false,
    date: false,
    entity: false,
    method: false,
    comments: false,
    franchisee: false,
    utr: false,
  });

  const [fromData, setFromData] = useState();
  const [toData, setToData] = useState();
  const [entityData, setEntityData] = useState("");
  const [methodData, setMethodData] = useState("");
  const [commentsData, setCommentsData] = useState("");
  const [utrData, setUtrData] = useState("");
  const [dateData, setDateData] = useState(0);
  const [franchiseeData, setFranchiseeData] = useState("");

  const handleReset = () => {
    setFromData(currentData?.from);
    setToData(currentData?.to);
    setEntityData(currentData?.entity);
    setMethodData(currentData?.method);
    setCommentsData(currentData?.comments);
    setDateData(currentData?.date);
    setFranchiseeData(currentData?.franchisee?._id);
    setUtrData(currentData?.utr);

    setEdited({
      from: false,
      to: false,
      date: false,
      entity: false,
      method: false,
      comments: false,
      franchisee: false,
      utr: false,
    });
  };

  useEffect(() => {
    handleReset();
  }, [currentData]);

  const patchTxn = async () => {
    setLoadingData(true);

    let op = {};
    if (edited.from === true) op.from = fromData._id;
    if (edited.to === true) op.to = toData._id;
    if (edited.entity === true) op.entity = entityData;
    if (edited.method === true) op.method = methodData;
    if (edited.comments === true) op.comments = commentsData;
    if (edited.date === true) op.date = dateData;
    if (edited.franchisee === true) op.franchisee = franchiseeData;
    if (edited.utr === true) op.utr = utrData;

    const ret = await dataPatch(
      `/api/internalaccs/coreact/txn/${poutId}`,
      await getAccessToken(),
      op
    );

    if (ret.success === true) {
      reloadData();
      setEdited(false);
    } else {
      console.log(ret.message);
      setError(ret.message + " - " + ret.code);
      setLoadingData(false);
    }
  };

  const handleModify = () => {
    confirm({
      description: "Please confirm!",
      confirmationButtonProps: { autoFocus: true },
    })
      .then(() => {
        patchTxn();
      })
      .catch(() => {});
  };

  const [searchDrawerState, setSearchDrawerState] = useState(null);

  const drawerSearchInit = (type) => {
    setSearchDrawerState(type);
  };

  const drawerReturnSearch = (acc) => {
    console.log(acc);
    if (searchDrawerState === "from") {
      setFromData(acc);
    } else if (searchDrawerState === "to") {
      setToData(acc);
    }

    setEdited({ ...edited, [searchDrawerState]: true });
    setSearchDrawerState(null);
  };

  const [approveRejectModal, setApproveRejectModal] = useState(false);

  const entities = [
    "cashflow",
    "farmerpay",
    "dispensein",
    "writeoff",
    "bill",
    "salebill",
    "salary",
    "payroll",
    "expense",
  ];

  return (
    <Stack direction={"column"} spacing={2}>
      <Card>
        <AppBar sx={{ position: "relative" }} color="white">
          <Stack
            direction={"row"}
            spacing={2}
            m={2}
            justifyContent={"space-between"}
          >
            <Label variant="filled" color="primary">
              <b>₹ {currentData?.amount?.toFixed(2)}</b>
            </Label>

            {loadingData && <CircularProgress />}

            <Label
              variant="filled"
              color={
                currentData.status === "success"
                  ? "success"
                  : ["pending", "requested", "queued", "processing"].includes(
                      currentData.status
                    )
                  ? "warning"
                  : "error"
              }
            >
              <b>{currentData.status && currentData.status.toUpperCase()}</b>
            </Label>
          </Stack>
        </AppBar>

        <Stack direction={"column"} spacing={2} p={2}>
          <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
            <Stack direction={"row"} spacing={2}>
              <Select
                id="franchisee-select"
                value={franchiseeData}
                onChange={(e) => {
                  setFranchiseeData(e.target.value);
                  setEdited({ ...edited, franchisee: true });
                }}
                color="warning"
                startAdornment={
                  <InputAdornment variant="filled" sx={{ mr: 2 }}>
                    Center
                  </InputAdornment>
                }
              >
                {allFranchisees.map((item) => (
                  <MenuItem value={item._id}>{item.name}</MenuItem>
                ))}
              </Select>

              {franchiseeData !== currentData?.franchisee?._id && (
                <IconButton
                  onClick={() => {
                    setFranchiseeData(currentData?.franchisee?._id);
                    setEdited({ ...edited, franchisee: false });
                  }}
                >
                  <RotateLeftRounded />
                </IconButton>
              )}
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={"en-in"}
              >
                <MobileDateTimePicker
                  value={moment(dateData)}
                  onAccept={(newValue) => {
                    if (newValue) {
                      setDateData(newValue.valueOf());
                      setEdited({ ...edited, date: true });
                    }
                  }}
                  closeOnSelect={false}
                  format="DD-MM-YYYY hh:mm:ss A"
                  label="Date"
                />
              </LocalizationProvider>

              {dateData !== currentData.date && (
                <IconButton
                  onClick={() => {
                    setDateData(currentData.date);
                    setEdited({ ...edited, date: false });
                  }}
                >
                  <RotateLeftRounded />
                </IconButton>
              )}
            </Stack>
          </Stack>

          <Stack direction={"row"} spacing={2} my={1}>
            <OutlinedInput
              fullWidth
              value={
                (fromData && `${fromData.name} (${fromData.entity})`) || "-"
              }
              onClick={() => drawerSearchInit("from")}
              startAdornment={
                <InputAdornment variant="filled" sx={{ mr: 2 }}>
                  CR / From
                </InputAdornment>
              }
              color="success"
              sx={{ color: "green" }}
            />

            {fromData?._id !== currentData?.from?._id && (
              <IconButton
                onClick={() => {
                  setFromData(currentData.from);
                  setEdited({ ...edited, from: false });
                }}
              >
                <RotateLeftRounded />
              </IconButton>
            )}
          </Stack>

          <Stack direction={"row"} spacing={2} my={1}>
            <OutlinedInput
              fullWidth
              value={(toData && `${toData.name} (${toData.entity})`) || "-"}
              onClick={() => drawerSearchInit("to")}
              startAdornment={
                <InputAdornment variant="filled" sx={{ mr: 2 }}>
                  DR / To
                </InputAdornment>
              }
              color="error"
              sx={{ color: "red" }}
            />
            {toData?._id !== currentData?.to?._id && (
              <IconButton
                onClick={() => {
                  setToData(currentData.to);
                  setEdited({ ...edited, to: false });
                }}
              >
                <RotateLeftRounded />
              </IconButton>
            )}
          </Stack>

          <Stack direction={"row"} spacing={2} my={1}>
            <Select
              id="entity-select"
              value={entityData}
              onChange={(event) => {
                setEntityData(event.target.value);
                setEdited({ ...edited, entity: true });
              }}
              color="info"
              startAdornment={
                <InputAdornment variant="filled" sx={{ mr: 2 }}>
                  Category
                </InputAdornment>
              }
            >
              {entities.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            {entityData !== currentData.entity && (
              <IconButton
                onClick={() => {
                  setEntityData(currentData.entity);
                  setEdited({ ...edited, entity: false });
                }}
              >
                <RotateLeftRounded />
              </IconButton>
            )}

            <Select
              id="method-select"
              value={methodData}
              onChange={(event) => {
                setMethodData(event.target.value);
                setEdited({ ...edited, method: true });
              }}
              color="info"
              startAdornment={
                <InputAdornment variant="filled" sx={{ mr: 2 }}>
                  Method
                </InputAdornment>
              }
            >
              <MenuItem value={"cash"}>CASH</MenuItem>
              <MenuItem value={"online"}>ONLINE</MenuItem>
              <MenuItem value={"razorpayx"}>RazorpayX</MenuItem>
            </Select>

            {methodData !== currentData.method && (
              <IconButton
                onClick={() => {
                  setMethodData(currentData.method);
                  setEdited({ ...edited, method: false });
                }}
              >
                <RotateLeftRounded />
              </IconButton>
            )}
          </Stack>

          {currentData.fund_account && currentData.fund_account.number && (
            <Card sx={{ p: 2, boxShadow: 2 }}>
              <Typography variant="h6" gutterBottom>
                Fund Account Details
              </Typography>
              <Typography variant="h7" gutterBottom>
                {currentData.fund_account.name} |{" "}
                {currentData.fund_account.number} |{" "}
                {currentData.fund_account.ifsc}
              </Typography>
            </Card>
          )}

          <Stack direction={"row"} spacing={2} my={2}>
            <TextField
              label="UTR No (Optional)"
              variant="outlined"
              fullWidth
              value={utrData}
              onChange={(e) => {
                setCommentsData(e.target.value);
                setEdited({ ...edited, utr: true });
              }}
            />

            {utrData !== currentData.utr && (
              <IconButton
                onClick={() => {
                  setUtrData(currentData.utr);
                  setEdited({ ...edited, utr: false });
                }}
              >
                <RotateLeftRounded />
              </IconButton>
            )}
          </Stack>

          <Stack direction={"row"} spacing={2} my={2}>
            <TextField
              label="Comments"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={commentsData}
              onChange={(e) => {
                setCommentsData(e.target.value);
                setEdited({ ...edited, comments: true });
              }}
            />

            {commentsData !== currentData.comments && (
              <IconButton
                onClick={() => {
                  setCommentsData(currentData.comments);
                  setEdited({ ...edited, comments: false });
                }}
              >
                <RotateLeftRounded />
              </IconButton>
            )}
          </Stack>

          <Stack
            direction={"row"}
            spacing={2}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack direction={"row"} spacing={1}>
              <Typography color={"gray"}>Added on</Typography>
              <Typography color={"gray"}>
                {getDateStringFullWithTimeSync(
                  parseInt(currentData._id.substring(0, 8), 16) * 1000
                )}
              </Typography>
            </Stack>

            {Object.values(edited).includes(true) && (
              <Stack direction={"row"}>
                <IconButton onClick={handleReset}>
                  <Avatar
                    variant="circular"
                    sx={{
                      backgroundColor: "warning.main",
                      color: "black",
                    }}
                  >
                    <RestoreRounded />
                  </Avatar>
                </IconButton>
                <IconButton onClick={handleModify}>
                  <Avatar
                    variant="circular"
                    sx={{
                      backgroundColor: "primary.main",
                      color: "white",
                    }}
                  >
                    <SaveIcon />
                  </Avatar>
                </IconButton>
              </Stack>
            )}
          </Stack>

          {currentData.status === "requested" && (
            <Stack direction={"row"} justifyContent={"end"}>
              <Button
                color="error"
                variant="contained"
                onClick={() => setApproveRejectModal(true)}
              >
                Approve / Reject
              </Button>
            </Stack>
          )}
        </Stack>
      </Card>

      {error && (
        <Grid item xs={12} md={12} lg={12}>
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        </Grid>
      )}

      <Drawer
        anchor={"right"}
        open={searchDrawerState ? true : false}
        onClose={() => setSearchDrawerState(null)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        {(searchDrawerState === "from" || searchDrawerState === "to") && (
          <InternalAccSearch onAccSelected={drawerReturnSearch} />
        )}

        {searchDrawerState === "comments" && (
          <SetComments
            onAccSelected={drawerReturnSearch}
            exstComments={commentsData}
          />
        )}
      </Drawer>

      {approveRejectModal === true && (
        <Modal
          open={approveRejectModal}
          onClose={() => setApproveRejectModal(false)}
        >
          <ApproveRejectTxn
            handleM1Close={() => {
              setApproveRejectModal(false);
              reloadData();
            }}
            txnDet={currentData}
          />
        </Modal>
      )}
    </Stack>
  );
};
