import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import {
  Alert,
  Stack,
  TextField,
  Typography,
  AppBar,
  Toolbar,
  Slide,
  Dialog,
  Button,
  Switch,
  IconButton,
  MenuItem,
  CircularProgress,
  Divider,
  OutlinedInput,
  InputAdornment,
  Drawer,
  Box,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import {
  CloseRounded,
  DoneAllRounded,
  SearchRounded,
} from "@mui/icons-material";

import { forwardRef } from "react";
import { DateSelector } from "src/utils/date-selector";
import { useSnackbar } from "src/contexts/SnackbarContext";
import { dataFetch } from "./data-fetch-cro";
import {
  getDayStartAndEndSync,
  TWENTY4HOURS,
} from "src/utils/date-time-helper";
import CartSearch from "./cart-search";
import AddUserLocation from "./add-user-location";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddUserPage({ drawerOpen, handleClose }) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [phone, setPhone] = useState("");
  const [phoneSuccess, setPhoneSuccess] = useState(false);
  const [phoneLoading, setPhoneLoading] = useState(false);

  const globalSearch = async () => {
    setError(null);

    let searchPhone = phone;

    if (phone.length < 10) {
      showSnackbar("Enter a valid phone", "error");
      return;
    } else if (phone.length === 10) {
      if (!["6", "7", "8", "9"].includes(phone[0])) {
        showSnackbar("Invalid phone number", "error");
        return;
      } else {
        setPhone("+91" + phone);
        searchPhone = "+91" + phone;
      }
    } else if (phone.length === 13) {
      if (!phone.startsWith("+91")) {
        showSnackbar("Invalid phone number", "error");
        return;
      }
    }

    setPhoneLoading(true);

    try {
      const ret = await dataFetch(
        `/api/cro/mgract/globalsearch/${searchPhone}`,
        await getAccessToken()
      );

      if (ret.success === true) {
        showSnackbar(
          `User already exists (${ret?.data?.name || "-"})`,
          "error"
        );
      } else {
        setPhoneSuccess(true);
        showSnackbar("Phone number available", "success");
      }
    } catch (err) {
      showSnackbar("Unexpected error: " + err.message, "error");
    }

    setPhoneLoading(false);
  };

  const [name, setName] = useState("");
  const [freeSampleState, setFreeSampleState] = useState(false);

  const [slot, setSlot] = useState("4");
  const [message, setMessage] = useState("");
  const [day1, setDay1] = useState(Date.now());
  const [day2, setDay2] = useState(Date.now() + TWENTY4HOURS);
  const [day3, setDay3] = useState(Date.now() + 2 * TWENTY4HOURS);

  const handleDay1Change = (date) => {
    setDay1(date);
    setDay2(date + TWENTY4HOURS);
    setDay3(date + 2 * TWENTY4HOURS);
  };

  const handleDay2Change = (date) => {
    setDay2(date);
    setDay3(date + TWENTY4HOURS);
  };

  const handleDay3Change = (date) => {
    setDay3(date);
  };

  const [selectedCart, setSelectedCart] = useState(null);
  const [cartDrawerState, setCartDrawerState] = useState(false);

  const [locationOpen, setLocationOpen] = useState(null);

  const validateData = () => {
    if (name.length === 0) {
      showSnackbar("Name is required", "error");
      return;
    }
    if (phoneSuccess !== true) {
      showSnackbar("Phone number is not verified", "error");
      return;
    }

    const todayStart = getDayStartAndEndSync(Date.now()).start;
    if (day1 < todayStart || day2 < todayStart || day3 < todayStart) {
      showSnackbar("Date not allowed in past", "error");
      return;
    }

    let op = {
      phone: phone,
      name: name,
      cart_det: selectedCart,
      free_sample: freeSampleState,
    };

    if (freeSampleState === true) {
      let fs = {
        slot: slot,
        day1: day1,
        day2: day2,
        day3: day3,
      };

      if (message && message.length > 0) {
        fs.message = message;
      }

      op["free_sample_data"] = fs;
    }

    setLocationOpen(op);
  };

  return (
    <Dialog
      fullScreen
      maxWidth="sm"
      scroll="body"
      open={drawerOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 1 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            Add Customer
          </Typography>
          <Button variant="contained" color="error" onClick={handleClose}>
            <CloseRounded />
          </Button>
        </Toolbar>
      </AppBar>

      <Stack direction="column" spacing={1} p={1}>
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <TextField
            fullWidth
            type="text"
            label="Phone"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setPhoneSuccess(false);
            }}
          />

          {(phoneSuccess === true && <DoneAllRounded color="success" />) ||
            (phoneLoading === true && <CircularProgress color="primary" />) || (
              <IconButton color="primary" onClick={globalSearch}>
                <SearchRounded />
              </IconButton>
            )}
        </Stack>

        {phoneSuccess === true && (
          <Stack spacing={2}>
            <TextField
              fullWidth
              type="text"
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <OutlinedInput
              fullWidth
              type="text"
              value={(selectedCart && selectedCart.name) || ""}
              onClick={() => setCartDrawerState(true)}
              startAdornment={
                <InputAdornment position="start">
                  <Box sx={{ color: "text.disabled" }}>CART</Box>
                </InputAdornment>
              }
            />

            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Switch
                checked={freeSampleState}
                onChange={() => setFreeSampleState(!freeSampleState)}
              />

              <Typography>Free Sample</Typography>
            </Stack>

            <Divider style={{ marginBottom: "10px" }} />

            {freeSampleState === true && (
              <>
                <TextField
                  select
                  label="Slot"
                  sx={{ minWidth: 150 }}
                  value={slot}
                  onChange={(e) => setSlot(e.target.value)}
                >
                  <MenuItem value="3">9AM - 11AM</MenuItem>
                  <MenuItem value="4">5PM - 7PM</MenuItem>
                  <MenuItem value="5">7PM - 9PM</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  type="text"
                  label="Instructions"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <DateSelector
                  fullWidth={true}
                  value={day1}
                  onChange={handleDay1Change}
                  title={"Day 1"}
                  displayFormat={"DD MMM YYYY"}
                  minDate={Date.now()}
                  maxDate={Date.now() + 10 * TWENTY4HOURS}
                />
                <DateSelector
                  fullWidth={true}
                  value={day2}
                  onChange={handleDay2Change}
                  title={"Day 2"}
                  displayFormat={"DD MMM YYYY"}
                  minDate={day1 + TWENTY4HOURS}
                  maxDate={Date.now() + 10 * TWENTY4HOURS}
                />
                <DateSelector
                  fullWidth={true}
                  value={day3}
                  onChange={handleDay3Change}
                  title={"Day 3"}
                  displayFormat={"DD MMM YYYY"}
                  minDate={day2 + TWENTY4HOURS}
                  maxDate={Date.now() + 10 * TWENTY4HOURS}
                />
              </>
            )}

            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ mt: 5 }}
              onClick={() => {
                validateData();
              }}
            >
              Proceed
            </Button>
          </Stack>
        )}
      </Stack>

      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      <Drawer
        anchor={"right"}
        open={cartDrawerState}
        onClose={() => setCartDrawerState(false)}
        sx={{ zIndex: 1300, p: 1 }}
      >
        <CartSearch
          onCartSelected={(cart) => {
            setCartDrawerState(false);
            setSelectedCart(cart);
          }}
        />
      </Drawer>

      {locationOpen && (
        <AddUserLocation
          data={locationOpen}
          handleClose={() => setLocationOpen(null)}
          drawerOpen={locationOpen !== null}
          onUserAdded={() => {
            setLocationOpen(null);
            handleClose();
          }}
        />
      )}
    </Dialog>
  );
}
