import { useState } from "react";

import {
  Container,
  Typography,
  Stack,
  Modal,
  Grid,
  Button,
} from "@mui/material";

import Page from "src/components/Page";
import LedgerListCard from "./LedgerListCard";
import AddLedgerModal from "./AddLedgerModal";
import RecalculateLedgers from "./RecalculateLedgers";

export default function LedgersPageAdmin() {
  //add txn modal
  const addTxnClicked = () => {
    setAddLedgerModalOpen(true);
  };

  const [addLedgerModalOpen, setAddLedgerModalOpen] = useState(false);

  const closeAddLedgerModal = () => {
    setAddLedgerModalOpen(false);
  };

  return (
    <Page title="Ledgers">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          mb={4}
          justifyContent={"space-between"}
        >
          <Typography variant="h4">Ledgers</Typography>
          <Button variant="contained" onClick={addTxnClicked}>
            Add Ledger
          </Button>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <LedgerListCard />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <RecalculateLedgers />
          </Grid>
        </Grid>
      </Container>

      <Modal open={addLedgerModalOpen} onClose={closeAddLedgerModal}>
        <AddLedgerModal handleM1Close={closeAddLedgerModal} />
      </Modal>
    </Page>
  );
}
