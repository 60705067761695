import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { styled } from "@mui/material/styles";
import {
  Stack,
  Drawer,
  Typography,
  Button,
  Modal,
  IconButton,
  TextField,
} from "@mui/material";

import { MHidden } from "../../components/@material-extend";

import {
  AssignmentRounded,
  CalendarTodayRounded,
  CallRounded,
  CloseRounded,
  DialerSipRounded,
  DialpadRounded,
  KeyboardAltRounded,
  NumbersRounded,
  PushPinRounded,
} from "@mui/icons-material";

import AddTaskModal from "src/pages/tasks/AddTaskModal";
import MyTask from "src/pages/quick-access/MyTask";
import CallHistoryView from "src/pages/quick-access/CallHistoryView";
import DialPad from "./Dialpad";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  getDateStringFullSync,
  getDayStart,
  TWENTY4HOURS,
} from "src/utils/date-time-helper";
import moment from "moment";
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 40;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// ----------------------------------------------------------------------

DashboardSidebarRight.propTypes = {
  isOpenSidebarRight: PropTypes.bool,
  onCloseSidebarRight: PropTypes.func,
};

export default function DashboardSidebarRight({
  isOpenSidebarRight,
  onCloseSidebarRight,
  onPinTaskSidebar,
  onpinHistorySidebar,
  pinTask,
  pinHistory,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [taskOpen, setTaskOpen] = useState(false);
  const [openCallHistory, setOpenCallHistory] = useState(false);
  const [dialpadModalOpen, setDialpadModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(Date.now());
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const handleDialpadModal = () => {
    setDialpadModalOpen(true);
  };

  const closeAddTaskModal = () => {
    setModalOpen(false);
  };

  const addTaskClicked = () => {
    setModalOpen(true);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpenSidebarRight) {
      onCloseSidebarRight();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <RootStyle>
      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          anchor="right"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              mt: 12,
              mx: 2,
              px: 2,
            },
          }}
        >
          <div>
            <Stack spacing={4} sx={{ py: 2 }}>
              <AssignmentRounded
                hover="true"
                color="primary"
                style={{ fontSize: "25px" }}
                onClick={() => setTaskOpen(true)}
              />
            </Stack>
            <Stack spacing={4}>
              <CallRounded
                color="primary"
                style={{ fontSize: "25px" }}
                onClick={() => setOpenCallHistory(true)}
              />
            </Stack>
          </div>
        </Drawer>
      </MHidden>

      <Drawer
        variant={pinTask ? "persistent" : "temporary"}
        anchor="right"
        open={taskOpen || pinTask}
        onClose={() => setTaskOpen(false)}
        PaperProps={{
          sx: {
            width: 350, // Adjust this value as needed
          },
        }}
        sx={{
          zIndex: 1300, // Ensure it appears above other components if needed
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5" gutterBottom m={2}>
            My Tasks
          </Typography>

          <Stack
            direction="row"
            alignItems="right"
            justifyContent="right"
            mb={2}
          >
            <Button
              sx={{ mt: 2, mx: 1 }}
              variant="contained"
              onClick={addTaskClicked}
            >
              Add Task
            </Button>

            <IconButton sx={{ mt: 2 }} onClick={onPinTaskSidebar}>
              <PushPinRounded color={pinTask ? "secondary" : "primary"} />
            </IconButton>
          </Stack>
        </Stack>

        <MyTask />
      </Drawer>

      <Drawer
        variant={pinHistory ? "persistent" : "temporary"}
        anchor="right"
        open={openCallHistory || pinHistory}
        onClose={() => setOpenCallHistory(false)}
        PaperProps={{
          sx: {
            width: 350, // Adjust this value as needed
          },
        }}
        sx={{
          zIndex: 1300, // Ensure it appears above other components if needed
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5" gutterBottom m={2}>
            Call History
          </Typography>

          <Stack
            direction="row"
            alignItems="right"
            justifyContent="right"
            mb={2}
          >
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={"en-in"}
            >
              {/* <IconButton
                sx={{ mt: 2 }}
                onClick={() => setIsDatePickerOpen(true)}
              >
                <CalendarTodayRounded color="primary" />
              </IconButton> */}
              <MobileDatePicker
                open={isDatePickerOpen}
                onClose={() => setIsDatePickerOpen(false)}
                value={moment(selectedDate)}
                onChange={(newValue) => {
                  if (newValue) {
                    const d1 = newValue.valueOf();
                    if (selectedDate !== d1) setSelectedDate(d1);
                  }
                }}
                format="DD/MM/YYYY"
                sx={{ display: "none" }}
              />
            </LocalizationProvider>

            <IconButton sx={{ mt: 2 }} onClick={handleDialpadModal}>
              <DialpadRounded color="primary" />
            </IconButton>

            <IconButton sx={{ mt: 2 }} onClick={onpinHistorySidebar}>
              <PushPinRounded color={pinHistory ? "secondary" : "primary"} />
            </IconButton>

            {pinHistory && (
              <IconButton
                sx={{ mt: 2 }}
                onClick={() => {
                  onpinHistorySidebar();
                  setOpenCallHistory(false);
                }}
              >
                <CloseRounded color="red" />
              </IconButton>
            )}
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" mx={2}>
          <Stack direction="row" spacing={1}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => setSelectedDate(Date.now())}
              sx={{ px: 0.5, py: 0.25, fontSize: "0.75rem" }}
            >
              Today
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={() => setSelectedDate(Date.now() - TWENTY4HOURS)}
              sx={{ px: 0.5, py: 0.25, fontSize: "0.75rem" }}
            >
              Yesterday
            </Button>
          </Stack>

          <Button
            variant="outlined"
            onClick={() => setIsDatePickerOpen(true)}
            size="small"
            sx={{ px: 0.5, py: 0.25, fontSize: "0.75rem" }}
          >
            {getDateStringFullSync(selectedDate)}
          </Button>
        </Stack>
        <CallHistoryView date={selectedDate} />
      </Drawer>

      <Modal open={dialpadModalOpen} onClose={() => setDialpadModalOpen(false)}>
        <DialPad handleM1Close={() => setDialpadModalOpen(false)} />
      </Modal>

      <Modal open={modalOpen} onClose={closeAddTaskModal}>
        <AddTaskModal handleM1Close={closeAddTaskModal} />
      </Modal>
    </RootStyle>
  );
}
