import {
  Container,
  Typography,
  Stack,
  Card,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  OutlinedInput,
  Checkbox,
  Divider,
} from "@mui/material";
import { useAuth } from "src/contexts/AuthContext";

import { useEffect, useState } from "react";
import { dataFetch } from "src/utils/data-fetch";
import { styled } from "@mui/material/styles";
import CustomerDetailsPage from "../home/det/CustomerDetailsPage";

export default function LowBalanceSection() {
  const [customerData, setCustomerData] = useState([]);

  const { getAccessToken, activeFranchise } = useAuth();

  const [custDrawerOpen, setCustDrawerOpen] = useState(false);
  const [selectedCust, setSelectedCust] = useState(null);

  const handleCustomerOpen = (custData) => {
    setSelectedCust(custData);
    setCustDrawerOpen(true);
  };

  const loadData = async () => {
    let url = `/api/wallet/mgract/lowbal`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setCustomerData(ret.data);
      console.log(ret.data);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          mt={2}
          spacing={2}
        >
          <Typography variant="h4">Low Balance Customers</Typography>
        </Stack>

        <Table>
          <TableContainer sx={{ minHeight: 100 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">
                    <b>Balance</b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {customerData.length > 0 &&
                  customerData.map((item, index) => (
                    <TableRow
                      hover
                      key={index}
                      onClick={() => handleCustomerOpen(item.user)}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item?.user?.name || "--"}</TableCell>
                      <TableCell align="right">
                        <b>₹ {item?.balance || "--"}</b>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Table>
      </Container>

      <CustomerDetailsPage
        customer={selectedCust}
        handleClose={() => setCustDrawerOpen(false)}
        drawerOpen={custDrawerOpen}
      />
    </Card>
  );
}
