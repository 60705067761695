import {
  Card,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Grid,
  Stack,
  CircularProgress,
  Modal,
  Switch,
} from "@mui/material";

import { Edit as EditIcon } from "@mui/icons-material";

import { useEffect, useState } from "react";
import { dataFetch } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";
import EditProductModal from "./EditProductModal";
import UploadProductImage from "./UploadProductImage";

export default function ProductProfileSection({ product }) {
  const [currentData, setCurrentData] = useState();
  const [loadingData, setLoadingData] = useState(false);

  const [status, setStatus] = useState(product?.stock);
  const { getAccessToken, activeFranchise } = useAuth();
  console.log("product", product.stock);

  const updateAvailability = async (status) => {
    setLoadingData(true);

    let url = `/api/products/mgract/product/${product._id}/availability/${status}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setStatus(status);
      console.log(ret.data);
    } else {
      alert(ret.message);
    }
    setLoadingData(false);
  };

  const loadData = async () => {
    setLoadingData(true);
    const response = await dataFetch(
      `/api/products/mgract/product/${product._id}`,
      await getAccessToken(),
      activeFranchise
    );

    if (response.success === true) {
      setCurrentData(response.data);
      console.log("currentData", response.data);
    }
    setLoadingData(false);
  };

  const [uploadImageModal, setUploadImageModal] = useState(false);
  const closeUploadImageModal = () => {
    setUploadImageModal(false);
    loadData();
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Stack direction={"column"} spacing={2}>
            <Card>
              <Stack direction="column" alignItems="center" spacing={2} pb={2}>
                <div>
                  <img
                    src={
                      (currentData &&
                        currentData?.images?.length > 0 &&
                        currentData?.images[0]) ||
                      "https://milkvilla.in/images/mv_new_logo.jpg"
                    }
                    alt="Product"
                  />
                </div>

                <Typography variant="h6">
                  {currentData ? currentData.name : ""}
                </Typography>

                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setUploadImageModal(true)}
                >
                  Update Image
                </Button>
              </Stack>
            </Card>

            {product?.stock_type == 1 && (
              <Grid item xs={12} md={12} lg={12}>
                <Card sx={{ p: 2 }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h6">Availability</Typography>
                    {loadingData === true && <CircularProgress />}

                    <Switch
                      label="availability"
                      onChange={() => {
                        const newStatus = status === "1" ? "0" : "1";

                        updateAvailability(newStatus);
                      }}
                    />
                  </Stack>
                </Card>
              </Grid>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12} md={8} lg={8}>
          {product && (
            <ProductDetailsCard
              doc={currentData ? currentData : product}
              reload={loadData}
            />
          )}
        </Grid>
      </Grid>

      <Modal open={uploadImageModal} onClose={closeUploadImageModal}>
        <UploadProductImage
          handleM1Close={closeUploadImageModal}
          productId={product._id}
        />
      </Modal>
    </>
  );
}

const ProductDetailsCard = ({ doc, reload }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModalFn = () => {
    setModalOpen(false);
    reload();
  };

  const openModalFn = async () => {
    setModalOpen(true);
  };

  return (
    <>
      <Card sx={{ px: 3, py: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5" gutterBottom>
            Details
          </Typography>

          <Button
            variant="outlined"
            color="error"
            startIcon={<EditIcon />}
            onClick={openModalFn}
          >
            Edit
          </Button>
        </Stack>

        {doc && (
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={6}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Bio:</b>
                  </TableCell>
                  <TableCell>{doc?.bio ? doc.bio : "--"}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Active:</b>
                  </TableCell>
                  <TableCell>{doc?.active ? "Yes" : "No"}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Order Type:</b>
                  </TableCell>
                  <TableCell>
                    {doc.order_type === "0"
                      ? "Normal"
                      : doc.order_type === "1"
                      ? "Subscription"
                      : "Both"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Category</b>
                  </TableCell>
                  <TableCell>
                    {doc?.category?.name ? doc?.category?.name : "--"}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Quantum</b>
                  </TableCell>
                  <TableCell>{doc?.quantum ? doc?.quantum : "--"}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Stock Type</b>
                  </TableCell>
                  <TableCell>
                    {doc?.stock_type == 0 ? "Countable" : "Uncountable"}
                  </TableCell>
                </TableRow>
              </TableBody>

              <TableRow>
                <TableCell>
                  <b>GST Applicable</b>
                </TableCell>
                <TableCell>{doc?.gst_applicable ? "Yes" : "No"}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <b>GST Number</b>
                </TableCell>
                <TableCell>{doc?.gst_rate ? doc?.gst_rate : "--"}</TableCell>
              </TableRow>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Price:</b>
                  </TableCell>
                  <TableCell>
                    {doc?.price
                      ? `₹ ${doc?.price} ${
                          doc?.price_unit === "0"
                            ? "/Kg"
                            : doc?.price_unit === "1"
                            ? "/Ltr"
                            : "/Pc"
                        }`
                      : "Not Assigned"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Discounted Price:</b>
                  </TableCell>
                  <TableCell>
                    {doc?.disc_price
                      ? `₹ ${doc?.disc_price} ${
                          doc?.price_unit === "0"
                            ? "/Kg"
                            : doc?.price_unit === "1"
                            ? "/Ltr"
                            : "/Pc"
                        }`
                      : "Not Assigned"}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Subscription Price</b>
                  </TableCell>
                  <TableCell>
                    {" "}
                    {doc?.sub_price
                      ? `₹ ${doc?.sub_price} ${
                          doc?.price_unit === "0"
                            ? "/Kg"
                            : doc?.price_unit === "1"
                            ? "/Ltr"
                            : "/Pc"
                        }`
                      : "Not Assigned"}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>App Price</b>
                  </TableCell>
                  <TableCell>
                    {" "}
                    {doc?.app_price
                      ? `₹ ${doc?.app_price} ${
                          doc?.price_unit === "0"
                            ? "/Kg"
                            : doc?.price_unit === "1"
                            ? "/Ltr"
                            : "/Pc"
                        }`
                      : "Not Assigned"}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Weight</b>
                  </TableCell>
                  <TableCell>
                    {doc?.weight != null
                      ? `${doc?.weight} ${
                          doc?.price_unit === "0"
                            ? "Kg"
                            : doc?.price_unit === "1"
                            ? "Ltr"
                            : "Pc"
                        }`
                      : "Not Assigned"}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>HSN Code:</b>
                  </TableCell>
                  <TableCell>{doc?.hsn_code ? doc?.hsn_code : "-- "}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Offer Text:</b>
                  </TableCell>
                  <TableCell>
                    {doc?.offer_text ? doc?.offer_text : "-- "}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Grid>
          </Grid>
        )}
      </Card>

      <Modal open={modalOpen} onClose={closeModalFn}>
        <EditProductModal handleM1Close={closeModalFn} product={doc} />
      </Modal>
    </>
  );
};
