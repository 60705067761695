import {
  AppBar,
  Container,
  Dialog,
  IconButton,
  Slide,
  Stack,
  Tab,
  Toolbar,
  Typography,
} from "@mui/material";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState, forwardRef } from "react";

import { CloseRounded } from "@mui/icons-material";

import ProductProfileSection from "./ProductProfileSection";
import ProductImagesSection from "./ProductImagesSection";
import ProductInventorySection from "./ProductInventorySection";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProductDetailsPage({
  product,
  handleClose,
  drawerOpen,
}) {
  const [currentMenu, setCurrentMenu] = useState("profile");

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      scroll="body"
      open={drawerOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            {product?.name}
          </Typography>
          <IconButton edge="end" color="black" onClick={handleClose}>
            <CloseRounded />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Container sx={{ minHeight: "800px" }}>
        <TabContext value={currentMenu}>
          <TabList onChange={handleTabChange}>
            <Tab label="Profile" value="profile" {...a11yProps(0)} />
            <Tab label="Images" value="images" {...a11yProps(1)} />
            {product?.stock_type === 0 && (
              <Tab label="Inventory" value="inventory" {...a11yProps(2)} />
            )}
          </TabList>

          <TabPanel value="profile">
            <ProductProfileSection product={product} />
          </TabPanel>
          <TabPanel value="images">
            <ProductImagesSection product={product} />
          </TabPanel>
          <TabPanel value="inventory">
            <ProductInventorySection product={product} />
          </TabPanel>
        </TabContext>
      </Container>
    </Dialog>
  );
}
