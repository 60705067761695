import {
  Grid,
  Container,
  Typography,
  Stack,
  Button,
  Modal,
  Box,
} from "@mui/material";

import Page from "src/components/Page";

import { useNavigate } from "react-router-dom";

import InternalAccSearch from "./ledgers/InternalAccSearch";
import InternalTxnPaginated from "./txns/InternalTxnPaginated";
import ExportTxnsByDateAndCenter from "./ExportTxnsByDateAndCenter";
import TopCard from "./TopCard";
import { useState } from "react";
import AddTxnModal from "./txns/AddTxnModal";
import AddBillModal from "./bill/AddBillModal";
import PendingTxnApprovals from "./PendingTxnApprovals";

export default function InternalAccPageAdmin() {
  const navigate = useNavigate();

  const [addTxnModalOpen, setAddTxnModalOpen] = useState(false);

  const closeAddTxnModal = () => {
    setAddTxnModalOpen(false);
  };

  const [addBillModalOpen, setAddBillModalOpen] = useState(false);

  const closeAddBillModal = () => {
    setAddBillModalOpen(false);
  };

  return (
    <Page title="Accounts Dashboard">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4">Accounts Dashboard</Typography>
          <Stack direction="row">
            <Button
              variant="contained"
              onClick={() => setAddBillModalOpen(true)}
              sx={{ mr: 2 }}
            >
              Add Bill
            </Button>
            <Button
              variant="contained"
              onClick={() => setAddTxnModalOpen(true)}
            >
              Add Txn
            </Button>
          </Stack>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={6} md={3} lg={3}>
            <TopCard data="Txns Cat" onClick={() => navigate("txnscat")} />
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <TopCard data="Ledger List" onClick={() => navigate("ledgers")} />
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <TopCard
              data="Export"
              // onClick={expenseCatClicked}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <InternalAccSearch />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <InternalTxnPaginated />
          </Grid>
          
          <Grid item xs={12} md={12} lg={12}>
            <PendingTxnApprovals />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <ExportTxnsByDateAndCenter />
          </Grid>
        </Grid>
      </Container>

      <Modal open={addTxnModalOpen} onClose={closeAddTxnModal}>
        <AddTxnModal handleM1Close={closeAddTxnModal} />
      </Modal>
      <Modal open={addBillModalOpen} onClose={closeAddBillModal}>
        <AddBillModal handleM1Close={closeAddBillModal} />
      </Modal>
    </Page>
  );
}
