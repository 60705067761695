import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import { Edit as EditIcon } from "@mui/icons-material";

import {
  Card,
  Typography,
  Grid,
  Stack,
  CircularProgress,
  Button,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

export const MilkCartsInfoCard = ({ cart }) => {
  const [currentData, setCurrentData] = useState();
  const [loadingData2, setLoadingData2] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const loadData = async () => {
    let url = `/api/milkcarts/mgract/milkcart/${cart._id}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    console.log(ret.data);
    if (ret.success === true) {
      setCurrentData(ret.data);
    }

    setLoadingData2(false);
  };

  useEffect(() => {
    setLoadingData2(true);
    loadData();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <MilkCartsDetailsCardSecondary
            loading={loadingData2}
            doc={currentData}
          />
        </Grid>
      </Grid>
    </>
  );
};

const MilkCartsDetailsCardSecondary = ({ loading, doc }) => {
  return (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 3, mx: 3 }}
      >
        <Typography variant="h5" gutterBottom>
          Details
        </Typography>

        {loading === true && <CircularProgress />}

        <Button variant="outlined" color="error" startIcon={<EditIcon />}>
          Edit
        </Button>
      </Stack>

      {doc && (
        <Grid container columnSpacing={1} sx={{ pl: 2, pr: 2 }}>
          <Grid item xs={12} md={6} lg={6}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>Name:</b>
                </TableCell>
                <TableCell>{doc.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Phone:</b>
                </TableCell>
                <TableCell>{doc.phone}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Driver:</b>
                </TableCell>
                <TableCell>{doc?.default_employee?.name || "-"}</TableCell>
              </TableRow>
            </TableBody>
          </Grid>

        </Grid>
      )}
    </Card>
  );
};
