import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import Select from "@mui/material/Select";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Grid,
  Box,
  InputAdornment,
  MenuItem,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const mapData = {
  ledger: [
    "Sundry Debtors",
    "Sundry Creditors",
    "Loans & Advances (Asset)",
    "Duties & Taxes",
    "Provisions",
    "Suspense A/c",
    "Loans (Liability)",
  ],
  asset: ["Fixed Assets"],
  expense: ["Direct Expenses", "Indirect Expenses"],
  income: ["Indirect Incomes"],
  capital: ["Capital Account"],
};

export default function AddLedgerModal({ handleM1Close, duplicateData }) {
  const [error, setError] = useState();

  const { getAccessToken, allFranchisees } = useAuth();

  const approveNow = async (values) => {
    const ret = await dataPost(
      `/api/internalaccs/coreact/accs/addledger`,
      await getAccessToken(),
      {
        franchisee: values.franchisee,
        entity: values.entity,
        name: values.name,
        phone:
          values.phone && values.phone.length > 0
            ? `+91${values.phone}`
            : undefined,
        address: values.address,
        acc_group: values.acc_group,
      }
    );
    if (ret.success === true) {
      alert("Ledger added");
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    franchisee: Yup.string().required("Franchisee is required!"),
    name: Yup.string().required("Name is required!"),
    phone: Yup.number().max(9999999999).min(1111111111).optional(),
    address: Yup.string().optional(),
    entity: Yup.string().required("Entity is required!"),
    acc_group: Yup.string().required("Acc grp is required!"),
  });

  const formik = useFormik({
    initialValues: {
      franchisee:
        (duplicateData &&
          duplicateData.franchisee &&
          duplicateData.franchisee._id) ||
        undefined,
      corporate: (duplicateData && duplicateData.corporate) || true,
      name: "",
      phone: "",
      address: "",
      acc_group: "",
      entity:
        (duplicateData &&
          ["ledger", "asset", "expense", "income", "capital"].includes(
            duplicateData.entity
          ) &&
          duplicateData.entity) ||
        "ledger",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      if (mapData[values.entity].indexOf(values.acc_group) === -1) {
        setError("Invalid Acc Group");
        return;
      } else {
        setError(null);
      }
      await approveNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Card
      sx={{
        px: 4,
        py: 4,
        minWidth: 800,
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Typography variant="h5" gutterBottom>
          Add Ledger
        </Typography>
      </Stack>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Stack spacing={3}></Stack>
        </Grid>
      </Grid>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <Stack spacing={3}>
                <Select
                  id="fr-select"
                  {...getFieldProps("franchisee")}
                  MenuProps={MenuProps}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box sx={{ color: "text.disabled" }}>Franchise</Box>
                    </InputAdornment>
                  }
                >
                  {allFranchisees.map((item) => (
                    <MenuItem value={item._id}>{item.name}</MenuItem>
                  ))}
                </Select>

                <TextField
                  fullWidth
                  type="text"
                  label="Name"
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />

                <TextField
                  fullWidth
                  type="phone"
                  contentEditable={"false"}
                  label="Phone"
                  {...getFieldProps("phone")}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  type="text"
                  label="Address"
                  {...getFieldProps("address")}
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                />

                <Select
                  id="entity-select"
                  {...getFieldProps("entity")}
                  MenuProps={MenuProps}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box sx={{ color: "text.disabled" }}>Entity</Box>
                    </InputAdornment>
                  }
                >
                  <MenuItem value={"ledger"}>Ledger</MenuItem>
                  <MenuItem value={"asset"}>Asset</MenuItem>
                  <MenuItem value={"expense"}>Expense</MenuItem>
                  <MenuItem value={"income"}>Income</MenuItem>
                  <MenuItem value={"capital"}>Capital</MenuItem>
                </Select>
                <Select
                  id="acc-group-select"
                  {...getFieldProps("acc_group")}
                  MenuProps={MenuProps}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box sx={{ color: "text.disabled" }}>Acc Group</Box>
                    </InputAdornment>
                  }
                >
                  {mapData[formik.values.entity].map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>
          </Grid>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ mt: 3 }}
          >
            Save
          </LoadingButton>
        </Form>
      </FormikProvider>

      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Card>
  );
}
