import {
  AppBar,
  Button,
  Container,
  Dialog,
  IconButton,
  Slide,
  Stack,
  Tab,
  Toolbar,
  Typography,
} from "@mui/material";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState, forwardRef } from "react";
import { CloseRounded } from "@mui/icons-material";
import NotificationProfile from "./NotificationProfile";
import AddInAppMsgModal from "../AddInAppMsgModal";
import AddNotificationMsgModal from "../AddNewNotifcationModal";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NotificationDetailsPage({
  notification,
  handleClose,
  drawerOpen,
}) {
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const onModalClose = () => {
    setModalOpen(false);
  };

  const onNotificationModalClose = () => {
    setNotificationModalOpen(false);
  };
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={drawerOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            {notification?.title || "Loading..."}
          </Typography>

          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              color="white"
              onClick={() =>
                notification?.entity === "inapp"
                  ? setModalOpen(true)
                  : setNotificationModalOpen(true)
              }
            >
              Duplicate
            </Button>

            <Button variant="contained" color="error" onClick={handleClose}>
              <CloseRounded />
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>

      <Container sx={{ minHeight: 500 }}>
        {notification ? (
          <NotificationProfile notification={notification} />
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Container>
      {modalOpen === true && (
        <AddInAppMsgModal
          drawerOpen={modalOpen}
          notification={notification}
          handleM1Close={onModalClose}
        />
      )}
      {notificationModalOpen && (
        <AddNotificationMsgModal
          drawerOpen={notificationModalOpen}
          handleM1Close={onNotificationModalClose}
          notification={notification}
        />
      )}
    </Dialog>
  );
}
